import './index.scss';
// external
import React from 'react';
import classNames from 'classnames';
// internal
import { IPropsComponent } from './assets/interfaces';

const Tumbler: React.FC<IPropsComponent> = ({
  on,
  disabled,
  onChange,
  firstTitle,
  secondTitle,
  children
}) => {
  const className = classNames({
    tumbler: true,
    on,
    disabled
  });

  return (
    <div>
      <div className="tumbler--label">
        {children}
      </div>
      <div className={className} onClick={onChange}>
        <div className="tumbler--control">
          <div className="tumbler--titles">
            <div className="tumbler--titles--text">
             <span>
               {firstTitle}
             </span>
            </div>
            <div className="tumbler--titles--text">
             <span>
               {secondTitle}
             </span>
            </div>
          </div>
          <div className="tumbler--indicator" />
        </div>
      </div>
    </div>
  );
};

Tumbler.defaultProps = {
  on: false,
  onChange: () => {},
  disabled: false,
  firstTitle: 'One',
  secondTitle: 'Two',
};

export default React.memo(Tumbler);
