import './index.scss';
import React from 'react';
import * as auth from '../../../services/auth'
import * as api from '../../../services/api'
import { usersActions } from '../../../store/actions'
import store  from '../../../store/store'
import cogoToast from "cogo-toast";
import Card from "../../../components/ui/universal/Card";
import Spacer from "../../../components/ui/universal/Spacer";
import Loading from "../../../components/ui/universal/Loading";
import PrimaryButton from "../../../components/ui/universal/PrimaryButton";

class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.uploadPhotoForm = React.createRef();
    this.filedata = React.createRef();
  }

  state = {
    filedata: '',
    imagePreviewUrl: '',
    percentsOfComplete: 0
  };

  _handleSubmit = (e) => {
    e.preventDefault();
    if (!this.state.filedata) return;

    let uploader = new XMLHttpRequest();
    //let url = `${api.API_ENTRY}/account.photoUpdate`;
    let url = `${api.API_DOMAIN}/upload`;

    uploader.open("POST", url, true);
    let token = auth.getToken();
    token = JSON.parse(token).access_token;
    uploader.setRequestHeader('Authorization', token);

    uploader.upload.onprogress = (event) => {
      const percent = Math.ceil(event.loaded / event.total * 100);
      switch (percent) {
        case 100:
        case 1:
          this.setState({
            percentsOfComplete: percent
          });
          break;
      }

    };

    uploader.onload = (e) => {
      try {
        let response = JSON.parse(e.currentTarget.response);
        const update = store.dispatch(usersActions.photoUpdate(response.files[0].hash));
        update.then((data) => {
          const my_id = JSON.parse(auth.getToken()).owner_id;
          store.dispatch(usersActions.get(my_id, {
            personal: true
          }));
          this.props.close();
          cogoToast.success('Фотография обновлена!');
        }, ({ errors }) => {
          this.props.close();
          cogoToast.error('Ошибка обновления данных профиля');
          console.error('status' + response.status, "error upload");
        });
      } catch (e) {
        cogoToast.error('Ошибка');
        this.props.close();
      }
    };

    uploader.onerror = () => {
      let response = JSON.parse(this.response);
      if (response.status === 200) {
        //
      } else {
        console.error('status' + response.status, "error upload");
      }
      this.props.close();
      cogoToast.error('Ошибка обновления');
    };

    uploader.send(
      new FormData(this.uploadPhotoForm.current)
    );
  };

  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let filedata = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        filedata,
        imagePreviewUrl: reader.result
      });
    };

    reader.readAsDataURL(filedata)
  }

  render() {
    let imagePreview = this.state.imagePreviewUrl ?
      <div className="img" style={{backgroundImage: `url(${this.state.imagePreviewUrl})`}}> </div> : '';

    return (
      <Card horizontalPadding={3} verticalPadding={3}>
        <div className="UploadAvatar__padding_preview">
          <form
            name="uploadPhotoForm"
            id="uploadPhotoForm"
            ref={this.uploadPhotoForm}
            onSubmit={(e) => this._handleSubmit(e)}
          >
            <input
              style={{display:'none'}}
              ref={this.filedata}
              className="file"
              type="file"
              name="filedata"
              onChange={(e) => this._handleImageChange(e)}
            />
            {this.state.percentsOfComplete < 1 ? <div className="UploadAvatar__imgPreview">
              {imagePreview}
            </div> : <div className="UploadAvatar__percents">
              <Loading />
            </div>
            }
            <div className="UploadAvatar__button">
              {!this.state.filedata ? (
                // <div>
                //   <div style={{width: 300}}>
                //     Загружаемые изображения должны быть формата: <b>jpeg, png</b> и не должны быть размером более <b>20 МБ</b>.
                //   </div>
                //   <Spacer size={6} />
                //   <Button
                //     style={this.state.percentsOfComplete > 0 ? {display:'none'} : {}}
                //     size="large"
                //     fullWidth
                //     onClick={() => this.filedata.current.click()}
                //   >
                //     Выбрать фото
                //   </Button>
                // </div>

                <div>
                  <div className="header">
                    <span>
                      Загружаемые изображения должны быть формата: <b>jpeg, png</b> и не должны быть размером более <b>20 МБ</b>.
                    </span>
                  </div>
                  <Spacer size={6} />
                  <PrimaryButton
                    text="Выбрать фото"
                    style={this.state.percentsOfComplete > 0 ? {display:'none'} : {}}
                    onClick={() => this.filedata.current.click()}
                  />
                </div>


              ) : this.state.percentsOfComplete > 0 || [
                <div style={{width: '100%'}}>
                  <Spacer size={6}/>
                  {/*<Button*/}
                  {/*  mode="negative_outline"*/}
                  {/*  size="large"*/}
                  {/*  fullWidth={true}*/}
                  {/*  onClick={() => {*/}
                  {/*    this.setState({*/}
                  {/*      filedata: '',*/}
                  {/*      imagePreviewUrl: '',*/}
                  {/*      percentsOfComplete: 0*/}
                  {/*    })*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  Отмена*/}
                  {/*</Button>*/}

                  <PrimaryButton
                    mode="red"
                    text="Отмена"
                    onClick={() => {
                      this.setState({
                        filedata: '',
                        imagePreviewUrl: '',
                        percentsOfComplete: 0
                      })
                    }}
                  />
                </div>
              ]}
            </div>

            <div>
              {this.state.filedata ? [
                <Spacer size={6}/>,
                <PrimaryButton
                  text="Загрузить"
                  mode="primary"
                  state={this.state.percentsOfComplete > 0 && this.state.percentsOfComplete < 100 ? 'loading' : null}
                  onClick={(e) => this._handleSubmit(e)}
                />
              ] : ''}
            </div>
          </form>
        </div>
      </Card>
    )
  }
}

export default ImageUpload;
