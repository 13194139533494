export const PAGE_ROUTES = {
  MAIN: 'main',
  CITIES: 'cities',
  SEARCH: 'search',
  START: 'start',
  PROFILE: 'profile',
  SETTINGS: 'settings',
  SECURITY: 'security',
  MYCONTACTS: 'mycontacts',
  FORDEV: 'fordev',
  AGREEMENT: 'agreement',
  CODE: 'code',
  BALANCE: 'balance',
  MENU: 'menu',
  SPECIALMENU: 'specialmenu',
  SPECIALCATEGORIES: 'specialcategories',
  SPECIALORDERS: 'specialorders',
  PRODUCTS: 'products',
  OPTIONS: 'options',
  PERSONAL: 'personal',
  MYORDERS: 'myorders',
  MYADDRESS: 'myaddress',

  ORDERS: 'orders',
  NOTIFICATIONS: 'notifications',
  CONTACTSDATA: 'contactsdata',
  POINT: 'point',
  GENERAL_POINT_SETTINGS: 'GENERAL_POINT_SETTINGS',

  NOT_FOUND: 'not_found',
  UI: 'ui',

  ADMIN_MAIN: 'ADMIN_MAIN',
  ADMIN_MENU: 'ADMIN_MENU',
  CREATE_POINT: 'CREATE_POINT',
  EDIT_POINT: 'EDIT_POINT',
};