import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'home_outline_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="home_outline_28"><g fill="none" fill-rule="evenodd"><path d="M0 0h28v28H0z" /><path d="M15.13 2.691l8.936 8.49A3 3 0 0125 13.356v8.641a2.5 2.5 0 01-2.5 2.5h-6a1 1 0 01-1-1v-5.5a1.5 1.5 0 00-3 0v5.5a1 1 0 01-1 1h-6a2.5 2.5 0 01-2.5-2.5v-8.641a3 3 0 01.934-2.175l8.936-8.49a1.64 1.64 0 012.26 0zM14 4.377L5.311 12.63a1 1 0 00-.311.725v8.641a.5.5 0 00.5.5h5v-4.5a3.5 3.5 0 017 0v4.5h5a.5.5 0 00.5-.5v-8.641a1 1 0 00-.311-.725L14 4.377z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28HomeOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28HomeOutline: FC<Icon28HomeOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28HomeOutline as any).mountIcon = mountIcon;

export default Icon28HomeOutline;
