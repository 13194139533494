import React from "react";
import RowListItem from "../../../components/common/RowListItem";
import {ReactComponent as ChevronSVG} from "../../../static/images/svg/new/chevronRight.svg";
import router5 from "../../../router";
import {PAGE_ROUTES} from "../../../definitions";
import {ReactComponent as AdminCogSvg} from "../MenuPage/assets/adminsIcons/cog.svg";

const AdminMenuPage = () => {

  // useEffect(() => {
  //   dispatch(commonActions.getOrders({
  //     archive: 0,
  //     cursor: 0,
  //     count: 20
  //   }));

    // dispatch(commonActions.addOrder({
    //   point_id: 1,
    //   contact_info: 5,
    //   pay_method_id: 1,
    //   not_call: 0,
    //   take_it_myself: 0,
    //   change_needed: 0,
    //   comment: 0,
    //   items: [{subject_id: 1, variation_id: 1, count: 1, additions: [{id: 1, count: 2}]}]
    // }));

  // }, []);

  const renderAdminMenu = () => {
    return <>
      <div className="Main__full_width">
        <RowListItem
          title="Создание точки"
          icon={<AdminCogSvg />}
          chevron={<ChevronSVG />}
          onClick={() => router5.navigate(PAGE_ROUTES.CREATE_POINT)}
        />

        <RowListItem
          title="Редактирование"
          icon={<AdminCogSvg />}
          chevron={<ChevronSVG />}
          onClick={() => router5.navigate(PAGE_ROUTES.EDIT_POINT)}
        />
        <div className="Main__padding24">
        </div>
      </div>
    </>
  };

  return (
    <div>
        {renderAdminMenu()}
    </div>
  );
};

export default AdminMenuPage;