import './index.scss';
// external
import React, { memo } from 'react';
//
import { ReactComponent as LoadingSVG } from './assets/svg/loading.svg';

const Loading = () => {
  return (
    <div className="loading">
      <LoadingSVG />
    </div>
  );
};

export default memo(Loading);
