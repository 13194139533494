import { TYPES } from '../types';
import { loadingActions } from '../';
import { LOADING_STATUSES } from '../../../definitions/loading-statuses';
import * as api from "../../../services/api";

export const usersActions = {

  signUp: (params) => (dispatch) => {
    return new Promise((resolve, reject) => {
      api.post('auth.sign_up', {...params}).then((response) => {
        api.apiResponseTreatment(response).then((data) => {
          resolve({ status: true, data });
        }, (data) => {
          reject({ status: false, data })
        });
      }).catch((data) => {
        reject(data);
      });
    });
  },

  getAccount: (user_id) => (dispatch) => {
    return new Promise((resolve, reject) => {
      api.get('account.get', {
        fields: ['first_name', 'last_name', 'patronymic', 'deactivated', 'last_seen', 'verified', 'full_name', 'photo', 'gender'].join(','),
        user_ids: user_id
      }).then((response) => {
        api.apiResponseTreatment(response).then((data) => {
          dispatch({
            type: TYPES.USERS.SET_ACCOUNT,
            payload: data.account
          });
          resolve({
            status: true
          })
        }, () => {
          reject({
            status: false
          })
        });
      }).catch((data) => {
        reject(data);
      });
    });
  },

  get: (user_id, params = {
    personal: false
  }) => (dispatch) => {
    return new Promise((resolve, reject) => {
      const otherParams = {};
      if (params.personal) {
        otherParams.personal = 1;
      }
      api.get('users.get', {
        ...otherParams,
        fields: ['first_name', 'last_name', 'patronymic', 'deactivated', 'last_seen', 'verified', 'full_name', 'photo', 'gender'].join(','),
        user_ids: user_id
      }).then((response) => {
        api.apiResponseTreatment(response).then((data) => {
          if (data.countAll > 0) {
            for(const userId in data.rows) {
              dispatch({
                type: TYPES.USERS.SET_BY_ID,
                payload: {
                  [userId]: data.rows[userId]
                }
              });
              if (params.personal) {

                dispatch({
                  type: TYPES.USERS.SET_PERSONAL,
                  payload: data.rows[userId]
                });
              }
            }
            resolve({
              status: true,
              data: data.rows
            });
          } else {
            reject({
              status: false
            })
          }
        }, () => {
          reject({
            status: false
          })
        });

        // dispatch(loadingActions.removeLoading(
        //   LOADING_STATUSES.PERSONAL_INFO_LOADING_KEY + user_id
        // ));
      }).catch((data) => {
        // dispatch(loadingActions.removeLoading(
        //   LOADING_STATUSES.PERSONAL_INFO_LOADING_KEY + user_id
        // ));
        reject(data);
      });
    });
  },

  update: (params) => (dispatch) => {
    return new Promise((resolve, reject) => {

      dispatch(loadingActions.setLoading(
        LOADING_STATUSES.PERSONAL_UPDATE_LOADING_KEY
      ));

      api.get('account.update', params).then((response) => {
        api.apiResponseTreatment(response).then((data) => {
          resolve({
            status: true,
            response
          });
        }, (errors) => {
          reject({
            status: false,
            errors
          })
        });

        dispatch(loadingActions.removeLoading(
          LOADING_STATUSES.PERSONAL_UPDATE_LOADING_KEY
        ));
      }).catch((data) => {
        dispatch(loadingActions.removeLoading(
          LOADING_STATUSES.PERSONAL_UPDATE_LOADING_KEY
        ));
        reject(data);
      });
    });
  },

  changePassword: (params) => (dispatch) => {
    return new Promise((resolve, reject) => {

      dispatch(loadingActions.setLoading(
        LOADING_STATUSES.PASSWORD_UPDATE_LOADING_KEY
      ));

      const _params = {};
      for (const field in params) {
        switch (field) {
          case 'secret_phrase':
            _params[field] = params[field].toLowerCase();
            break;
          default:
            _params[field] = params[field];
            break;
        }
      }

      api.post('account.changePassword', _params).then((response) => {
        api.apiResponseTreatment(response).then((data) => {
          resolve({
            status: true,
            response
          });
        }, (errors) => {
          reject({
            status: false,
            errors
          })
        });

        dispatch(loadingActions.removeLoading(
          LOADING_STATUSES.PASSWORD_UPDATE_LOADING_KEY
        ));
      }).catch((data) => {
        dispatch(loadingActions.removeLoading(
          LOADING_STATUSES.PASSWORD_UPDATE_LOADING_KEY
        ));
        reject(data);
      });
    });
  },

  photoUpdate: (photo) => (dispatch) => {
    return new Promise((resolve, reject) => {

      dispatch(loadingActions.setLoading(
        LOADING_STATUSES.PERSONAL_UPDATE_LOADING_KEY
      ));

      api.get('account.photoUpdate', { photo }).then((response) => {
        api.apiResponseTreatment(response).then((data) => {
          resolve({
            status: true,
            response
          });
        }, (errors) => {
          reject({
            status: false,
            errors
          })
        });

        dispatch(loadingActions.removeLoading(
          LOADING_STATUSES.PERSONAL_UPDATE_LOADING_KEY
        ));
      }).catch((data) => {
        dispatch(loadingActions.removeLoading(
          LOADING_STATUSES.PERSONAL_UPDATE_LOADING_KEY
        ));
        reject(data);
      });
    });
  },

  setProfileIsClosed: (status) => (dispatch) => {
    return new Promise((resolve, reject) => {
      api.get('account.setProfileIsClosed', { status }).then((response) => {
        api.apiResponseTreatment(response).then((data) => {
          resolve({
            status: true,
            data
          });
        }, (errors) => {
          reject({
            status: false,
            errors
          })
        });
      }).catch((data) => {
        reject(data);
      });
    });
  },

  setCity: (city_id) => (dispatch) => {
    return new Promise((resolve, reject) => {
      api.get('account.setCity', { city_id }).then((response) => {
        api.apiResponseTreatment(response).then((data) => {
          resolve({
            status: true,
            data
          });
        }, (errors) => {
          reject({
            status: false,
            errors
          })
        });
      }).catch((data) => {
        reject(data);
      });
    });
  },

  getAllDevices: () => (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(loadingActions.setLoading(
        LOADING_STATUSES.LOAD_DEVICES_LOADING_KEY
      ));

      api.get('account.getAllDevices', {}).then((response) => {
        api.apiResponseTreatment(response).then((data) => {
          dispatch({
            type: TYPES.USERS.SET_DEVICES,
            payload: data
          });

          resolve({
            status: true,
            data
          });
        }, (errors) => {
          reject({
            status: false,
            errors
          })
        });

        dispatch(loadingActions.removeLoading(
          LOADING_STATUSES.LOAD_DEVICES_LOADING_KEY
        ));
      }).catch((data) => {
        reject(data);
      });
    });
  },

};
