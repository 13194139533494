import "./index.scss";
import React, { useEffect, useState } from "react";
import { ReactComponent as SearchSVG } from "../../../components/ui/mobile/HeaderIcon/assets/svg/search.svg";
import MyButton from "../../../components/ui/universal/PrimaryButton";
import Spacer from "../../../components/ui/universal/Spacer";
import Padding from "../../../components/ui/universal/Padding";
import BriefItem from "../../../components/common/BriefItem";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "../../../store/actions/common-actions";
import Loading from "../../../components/ui/universal/Loading";
import { isEmptyObject } from "../../../utils";
import router from "../../../router";
import { LOADING_STATUS_IN_PROCESS, PAGE_ROUTES } from "../../../definitions";
import Heading from "../../../components/ui/universal/Heading";
import { v4 as uuidv4 } from "uuid";
import IsEmptyHandler from "../../../components/common/is-empty-handler";
import Pagination from "../../../components/common/pagination";

const appendCursor = 20;

const MyOrdersPage = () => {
  const dispatch = useDispatch();
  const [hiddenInfoOrder, setHiddenInfoOrder] = useState(false);
  const { myOrders } = useSelector((state) => ({
    myOrders: state.common.myOrders,
  }));

  const { loadingStatus } = useSelector((store) => ({
    loadingStatus: store.common.loadingStatus,
  }));

  console.log(323, myOrders);

  const isLoading = isEmptyObject(myOrders);

  useEffect(() => {
    if (isEmptyObject(myOrders)) {
      dispatch(commonActions.getMyOrders());
    }
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  const orders = {
    append: () => {
      dispatch(
        commonActions.getMyOrders(
          {
            cursor: myOrders.hasNext.cursor + appendCursor,
          },
          true,
        ),
      );
    },
  };

  console.log(443, myOrders);

  const { rows } = myOrders;
  const renderOrders = () => {
    return (
      <div>
        <IsEmptyHandler counterText="Всего заказов:" counter={myOrders.countAll} showCounter={true}>
          <Pagination
            hasNext={myOrders.hasNext.hasNext}
            loadingStatus={loadingStatus["ordersIsLoading"] === LOADING_STATUS_IN_PROCESS}
            loadingMore={orders.append}>
            {rows.map((itemKey, key) => {
              return renderItem({ itemKey, key });
            })}
          </Pagination>
        </IsEmptyHandler>
      </div>
    );
  };

  const renderInfo = () => {
    return (
      <div className="MyOrdersPage">
        <div className="MyOrdersPage_fake" />
        <div className="MyOrdersPage_wrapper">
          <div className="MyOrdersPage_wrapper_icon">
            <SearchSVG />
          </div>
          <Spacer size={5} />
          <div className="MyOrdersPage_wrapper_info">
            <div className="MyOrdersPage_wrapper_info_title">Вы пока ничего не заказывали</div>
            <div className="MyOrdersPage_wrapper_info_description">Совершите первый заказ прямо сейчас!</div>
          </div>
          <Spacer size={5} />
          <div className="MyOrdersPage_wrapper_buttonBox">
            <MyButton
              text="Сделать заказ"
              mode={"primary"}
              onClick={() => {
                router.navigate(PAGE_ROUTES.MAIN);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderItem = ({ itemKey }) => {
    const { point, order, price, status_id, create_time } = itemKey;
    const time = new Date(create_time * 1000);
    const createTime = time.toLocaleDateString("ru-RU") + " " + time.toLocaleTimeString("ru-RU");
    return (
      <div>
        <div
          className="MyOrdersPage_box"
          onClick={() => {
            setHiddenInfoOrder(!hiddenInfoOrder);
          }}>
          <div className="MyOrdersPage_box_header">
            <div className="MyOrdersPage_box_header_text">
              <Heading size={2} underline={1}>
                {point.title}
              </Heading>
            </div>
          </div>
          <div className="MyOrdersPage_box_title">
            <div className="MyOrdersPage_box_title_container">
              <BriefItem status_id={status_id} mode={status_id} />
            </div>
            <div className="MyOrdersPage_box_title_container">
              <span>{createTime}</span>
            </div>
          </div>
          <div>
            {order.map((item) => {
              const { additions, subject, variation_id, count_position } = item;
              return (
                <div key={uuidv4()}>
                  <Spacer size={3} />
                  <div className="MyOrdersPage_box_item">
                    {variation_id === undefined && !additions.length ? (
                      <div />
                    ) : (
                      <div className="MyOrdersPage_box_hidden">
                        {!hiddenInfoOrder ? "Показать подробности заказа" : "Скрыть подробности заказа"}
                      </div>
                    )}
                    <div className="MyOrdersPage_box_item_info">
                      <span>{subject.name}</span>
                    </div>
                    <div className="MyOrdersPage_box_item_info">
                      <div className="MyOrdersPage_box_item_info_price">
                        {count_position === 1
                          ? `Количество: ${count_position} шт - ${subject.price} ₽`
                          : `Количество: ${count_position} шт - ${count_position * subject.price} ₽`}
                      </div>
                    </div>
                  </div>
                  <Spacer size={3} />

                  {hiddenInfoOrder && (
                    <div className="MyOrdersPage_box_wrapper">
                      {variation_id !== undefined && (
                        <div className="MyOrdersPage_box_wrapper_topping">
                          <div className="MyOrdersPage_box_wrapper_topping_item">
                            <div className="MyOrdersPage_box_wrapper_topping_item_left">
                              <span>{variation_id.info.title}</span>
                              <span className="description">{variation_id.info.description}</span>
                            </div>
                            <div className="MyOrdersPage_box_wrapper_topping_item_right">
                              <span>{variation_id.info.price} ₽</span>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="MyOrdersPage_box_wrapper_topping">
                        {additions.map((item, key) => {
                          const { info, count } = item;
                          return (
                            <div key={uuidv4()} className="MyOrdersPage_box_wrapper_topping_item">
                              <div className="MyOrdersPage_box_wrapper_topping_item_left">
                                <span>{info.title}</span>
                                <span className="description">{info.description}</span>
                              </div>
                              <div className="MyOrdersPage_box_wrapper_topping_item_right">
                                <span>
                                  {count === 1
                                    ? `за ${count} шт ${info.price}`
                                    : `за ${count} шт ${count * info.price}`}
                                  ₽
                                </span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          <div className="MyOrdersPage_box_total">
            <div className="MyOrdersPage_box_total_left">
              <span>Итого:</span>
            </div>
            <div className="MyOrdersPage_box_total_right">
              <span>{price} ₽</span>
            </div>
          </div>
        </div>
        <Spacer size={10} />
      </div>
    );
  };

  return (
    <div>
      <Padding horizontal={2} vertical={2}>
        {rows.length ? <div>{renderOrders()}</div> : <div>{renderInfo()}</div>}
      </Padding>
    </div>
  );
};

export default MyOrdersPage;
