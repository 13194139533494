import "./index.scss";
import React, { useEffect, useState } from "react";
import Counter from "../Counter";
import { ReactComponent as DownSVG } from "../../../static/images/svg/new/down_chevron.svg";
import { ReactComponent as TimeSVG } from "../../../static/images/svg/new/time.svg";
import Padding from "../../ui/universal/Padding";
import AdditionalServices from "../AdditionalServices";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "../../../store/actions";
import { isLogged } from "../../../services";
import SelectVariation from "../SelectVariation";
import { TYPES } from "../../../store/actions";
import cogoToast from "cogo-toast";

const NewRowItem = (props) => {
  const { id, force, noEdit, name, photo, price } = props;
  const [counterValue, setCounterValue] = useState(props.preDefaultCounter);
  const [hiddenInfo, setHiddenInfo] = useState(true);
  const [lockedIsLoadingOptions, setLockedIsIsLoadingOptions] = useState(false);
  const [isEmptyOptions, setIsEmptyOptions] = useState(false);
  const dispatch = useDispatch();
  const isLoggedIn = isLogged();

  const {
    variationsById,
    additionsById,
    selectedVariations,
    selectedAdditions,
  } = useSelector((state) => ({
    variationsById: state.common.variationsById,
    additionsById: state.common.additionsById,
    selectedAdditions: state.common.selectedAdditions,
    selectedVariations: state.common.selectedVariations,
  }));

  const thisItem = {
    data: { ...props },
    id: props.id,
  };

  useEffect(() => {
    if (!!force) {
      setCounterValue(0);
      if (props.hasOwnProperty("setForceDelete")) {
        props.setForceDelete(0);
      }
    }
  }, [force]);

  useEffect(() => {
    if (!counterValue && !!props.preDefaultCounter) {
      setCounterValue(props.preDefaultCounter);
    }
  }, [props.preDefaultCounter]);

  delete thisItem.data.onChange;
  delete thisItem.data.photo;

  const handlerGetOptions = ({ notify = false, cb = false }) => {
    if (lockedIsLoadingOptions) return;

    if (variationsById.hasOwnProperty(id) && additionsById.hasOwnProperty(id)) {
      setHiddenInfo(!hiddenInfo);
    } else {
      setLockedIsIsLoadingOptions(true);
      const getVariations = dispatch(
        commonActions.getVariations({ subject_id: id })
      );
      const getAdditions = dispatch(
        commonActions.getAdditions({ subject_id: id })
      );

      getVariations.then(({ data: { count: count1 } }) => {
        getAdditions.then(({ data: { count: count2 } }) => {
          setLockedIsIsLoadingOptions(false);
          if (count1 + count2 === 0) {
            setIsEmptyOptions(true);
            if (notify) {
              cogoToast.error("Нет дополнительных опций");
            }
            return;
          }

          setHiddenInfo(false);
          if (!!cb) cb();
        });
      });
    }
  };

  const renderVariations = () => {
    const empty = "";

    if (!variationsById.hasOwnProperty(id)) {
      return empty;
    }

    if (!variationsById[id].length) {
      return empty;
    }

    return (
      <div>
        <div className="NewRowItem_description_name">
          <div>
            <span>Варианты</span>
          </div>
          <div>
            <small>Каждой единицы товара</small>
          </div>
        </div>

        {variationsById[id]
          .sort((a, b) => {
            return a.price - b.price;
          })
          .map((variationItem) => {
            const {
              id: variationId,
              title,
              description,
              price,
            } = variationItem;
            let checked = false;
            if (selectedVariations.has(id)) {
              if (selectedVariations.get(id) === variationId) {
                checked = true;
              }
            }
            return (
              <div key={"SelectVariation_" + variationId}>
                <SelectVariation
                  id={variationId}
                  checked={checked}
                  name={title}
                  description={description}
                  price={price}
                  onChangeHandler={(e) => {
                    if (!checked) {
                      dispatch({
                        type: TYPES.COMMON.SET_SELECTED_VARIATIONS_BY_ID,
                        payload: { id, data: variationId },
                      });
                    } else {
                      dispatch({
                        type: TYPES.COMMON.REMOVE_SELECTED_VARIATIONS_BY_ID,
                        payload: { id },
                      });
                    }
                  }}
                />
              </div>
            );
          })}
      </div>
    );
  };

  const renderAdditions = () => {
    const empty = "";

    if (!additionsById.hasOwnProperty(id)) {
      return empty;
    }

    if (!additionsById[id].length) {
      return empty;
    }

    return (
      <div>
        <div className="NewRowItem_description_name">
          <div>
            <span>Дополнительно</span>
          </div>
          <div>
            <small>к каждой единице товара</small>
          </div>
        </div>

        {additionsById[id]
          .sort((a, b) => {
            return a.price - b.price;
          })
          .map((additionItem) => {
            const { id: additionId, title, description, price } = additionItem;
            let currentAdditions = new Map();
            if (selectedAdditions.has(id)) {
              currentAdditions = selectedAdditions.get(id);
            }

            let count = 0;
            if (currentAdditions.has(additionId)) {
              const currentAddition = currentAdditions.get(additionId);
              count = currentAddition.count;
            }

            return (
              <div key={"additionId_" + additionId}>
                <AdditionalServices
                  id={additionId}
                  name={title}
                  description={description}
                  price={price}
                  count={count}
                  onChangeHandler={({ id: _id, count }) => {
                    if (count > 0) {
                      currentAdditions.set(additionId, {
                        id: _id,
                        count,
                      });
                    } else {
                      currentAdditions.delete(additionId);
                    }

                    dispatch({
                      type: TYPES.COMMON.SET_SELECTED_ADDITIONS_BY_ID,
                      payload: { id, data: currentAdditions },
                    });
                  }}
                />
              </div>
            );
          })}
      </div>
    );
  };

  const renderOptions = () => {
    return (
      <Padding horizontal={1} vertical={2}>
        <div>
          {renderVariations()}
          {renderAdditions()}
        </div>
      </Padding>
    );
  };

  const getPrice = ({ counter = 0, price }) => {
    if (counter === 0) {
      return price;
    }

    return counter * price;
  };

  return (
    <div className="NewRowItem_box no_select">
      <div className="w-100 display_flex">
        <div className="NewRowItem_box_info">
          <div className="NewRowItem_box_info_productName">
            <span>{name}</span>
            {isLoggedIn && counterValue > 0 && !isEmptyOptions ? (
              <div
                className="NewRowItem_box_info_productName_icon"
                onClick={() => {
                  handlerGetOptions({ notify: true });
                }}
              >
                {lockedIsLoadingOptions ? <TimeSVG /> : <DownSVG />}
              </div>
            ) : (
              <div className="NewRowItem_box_info_productName_icon" />
            )}
          </div>
          <div className="NewRowItem_box_info_orderPage">
            {isLoggedIn && (
              <Counter
                maxValue={props.maxCountForOrder}
                value={counterValue}
                onChange={(value) => {
                  setCounterValue(value);
                  if (value === 0) {
                    setHiddenInfo(true);
                  }

                  if (
                    value === 1 &&
                    !variationsById.hasOwnProperty(id) &&
                    !additionsById.hasOwnProperty(id)
                  ) {
                    handlerGetOptions({
                      notify: false,
                      cb: () => {
                        setHiddenInfo(false);
                      },
                    });
                  }

                  props.onChange({ ...thisItem, counter: value });
                }}
              />
            )}
            {!noEdit && (
              <div className="NewRowItem_box_info_orderPage_price">
                {!!counterValue ? (
                  <span>
                    {counterValue > 1 && counterValue * price}
                    {counterValue < 2 && price}₽
                  </span>
                ) : (
                  <span>{getPrice({ counter: 0, price })} ₽</span>
                )}
              </div>
            )}

            {!!noEdit && (
              <div className="NewRowItem_box_info_orderPage_price">
                <span>{getPrice({ counter: props.counter, price })} ₽</span>
              </div>
            )}
          </div>
        </div>

        <div
          className="NewRowItem_box_cards"
          style={{ backgroundImage: `url(${photo})` }}
        />
      </div>

      {!hiddenInfo && !isEmptyOptions && <div>{renderOptions()}</div>}
    </div>
  );
};

function PropsAreEqual(prev, next) {
  return next.force === prev.force;
}

export default React.memo(NewRowItem, PropsAreEqual);
