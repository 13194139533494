import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'location_map_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="location_map_outline_28"><path d="M14 11a2 2 0 100-4 2 2 0 000 4z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M7 9a7 7 0 1114 0c0 4.506-3.038 8.242-4.845 10.082a2.994 2.994 0 01-4.31 0C10.038 17.242 7 13.506 7 9zm7-5a5 5 0 00-5 5c0 3.667 2.528 6.905 4.272 8.681a.995.995 0 001.456 0C16.472 15.905 19 12.667 19 9a5 5 0 00-5-5z" fill="currentColor" /><path d="M7.262 16.572a1 1 0 01-.108 1.41C6.372 18.653 6 19.393 6 20c0 .853.634 1.835 2.122 2.661C9.576 23.47 11.652 24 14 24c2.348 0 4.424-.53 5.878-1.339C21.366 21.835 22 20.853 22 20c0-.624-.343-1.315-1.143-1.988a1 1 0 011.287-1.53C23.22 17.386 24 18.588 24 20c0 1.909-1.38 3.427-3.15 4.41C19.046 25.412 16.622 26 14 26c-2.623 0-5.046-.588-6.85-1.59C5.38 23.427 4 21.909 4 20c0-1.395.81-2.642 1.852-3.536a1 1 0 011.41.108z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28LocationMapOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28LocationMapOutline: FC<Icon28LocationMapOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28LocationMapOutline as any).mountIcon = mountIcon;

export default Icon28LocationMapOutline;
