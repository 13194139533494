// @ts-nocheck in
import './index.css';
import React, { FC, InputHTMLAttributes, ReactNode } from 'react';
import Tappable from '../Tappable';
import classNames from 'classnames';
import { HasFormLabels, HasRef, HasRootRef } from '../../../../interfaces';

export interface RadioProps extends
  InputHTMLAttributes<HTMLInputElement>,
  HasRef<HTMLInputElement>,
  HasRootRef<HTMLLabelElement>,
  HasFormLabels {
  description?: ReactNode;
}

const Radio: FC<RadioProps> = (props: RadioProps) => {
  const {
    children, description, style, className, getRef, getRootRef, top, bottom, ...restProps
  } = props;

  return (
    <Tappable
      Component="label"
      style={style}
      className={classNames('Radio Radio--ios', className)}
      activeEffectDelay={100}
      disabled={restProps.disabled}
      getRootRef={getRootRef}
    >
      <input {...restProps} type="radio" className="Radio__input" ref={getRef} />
      <div className="Radio__container">
        <div className="Radio__icon" />
        <div className="Radio__content">
          {children}
          {description && <div className="Radio__description">{description}</div>}
        </div>
      </div>
    </Tappable>
  );
};

export default Radio;
