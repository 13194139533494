import createRouter from 'router5';
import browserPlugin from 'router5-plugin-browser';
import listenersPlugin from 'router5-plugin-listeners';
import { PAGE_ROUTES } from './definitions/routes';
import { isLogged } from './services/auth';

const logged = isLogged();

const cityId = localStorage.getItem('city') !== null;

let __routes = [];

if (cityId) {
	if (!logged) {
		__routes = [
			{
				name: PAGE_ROUTES.MAIN,
				path: '/',
			},
			{
				name: PAGE_ROUTES.CITIES,
				path: '/cities',
			},
			{
				name: PAGE_ROUTES.POINT,
				path: '/point:id',
			},
			{
				name: PAGE_ROUTES.NOT_FOUND,
				path: '/not_found',
			},
		];
	} else {
		__routes = [
			{
				name: PAGE_ROUTES.MAIN,
				path: '/',
			},
			{
				name: PAGE_ROUTES.PROFILE,
				path: '/id:id',
			},
			{
				name: PAGE_ROUTES.CITIES,
				path: '/cities',
			},
			{
				name: PAGE_ROUTES.GENERAL_POINT_SETTINGS,
				path: `/general_point_settings:id`,
			},
			{
				name: PAGE_ROUTES.SEARCH,
				path: '/search',
			},
			{
				name: PAGE_ROUTES.MENU,
				path: '/menu',
			},
			{
				name: PAGE_ROUTES.SPECIALMENU,
				path: '/specialmenu:id',
			},
			{
				name: PAGE_ROUTES.SPECIALCATEGORIES,
				path: '/specialcategories:id',
			},
			{
				name: PAGE_ROUTES.SPECIALORDERS,
				path: '/specialorders:id',
			},
			{
				name: PAGE_ROUTES.PRODUCTS,
				path: '/products:id',
			},
			{
				name: PAGE_ROUTES.OPTIONS,
				path: '/options:id/:subjectId',
			},
			{
				name: PAGE_ROUTES.BALANCE,
				path: '/balance:id',
			},
			{
				name: PAGE_ROUTES.PERSONAL,
				path: '/personal:id',
			},
			{
				name: PAGE_ROUTES.MYORDERS,
				path: '/myorders',
			},
			{
				name: PAGE_ROUTES.MYADDRESS,
				path: '/myaddress/:section',
			},
			{
				name: PAGE_ROUTES.NOTIFICATIONS,
				path: '/notifications',
			},
			{
				name: PAGE_ROUTES.FORDEV,
				path: '/fordev',
			},
			{
				name: PAGE_ROUTES.AGREEMENT,
				path: '/agreement',
			},
			{
				name: PAGE_ROUTES.CODE,
				path: '/code',
			},
			{
				name: PAGE_ROUTES.SETTINGS,
				path: '/settings/:section',
			},
			{
				name: PAGE_ROUTES.POINT,
				path: '/point:id',
			},
			{
				name: PAGE_ROUTES.UI,
				path: '/ui',
			},
			{
				name: PAGE_ROUTES.ADMIN_MENU,
				path: '/admin_menu',
			},
			{
				name: PAGE_ROUTES.CREATE_POINT,
				path: '/create_point',
			},
			{
				name: PAGE_ROUTES.EDIT_POINT,
				path: '/edit_point:id',
			},
			{
				name: PAGE_ROUTES.NOT_FOUND,
				path: '/not_found',
			}
		];
	}

} else {
	__routes = [
		{
			name: PAGE_ROUTES.CITIES,
			path: '/',
		},
		{
			name: PAGE_ROUTES.NOT_FOUND,
			path: '/not_found',
		}
	];
}

export const routes = __routes;

const params = {
  defaultRoute: PAGE_ROUTES.NOT_FOUND,
  defaultParams: {},
  strictQueryParams: true,
  trailingSlash: true,
  useTrailingSlash: false,
  queryParamsMode: 'loose',
};

let router = createRouter(routes, params);

router.usePlugin(
  browserPlugin({
    base: '',
    useHash: false,
    hashPrefix: '',
    mergeState: true,
    preserveHash: false,
    forceDeactivate: true,
  }
));

router.usePlugin(
  listenersPlugin()
);

export default router;
