import './index.scss';
//
import React, { FC } from 'react';
//
import Heading from '../../ui/universal/Heading';
import { IPropsComponent } from './assets/interfaces';
import Padding from "../../ui/universal/Padding";

const IsEmptyHandler: FC<IPropsComponent> = (props) => {
  const {
    counter, counterText, showCounter,
    children, placeholderContent, placeholderText
  } = props;

  return (counter > 0) ? (
    <>
      {showCounter && (
        <Padding horizontal={3} vertical={3}>
          <Heading size={2} underline={false}>
            {counterText} {counter}
          </Heading>
        </Padding>
      )}
      <div>
        {children}
      </div>
    </>
  ) : (
    <>
      {!!placeholderContent && (
        <div>
          {placeholderContent}
        </div>
      )}
      {!placeholderContent && (
        <Padding horizontal={3} vertical={3}>
          <Heading size={2} underline={false}>
            {placeholderText}
          </Heading>
        </Padding>
      )}
    </>
  );
};

IsEmptyHandler.defaultProps = {
  counter: 0,
  showCounter: false,
  counterText: 'объектов',
  placeholderText: 'There seems to be nothing here...',
};

export default IsEmptyHandler;
