export const TYPES = {
  COMMON: {
    LOADING_STATUS_IN_PROCESS: "LOADING",
    LOADING_STATUS_OK: "",
    LOADING_STATUS_FAIL: "FAIL",

    SET_TEST: "SET_TEST",
    SET_CITIES: "SET_CITIES",
    SET_CITY: "SET_CITY",

    SET_SECTIONS: "SET_SECTIONS",
    SET_ACTIVE_SECTION: "SET_ACTIVE_SECTION",

    SET_CATEGORIES: "SET_CATEGORIES",
    SET_ACTIVE_CATEGORY: "SET_ACTIVE_CATEGORY",

    SET_TAGS: "SET_TAGS",
    SET_ADDRESSES: "SET_ADDRESSES",

    SET_MY_ORDERS: "SET_MY_ORDERS",
    APPEND_MY_ORDERS: "APPEND_MY_ORDERS",
    SET_POINT_ORDERS: "SET_POINT_ORDERS",
    APPEND_POINT_ORDERS: "APPEND_POINT_ORDERS",
    SET_POINT_ORDERS_ID: "SET_POINT_ORDERS_ID",

    SET_MY_POINTS: "SET_MY_POINTS",
    SET_POINTS: "SET_POINTS",
    SET_POINT_BY_ID: "SET_POINT_BY_ID",

    SET_VARIATIONS_BY_ID: "SET_VARIATIONS_BY_ID",
    SET_ADDITIONS_BY_ID: "SET_ADDITIONS_BY_ID",
    SET_ADDITIONS_ID: "SET_ADDITIONS_ID",
    SET_VARIATIONS_ID: "SET_VARIATIONS_ID",

    SET_SUBJECTS: "SET_SUBJECTS",
    APPEND_SUBJECTS: "APPEND_SUBJECTS",

    SET_ACTIVE_POINT_CATEGORY_ID: "SET_ACTIVE_POINT_CATEGORY_ID",

    COMMON_SET_LOADING_STATUS: "COMMON_SET_LOADING_STATUS",

    SET_SUBJECT_BY_ID: "SET_SUBJECT_BY_ID",
    SET_SUBJECTS_BY_CATEGORY: "SET_SUBJECTS_BY_CATEGORY",
    APPEND_SUBJECTS_BY_CATEGORY: "APPEND_SUBJECTS_BY_CATEGORY",

    SET_SELECTED_VARIATIONS_BY_ID: "SET_SELECTED_VARIATIONS_BY_ID",
    SET_SELECTED_ADDITIONS_BY_ID: "SET_SELECTED_ADDITIONS_BY_ID",

    REMOVE_SELECTED_VARIATIONS_BY_ID: "REMOVE_SELECTED_VARIATIONS_BY_ID",
    REMOVE_SELECTED_ADDITIONS_BY_ID: "REMOVE_SELECTED_ADDITIONS_BY_ID",

    CLEAR_SELECTED_ADDITIONS: "CLEAR_SELECTED_ADDITIONS",
    CLEAR_SELECTED_VARIATIONS: "CLEAR_SELECTED_VARIATIONS",
  },
  USERS: {
    SET_BY_ID: "SET_BY_ID",
    SET_PERSONAL: "SET_PERSONAL",
    SET_ACCOUNT: "SET_ACCOUNT",
    SET_DEVICES: "SET_DEVICES",
    SET_AUTH: "SET_AUTH",
  },
  LOADING: {
    SET: "SET_LOADING",
    REMOVE: "REMOVE_LOADING",
    CLEAR: "CLEAR_LOADING",
  },
};
