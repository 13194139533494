//
import "./index.scss"
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {loadingActions, usersActions} from '../../../store/actions'
import * as Yup from "yup";
import {ErrorMessage, Form, Formik} from "formik";
import Input from "../../../components/ui/universal/Input";
import PasswordInput from "../../../components/ui/universal/PasswordInput";
import Spacer from "../../../components/ui/universal/Spacer";
import Tumbler from "../../../components/ui/universal/Tumbler";
import cogoToast from 'cogo-toast';
import * as Table from "../../../components/ui/universal/Table";
import * as DropActions from "../../../components/ui/universal/DropActions";
import * as auth from "../../../services/auth";
import {confirmAlert} from 'react-confirm-alert';
import store from "../../../store/store";
import router from "../../../router";
import {switchMatch} from "../../../utils";
import Moment from "react-moment";
import {LOADING_STATUSES} from "../../../definitions/loading-statuses";
import Padding from '../../../components/ui/universal/Padding';
import Loading from "../../../components/ui/universal/Loading";
import PrimaryButton from "../../../components/ui/universal/PrimaryButton";
import {ReactComponent as KeySVG} from "../../../static/images/svg/new/key.svg";
import router5 from "../../../router";
import {ReactComponent as RightSVG} from "../../../static/images/svg/new/chevronRight.svg";
import ReactPhoneInput from "react-phone-input-2";

const REQUIRED_FIELD_ERROR = 'Обязательное поле';

const Settings = () => {
  const dispatch = useDispatch();
  const {personal, devices} = useSelector((state => ({
    ...state.users
  })));

  const [state, setState] = useState({
    touched: false,
    touchedSecurity: false,
    personal,
    passwords: {
      new_password: '',
      secret_phrase: '',
    },
    is_closed: personal.is_closed
  });

  useEffect(() => {
    setState({
      ...state,
      personal,
      is_closed: personal.is_closed
    });

    if (Object.keys(devices).length === 0) {
      if (!loadingActions.getLoading(LOADING_STATUSES.LOAD_DEVICES_LOADING_KEY)) {
        dispatch(usersActions.getAllDevices());
      }
    }
  }, [personal]);

  const {params: routeParams, name: routeName} = router.getState();
  const {section: routeSection} = routeParams;

  const forimValidationSchema = (
    Yup.object().shape({
      first_name: Yup.string()
        .min(2, 'Имя не может быть короче 2 символов')
        .required(REQUIRED_FIELD_ERROR),

      last_name: Yup.string()
        .min(2, 'Фамилия не может быть короче 2 символов')
        .required(REQUIRED_FIELD_ERROR),

      gender: Yup.number()
        .required(REQUIRED_FIELD_ERROR),
    })
  );

  const renderForm = () => {
    return (
      <Formik
        enableReinitialize={true}
        initialValues={
          {
            first_name: personal.first_name,
            last_name: personal.last_name,
            gender: personal.gender,

          }
        }
        validationSchema={forimValidationSchema}
        onSubmit={(values, {setSubmitting, setErrors}) => {
          setState({...state, touched: false});
          const update = dispatch(usersActions.update(values));
          update.then((data) => {
            setSubmitting(true);
            cogoToast.success('Сохранено!');
            const my_id = JSON.parse(auth.getToken()).owner_id;
            store.dispatch(usersActions.get(my_id, {
              personal: true
            }));
          }, ({errors}) => {
            errors.forEach(({code, field}) => {
              switch (field) {
                case 'last_name':
                case 'first_name':
                  setErrors({
                    [field]: 'Некорректное значение',
                  });

                  setSubmitting(false);
                  cogoToast.error('Ошибка!');
                  break;
              }
            });
          });
        }}
        render={({
                   errors,
                   touched,
                   values,
                   setFieldValue,
                 }) => (
          <Form name="settings">
            <div>
              <div style={{width: '100%'}}>

                {/*{(*/}
                {/*  <div className="SettingPage_box">*/}
                {/*    <div className="SettingPage_box_left">*/}
                {/*      <Avatar*/}
                {/*        width={80}*/}
                {/*        height={80}*/}
                {/*        borderRadius={20}*/}
                {/*        src={personal.photo['450']}*/}
                {/*      />*/}
                {/*    </div>*/}

                {/*    <div*/}
                {/*      className="SettingPage_box_right"*/}
                {/*      onClick={() => {*/}
                {/*        Modals.open('AvatarUpload');*/}
                {/*      }}*/}
                {/*    >*/}
                {/*      <span>*/}
                {/*        Изменить фото*/}
                {/*      </span>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*)}*/}

                <div className="SettingPage_header">
                  <span>
                    Личные данные
                  </span>
                </div>

                <Spacer size={10}/>

                <Input
                  title="Имя"
                  name="first_name"
                  placeholder="Ваше имя"
                  value={values.first_name}
                  mode="secondary"
                  onChangeHandler={(e) => {
                    setFieldValue('first_name', e);
                    if (!state.touched) {
                      setState({...state, touched: true});
                    }
                  }}
                  error={!!(errors.first_name && touched.first_name)}
                  description={(errors.first_name && touched.first_name) && (
                    <ErrorMessage name="first_name" component="div" className="invalid-feedback"/>
                  )}
                />

                <Spacer size={10}/>

                <Input
                  title="Фамилия"
                  name="last_name"
                  mode="secondary"
                  placeholder="Ваша фамилия"
                  value={values.last_name}
                  onChangeHandler={(e) => {
                    setFieldValue('last_name', e);
                    if (!state.touched) {
                      setState({...state, touched: true});
                    }
                  }}
                  error={!!(errors.last_name && touched.last_name)}
                  description={(errors.last_name && touched.last_name) && (
                    <ErrorMessage name="last_name" component="div" className="invalid-feedback"/>
                  )}
                />

                <Spacer size={10}/>

                {/*<ReactPhoneInput*/}
                {/*  autoFormat*/}
                {/*  disableDropdown={true}*/}
                {/*  disableSearchIcon*/}
                {/*  countryCodeEditable={false}*/}
                {/*  enableSearch={false}*/}
                {/*  onlyCountries={['ru']}*/}
                {/*  country="ru"*/}
                {/*  placeholder="+7 915 123 45 67"*/}
                {/*  preferredCountries={['ru']}*/}
                {/*  disableCountryCode={false}*/}
                {/*  value={values.login}*/}
                {/*  isValid={() => !(errors.login && touched.login)}*/}
                {/*  masks={{ ru: '(...) ...-..-..' }}*/}
                {/*  onChange={(value, data) => {*/}
                {/*    setFieldValue('login', value);*/}
                {/*  }}*/}
                {/*/>*/}
                {/*{errors.login && touched.login && (*/}
                {/*  <div>*/}
                {/*    <ErrorMessage name="login" component="div" className="login--invalid-feedback" />*/}
                {/*  </div>*/}
                {/*)}*/}

                {/*<Spacer size={10}/>*/}

                <Input
                  title="Email"
                  name="first_name"
                  placeholder="Ваш email"
                  value={values.email}
                  mode="secondary"
                  onChangeHandler={(e) => {
                    setFieldValue('email', e);
                    if (!state.touched) {
                      setState({...state, touched: true});
                    }
                  }}
                  error={!!(errors.email && touched.email)}
                  description={(errors.email && touched.email) && (
                    <ErrorMessage name="email" component="div" className="invalid-feedback"/>
                  )}
                />

                <Spacer size={10}/>

                <Tumbler
                  on={values.gender === 2}
                  onChange={(e) => {
                    setFieldValue('gender', (values.gender === 2 ? 1 : 2));
                    if (!state.touched) {
                      setState({...state, touched: true});
                    }
                  }}
                  firstTitle="Мужской"
                  secondTitle="Женский"
                >
                  Пол
                </Tumbler>

                <Spacer size={10}/>

                <div>
                  <PrimaryButton
                    btnType={"submit"}
                    mode="primary"
                    text="Сохранить"
                  />

                </div>
              </div>
            </div>
          </Form>
        )}
      />
    );
  };

  const renderSecurityForm = () => {
    return (
      <div>
        <Formik
          enableReinitialize={true}
          initialValues={state.passwords}
          validationSchema={(
            Yup.object().shape({
              new_password: Yup.string()
                .min(6, 'Пароль не может быть короче 6 символов')
                .required(REQUIRED_FIELD_ERROR),

              secret_phrase: Yup.string()
                .required(REQUIRED_FIELD_ERROR),
            })
          )}
          onSubmit={(values, {setSubmitting, setErrors}) => {
            setState({...state, touchedSecurity: false});
            const changePassword = dispatch(usersActions.changePassword(values));
            changePassword.then((data) => {
              setSubmitting(true);
              cogoToast.success('Пароль изменен!');
            }, ({errors}) => {
              errors.forEach(({code, field}) => {
                switch (field) {
                  case 'secret_phrase':
                    setErrors({
                      [field]: 'Секретное слово указано не правильно',
                    });

                    setSubmitting(false);
                    break;
                }
              });
            });
          }}
          render={({
                     errors,
                     touched,
                     values,
                     setFieldValue,
                   }) => (
            <Form name="passwords">
              <div className="display_flex">
                <div style={{width: `100%`, borderBottom: "1px solid #0000001f"}}>
                  <Padding vertical={2} horizontal={2}>
                    <div>
                      <PasswordInput
                        title="Новый пароль"
                        name="new_password"
                        mode="secondary"
                        placeholder="Придумайте новый пароль"
                        value={values.new_password}
                        onChangeHandler={(e) => {
                          setFieldValue('new_password', e);
                          if (!state.touched) {
                            setState({...state, touchedSecurity: true});
                          }
                        }}
                        error={!!(errors.new_password && touched.new_password)}
                        description={(errors.new_password && touched.new_password) && (
                          <ErrorMessage name="new_password" component="div" className="invalid-feedback"/>
                        )}
                      />
                    </div>

                    <Spacer size={10}/>

                    <div>
                      <Input
                        title="Секретное слово"
                        name="secret_phrase"
                        mode="secondary"
                        placeholder="Введите, указанное при регистрации"
                        value={values.secret_phrase}
                        onChangeHandler={(e) => {
                          setFieldValue('secret_phrase', e);
                          if (!state.touchedSecurity) {
                            setState({...state, touchedSecurity: true});
                          }
                        }}
                        error={!!(errors.secret_phrase && touched.secret_phrase)}
                        description={(errors.secret_phrase && touched.secret_phrase) && (
                          <ErrorMessage name="secret_phrase" component="div" className="invalid-feedback"/>
                        )}
                      />
                    </div>
                  </Padding>
                  <div>
                    <Padding horizontal={2} vertical={2}>
                      <div>
                        <PrimaryButton
                          text="Изменить пароль"
                          mode="primary"
                          btnType="submit"
                        />
                      </div>

                      {/*<Button*/}
                      {/*  btnType="submit"*/}
                      {/*  size="large"*/}
                      {/*  disabled={!state.touchedSecurity}*/}
                      {/*>*/}
                      {/*  Изменить пароль*/}
                      {/*</Button>*/}
                    </Padding>
                  </div>
                </div>
              </div>


            </Form>
          )}
        />
      </div>
    );
  };


  const renderDevicesTable = () => {
    return (
      <div>
        <Padding vertical={2} horizontal={2}>
          <div className="SettingPage_header">
          <span>
            Активные сессии &raquo; {devices.count}
          </span>
          </div>

          <div>
            <Spacer size={6}/>
            <div>
              <Table.Main
                data={
                  devices.data.map((device) => {
                    return {
                      ...device,
                      last_updated: (
                        <Moment format="D MMM YYYY &raquo; HH:mm:ss" withTitle>
                          {device.last_updated * 1000}
                        </Moment>
                      )
                    }
                  })
                }
              >
                <Table.Column prop="ua" label="Платформа"/>
                <Table.Column prop="ip" label="IP"/>
                <Table.Column prop="last_updated" label="Время обновления" width={60}/>
                <Table.Column
                  width={30}
                  prop="action"
                  content={(data) => {
                    return (
                      <div>
                        <DropActions.Main
                          left={30}
                          right={40}
                          width={140}
                          items={[
                            {
                              title: 'Удалить',
                              onClick: () => {
                                confirmAlert({
                                  title: 'Вы точно хотите удалить сессию?',
                                  buttons: [
                                    {
                                      label: 'Да',
                                      onClick: () => {
                                        auth.removeTokenById(data.id).then(() => {
                                          dispatch(usersActions.getAllDevices());
                                        });
                                      }
                                    },
                                    {
                                      label: 'Нет',
                                      onClick: () => {
                                      }
                                    }
                                  ]
                                });
                              }
                            },
                          ]}
                        >
                          <DropActions.Button/>
                        </DropActions.Main>
                      </div>
                    );
                  }}
                />
              </Table.Main>
            </div>

            <div>
              <Spacer size={10}/>

              <PrimaryButton
                text="Удалить все сессии (кроме текущей)"
                mode="primary"
                onClick={() => {
                  confirmAlert({
                    title: 'Вы точно хотите удалить остальные сессии?',
                    buttons: [
                      {
                        label: 'Да',
                        onClick: () => {
                          const {access_token} = JSON.parse(auth.getToken());
                          auth.removeAllTokens(access_token).then(() => {
                            dispatch(usersActions.getAllDevices());
                          });
                        }
                      },
                      {
                        label: 'Нет',
                        onClick: () => {
                        }
                      }
                    ]
                  });
                }}
              />
              <Spacer size={5} direction="vertical"/>
            </div>
          </div>
        </Padding>
      </div>
    );
  };

  const renderMainBlock = () => {
    return switchMatch(routeSection, {
      general: (
        <div>
          <Padding horizontal={3} vertical={2}>

            <Spacer size={5}/>

            {renderForm()}

            <Spacer size={10}/>

            <div className="SettingPage_item">
              <div className="SettingPage_item_left">
                <KeySVG/>
              </div>
              <div
                className="SettingPage_item_center"
                onClick={() => router5.navigate('settings', {
                  section: 'security'
                })}
              >
                <span>
                  Сменить пароль
                </span>
              </div>
              <div className="SettingPage_item_right">
                <RightSVG/>
              </div>
            </div>

          </Padding>
        </div>
      ),
      privacy: (
        <div>
          <Padding horizontal={2} vertical={2}>
            <div className="SettingPage_header">
              <span>
                Профиль
              </span>
            </div>

          <Spacer direction="vertical" size={6}/>

          <Tumbler
            on={state.is_closed === 1}
            onChange={(e) => {
              const setIsClosed = dispatch(usersActions.setProfileIsClosed(state.is_closed === 1 ? 0 : 1));
              setState({
                ...state,
                is_closed: state.is_closed === 1 ? 0 : 1
              });

              setIsClosed.then(() => {
                cogoToast.success('Сохранено!');
              }, () => {
                cogoToast.error('Ошибка');
              });
            }}
            firstTitle="Открытый"
            secondTitle="Закрытый"
          >
            Профиль
          </Tumbler>
          </Padding>
          <Spacer direction="vertical" size={6}/>
        </div>
      ),
      security: (
        <div>
          <Padding horizontal={2} vertical={2}>
            <div className="SettingPage_header">
              <span>
                Пароль
              </span>
            </div>
          </Padding>

          {renderSecurityForm()}

          {renderDevicesTable()}
        </div>
      ),
    });
  };

  return (
    Object.keys(personal).length === 0 || Object.keys(devices).length === 0 ? (
      <div>
        <Loading/>
      </div>
    ) : (
      renderMainBlock()
    )
  );
};


export default Settings;
