import "./index.scss";
import React, {useState} from "react";
import Padding from "../../ui/universal/Padding";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuidv4} from "uuid";
import classNames from "classnames";
import {commonActions} from "../../../store/actions/common-actions";
import Loading from "../../../components/ui/universal/Loading";
import {useGetMyPointInfo} from "../../../hooks";

const ProductCategorySelection = (props) => {
  const {onClickClose} = props;
  const { pointId } = useGetMyPointInfo();
  const dispatch = useDispatch();
  const { activeCategory } = useSelector((state) => ({
    activeCategory: state.common.activePointCategoryId
  }));

  const setActiveCategory = (val) => {
    dispatch(commonActions.setActivePointCategoryId(val));
  };

  const {categories} = useSelector((store) => ({
    categories: store.common.categories,
    loadingStatus: store.common.loadingStatus
  }));

  const sortable = (a , b ) => {
    const categoryA = categories[pointId][a];
    const categoryB = categories[pointId][b];
    if (categoryA.priority > categoryB.priority) {
      return -1;
    } else {
      return 1;
    }
  };

  if (!Object.keys(categories).length) {
    return (
      <Loading/>
    );
  }

  return (
    <div>
      <div className="ProductCategorySelection">
        <div
          className="ProductCategorySelection_close"
          onClick={onClickClose}
        >
          <div className="ProductCategorySelection_close_icon"/>
        </div>
        <div className="ProductCategorySelection_content">
          <Padding vertical={2} horizontal={2}>
            <div className="ProductCategorySelection_content_title">
              <span>
                Выбор категории:
              </span>
            </div>
          <div>
            {Object.keys(categories[pointId]).sort(sortable).map((_category) => {
              const category = categories[pointId][_category];
              return (
                <div
                  key={uuidv4()}
                  className={classNames('PointCategories_item', {
                    'active': category.id === activeCategory
                  })}
                  onClick={() => {
                    setActiveCategory(category.id);
                    onClickClose();
                  }}
                >
								<span>
									{category.title}
								</span>
                </div>
              );
            })}
          </div>
          </Padding>
        </div>
      </div>
    </div>
  );
};

export default ProductCategorySelection