import {useDispatch, useSelector} from 'react-redux';
import router from "../router";
import {useEffect} from "react";
import {commonActions} from "../store/actions/common-actions";

export const useGetMyPointInfo = () => {
  const dispatch = useDispatch();
  let {params: {id: pointId}} = router.getState();
  pointId = +pointId;

  const { pointsById } = useSelector((state: any) => {
    return {
      pointsById: state.common.pointsById
    }
  });

  useEffect(() => {
    if (!(pointId in pointsById)) {
      dispatch(commonActions.getPoints({ id: pointId }));
    }
  }, []);

  let pointInfo = null;
  const isLoadedThisPoint = pointId in pointsById;

  if (isLoadedThisPoint) {
    pointInfo = pointsById[pointId];
  }

  return {
    isLoadedThisPoint,
    pointInfo,
    pointId
  }
};

export default useGetMyPointInfo;
