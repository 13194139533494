import "./index.scss";
import React, { useEffect, useState } from "react";
import Padding from "../../../components/ui/universal/Padding";
import Input from "../../../components/ui/universal/Input";
import { ReactComponent as SearchSVG } from "../../../components/ui/mobile/HeaderIcon/assets/svg/search.svg";
import { ReactComponent as EditSVG } from "../../../components/ui/mobile/HeaderIcon/assets/svg/edit.svg";
import * as Modals from "../../../components/ui/universal/Modals";
import { isEmptyObject } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "../../../store/actions/common-actions";
import Loading from "../../../components/ui/universal/Loading";
import { useGetMyPointInfo } from "../../../hooks";
import { intTo4digitsString } from "../../../utils/intTo4digitsString";
import BriefItem from "../../../components/common/BriefItem";
import IsEmptyHandler from "../../../components/common/is-empty-handler";
import Pagination from "../../../components/common/pagination";
import { LOADING_STATUS_IN_PROCESS } from "../../../definitions";

const appendCursor = 20;

const SpecialOrdersPage = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  // const [hiddenInfo, setHiddenInfo] = useState( false);
  const { pointId } = useGetMyPointInfo();
  const { pointOrders } = useSelector((state) => ({
    pointOrders: state.common.pointOrders,
  }));

  const { loadingStatus } = useSelector((store) => ({
    loadingStatus: store.common.loadingStatus,
  }));

  const isLoading = isEmptyObject(pointOrders);

  useEffect(() => {
    if (isEmptyObject(pointOrders)) {
      dispatch(commonActions.getPointOrders({ point_id: pointId }));
    }
  }, [pointId]);

  const updateOrders = () => {
    dispatch(commonActions.getPointOrders({ point_id: pointId }));
  };

  if (isLoading) {
    return <Loading />;
  }

  const orders = {
    append: () => {
      dispatch(
        commonActions.getPointOrders(
          {
            cursor: pointOrders.hasNext.cursor + appendCursor,
            point_id: pointId,
          },
          true,
        ),
      );
    },
  };

  const { rows } = pointOrders;
  const renderPointOrders = () => {
    return (
      <div>
        <IsEmptyHandler counterText="Всего заказов:" counter={pointOrders.countAll} showCounter={true}>
          <Pagination
            hasNext={pointOrders.hasNext.hasNext}
            loadingStatus={loadingStatus["ordersPointIsLoading"] === LOADING_STATUS_IN_PROCESS}
            loadingMore={orders.append}>
            {rows.map((itemKey, key) => {
              return renderPointItem({ itemKey, key });
            })}
          </Pagination>
        </IsEmptyHandler>
      </div>
    );
  };

  const renderPointItem = ({ itemKey }) => {
    const { id: orderId, create_time, status_id } = itemKey;
    const time = new Date(create_time * 1000);
    const createTime = time.toLocaleDateString("ru-RU") + " " + time.toLocaleTimeString("ru-RU");

    return (
      <div>
        <div
          className="SpecialOrdersPage_item"
          onClick={() => {
            Modals.open(
              "orderDetails",
              { orderId },
              {},
              {
                onCloseHandler: () => {
                  updateOrders();
                },
              },
            );
          }}>
          <div className="SpecialOrdersPage_item_info">
            <div className="SpecialOrdersPage_item_info_id">
              <span>{"#" + intTo4digitsString(orderId)}</span>
            </div>
            <div className="SpecialOrdersPage_item_info_date">
              <span>{createTime}</span>
            </div>
          </div>
          <div className="SpecialOrdersPage_item_briefBox">
            <div className="SpecialOrdersPage_item_briefBox_icon">
              <EditSVG />
            </div>
            <BriefItem status_id={status_id} mode={status_id} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Padding vertical={2} horizontal={2}>
        <Input icon={<SearchSVG />} mode="secondary" placeholder={"поиск"} value={value} onChangeHandler={setValue} />
      </Padding>
      <div>{renderPointOrders()}</div>
    </div>
  );
};

export default SpecialOrdersPage;
