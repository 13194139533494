import './index.scss';
import React from 'react';
import {useDispatch} from 'react-redux';
import {
  Formik,
  Form,
  ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import { isEmptyObject } from '../../../utils';
import { login } from '../../../services/auth';
import Input from '../../../components/ui/universal/Input';
import PasswordInput from '../../../components/ui/universal/PasswordInput';
import Card from '../../../components/ui/universal/Card';
import Spacer from '../../../components/ui/universal/Spacer';
import '../../../static/styles/input-phone.scss';
import ReactPhoneInput from 'react-phone-input-2';
import { ISignInContent } from './interface';
import PrimaryButton from "../../../components/ui/universal/PrimaryButton";
import {usersActions} from "../../../store/actions"
import cogoToast from "cogo-toast";
import Tumbler from "../../../components/ui/universal/Tumbler";
import { client_secret,client_id } from "../../../services/auth";
import { ReactComponent as BackSVG } from "../../../static/images/svg/new/left_chevron.svg";
import * as Modals from "../../../components/ui/universal/Modals";

const REQUIRED_FIELD_ERROR = 'Обязательное поле';

const RegistrationContent: React.FC<ISignInContent> = () => {
  const dispatch = useDispatch();
  const InitialFormikState = {
    first_name: '',
    login: '',
    password: '',
    secret_phrase: '',
    client_id,
    client_secret,
    gender: 1,
  };
  const formikValidationSchema = (
    Yup.object().shape({
      login: Yup.string()
        .required(REQUIRED_FIELD_ERROR),

      password: Yup.string()
        .min(6, 'Пароль должен быть не менее 6 символов')
        .required(REQUIRED_FIELD_ERROR),

      first_name: Yup.string()
        .required(REQUIRED_FIELD_ERROR),

      secret_phrase: Yup.string()
        .required(REQUIRED_FIELD_ERROR),
    })
  );

  const renderForm = () => {
    return (
      <div>
        <Formik
          initialValues={InitialFormikState}
          validationSchema={formikValidationSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            const Add:any = dispatch(usersActions.signUp({...values}));
            Add.then((response :any) => {
              if (response.status) {
                cogoToast.success('Успешно добавлен!');
                setSubmitting(true);
                Modals.clear()
                Modals.open('auth', {}, {}, {});
              }
            }, (response :any) => {
              if (!response.status) {

              }
              setSubmitting(false);
              cogoToast.error('Ошибка регистрации');
            });
          }}
          render={({
            errors,
            touched,
            values,
            setFieldValue,
          }) => (
            <Form>
              <ReactPhoneInput
                autoFormat
                disableDropdown={true}
                disableSearchIcon
                countryCodeEditable={false}
                enableSearch={false}
                onlyCountries={['ru']}
                country="ru"
                placeholder="+7 915 123 45 67"
                preferredCountries={['ru']}
                disableCountryCode={false}
                value={values.login.toString()}
                isValid={() => !(errors.login && touched.login)}
                masks={{ ru: '(...) ...-..-..' }}
                onChange={(value, data) => {
                  setFieldValue('login', value);
                }}
              />
              {errors.login && touched.login && (
                <div>
                  <ErrorMessage name="login" component="div" className="login--invalid-feedback" />
                </div>
              )}

              <Spacer size={13} />

              <Input
                title="Ваше имя"
                name="name"
                mode="secondary"
                placeholder="Василий"
                value={values.first_name}
                onChangeHandler={(e) => setFieldValue('first_name', e)}
                error={!!(errors.first_name && touched.first_name)}
                description={(errors.first_name && touched.first_name) && (
                  <ErrorMessage name="first_name" component="div" className="invalid-feedback" />
                )}
              />

              <Spacer size={13} />

              <Tumbler
                on={values.gender === 2}
                onChange={(e) => {
                  setFieldValue('gender', (values.gender === 2 ? 1 : 2));
                }}
                firstTitle="Женский"
                secondTitle="Мужской"
              >
                Пол
              </Tumbler>

              <Spacer size={13} />

              <PasswordInput
                title="Ваш пароль"
                name="password"
                mode="secondary"
                placeholder="***"
                value={values.password}
                onChangeHandler={(e) => setFieldValue('password', e)}
                error={!!(errors.password && touched.password)}
                description={errors.password && touched.password && (
                  <ErrorMessage name="password" component="div" className="invalid-feedback" />
                )}
              />

              <Spacer size={13} />

              <Input
                title="Секретное слово"
                name="secret_phrase"
                mode="secondary"
                placeholder="Для смены пароля"
                value={values.secret_phrase}
                onChangeHandler={(e) => setFieldValue('secret_phrase', e)}
                error={!!(errors.secret_phrase && touched.secret_phrase)}
                description={(errors.secret_phrase && touched.secret_phrase) && (
                  <ErrorMessage name="secret_phrase" component="div" className="invalid-feedback" />
                )}
              />

              <Spacer size={15} />

              <div>
                <PrimaryButton
                  text="Зарегистрироваться"
                  mode="primary"
                  btnType={"submit"}
                />

                <Spacer size={4} direction="vertical" />

                {!isEmptyObject(touched) && [
                  <PrimaryButton
                    text="Сбросить"
                    mode="red"
                    btnType="reset"
                  />,

                  <Spacer size={4} direction="vertical" />
                ]}
              </div>
            </Form>
          )}
        />
      </div>
    );
  };

  const renderMainBlock = () => {
    return (
      <div className="Registration">
        <Card verticalPadding={3} horizontalPadding={3}>
          <div className="relative">
            <Card transparent={true} horizontalPadding={2} shadow={false}>
              <div className="Registration_header">
                <div
                  className="Registration_header_left"
                  onClick={() => {
                    Modals.clear().then(() => {
                      Modals.open('auth', {}, {}, {});
                    })
                  }}
                >
                  <BackSVG/>
                </div>
                <div className="Registration_header_center">
                  <span>
                    Регистрация
                  </span>
                </div>
                <div className="Registration_header_right"/>
              </div>

              <Spacer size={10}/>
              <Spacer direction="vertical" size={4} />

              {renderForm()}

              <Spacer direction="vertical" size={4} />

            </Card>
          </div>
        </Card>
      </div>
    );
  };

  return renderMainBlock();
};

export default RegistrationContent;
