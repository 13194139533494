export { randomIntFromInterval } from './random';
export { switchMatch } from './switchMatch';
export { ucfirst, nl2br } from './text';
export { isMobile } from './mobile';
export { isBrowser } from './isBrowser';
export { isEmptyObject } from './isEmptyObject';
export { IsJsonString } from './isJson';
export * from './getDayByNumber';
export { canUseDOM, canUseEventListeners } from './dom';
export { ANDROID, IOS, OS, platform } from './platform';
export { getSupportedEvents, coordX, coordY, touchEnabled, rubber } from './touch';
export { isNumeric } from './isNumeric';

export function getObjectByIdFromArray(array: any, id: number) {
  return array.filter(({ id: _id}: any) => {
    return id === _id;
  });
}
