import "./index.scss";
import React, {useEffect, useState} from "react";
import Padding from "../../../components/ui/universal/Padding";
import Tumbler from "../../../components/ui/universal/Tumbler";
import Spacer from "../../../components/ui/universal/Spacer";
import PrimaryButton from "../../../components/ui/universal/PrimaryButton";
import * as Modals from "../../../components/ui/universal/Modals";
import router from "../../../router";
import Loading from "../../../components/ui/universal/Loading";
import {commonActions} from "../../../store/actions/common-actions";
import {useDispatch, useSelector} from "react-redux";
import {isEmptyObject} from "../../../utils";
import {ReactComponent as EditSVG} from "../../../static/images/svg/new/edit.svg";

const OptionsPage = () => {
  const [tumblerOn, setTumblerOn] = useState(' ');
  const dispatch = useDispatch();
  const {params: routeParams} = router.getState();
  const {subjectId: subjectId} = routeParams;

  const {additionsById, variationsById} = useSelector((store) => ({
    additionsById: store.common.additionsById,
    variationsById: store.common.variationsById,
  }));

  const updateSubjects = () => {
    dispatch(commonActions.getVariations({ subject_id: subjectId }));
    dispatch(commonActions.getAdditions({ subject_id: subjectId }));
  };

  useEffect(() => {
    dispatch(commonActions.getVariations({ subject_id: subjectId }));
    dispatch(commonActions.getAdditions({ subject_id: subjectId }));
  },[])

  const isLoading = isEmptyObject(additionsById) || isEmptyObject(variationsById);

  if (isLoading) {
    return (
      <div>
        <Loading/>
      </div>
    );
  }

  const renderInfoAdditions = () => {
    return (
      <div className="OptionsPage_info">
       <div className="OptionsPage_info_button">
         <PrimaryButton
           text="Добавить"
           mode="primary"
           onClick={() => {
             Modals.open('addAdditions', {subjectId}, {}, {
                 onCloseHandler: () => {
                   updateSubjects();
                 }
               }
             );
           }}
         />
       </div>
        <div className="OptionsPage_info_box">
          <span>
            Вы еще не добавили ни одной опции, сделайте это что бы они тут отображались
          </span>
        </div>
      </div>
    );
  };

  const renderInfoVariations = () => {
    return (
      <div className="OptionsPage_info">
        <div className="OptionsPage_info_button">
          <PrimaryButton
            text="Добавить"
            mode="primary"
            onClick={() => {
              Modals.open('addVariations', {subjectId}, {}, {
                  onCloseHandler: () => {
                    updateSubjects();
                  }
                }
              );
            }}
          />
        </div>
        <div className="OptionsPage_info_box">
          <span>
            Вы еще не добавили ни одной опции, сделайте это что бы они тут отображались
          </span>
        </div>
      </div>
    );
  };

  const renderVariations = () => {
    return(
      <div>
        <PrimaryButton
          text="Добавить"
          mode="primary"
          onClick={() => {
            Modals.open('addVariations', {subjectId}, {}, {
              onCloseHandler: () => {
                updateSubjects();
              }
              }
            );
          }}
        />
        <Spacer size={5}/>
        <div className="OptionsPage_box">
          {variationsById[subjectId].map((variationItem) => {
            const {id: variationItemId, title, description, price} = variationItem;
            return (
              <div className="OptionsPage_box_item">
                <div className="OptionsPage_box_item_left">
                  <span>
                    {title}
                  </span>
                </div>
                <div className="OptionsPage_box_item_center">
                   <span className="gray">
                    {description}
                  </span>
                </div>
                <div className="OptionsPage_box_item_right">
                  <span className="gray">
                    {price} ₽
                  </span>
                </div>
                <div
                  className="OptionsPage_box_item_icon"
                  onClick={() => {
                    Modals.open('editVariations', {variationItemId}, {}, {
                      onCloseHandler: () => {
                        updateSubjects();
                      }
                      }
                    );
                  }}
                >
                  <EditSVG/>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    );
  };

  const renderAdditions = () => {
    return(
      <div>
        <PrimaryButton
          text="Добавить"
          mode="primary"
          onClick={() => {
            Modals.open('addAdditions', {subjectId}, {}, {
              onCloseHandler: () => {
                updateSubjects();
                }
              }
            );
          }}
        />
        <Spacer size={5}/>
        <div className="OptionsPage_box">
          {additionsById[subjectId].map((additionItem) => {
            const {id: additionItemId, title, description, price} = additionItem
            return (
              <div className="OptionsPage_box_item">
                <div className="OptionsPage_box_item_left">
                  <span>
                    {title}
                  </span>
                </div>
                <div className="OptionsPage_box_item_center">
                    <span className="gray">
                    {description}
                  </span>
                </div>
                <div className="OptionsPage_box_item_right">
                   <span className="gray">
                    {price} ₽
                  </span>
                </div>
                <div
                 className="OptionsPage_box_item_icon"
                 onClick={() => {
                   Modals.open('editAdditions', {additionItemId}, {}, {
                       onCloseHandler: () => {
                         updateSubjects();
                       }
                     }
                   );
                 }}
                >
                  <EditSVG/>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    );
  };

  return (
    <div>
      <Padding horizontal={2} vertical={2}>
        <div>
          <Tumbler
            on={tumblerOn}
            onChange={() => setTumblerOn(!tumblerOn)}
            firstTitle="адитеонсы"
            secondTitle="вореации"
          />
        </div>
        <Spacer size={10} />
        <div>
          {!tumblerOn && (
            <div>
              {additionsById[subjectId].length ? (
                <div>
                  {renderAdditions()}
                </div>
              ) : (
                <div>
                  {renderInfoAdditions()}
                </div>
              )}
            </div>
          )}
          {!!tumblerOn && (
            <div>
              {variationsById[subjectId].length ? (
               <div>
                 {renderVariations()}
               </div>
              ) : (
                <div>
                  {renderInfoVariations()}
                </div>
              )}
            </div>
          )}
        </div>
      </Padding>
    </div>
  )
};

export default OptionsPage;