import './index.scss';
import React, { useState} from 'react';
import {ReactComponent as DownSVG} from "../../../static/images/svg/new/down_chevron.svg";
import {useSelector} from 'react-redux';
import Spacer from "../../ui/universal/Spacer";
import {v4 as uuidv4} from "uuid";

const BasketRowItem = (props) => {
  const {name, photo, price, counter, originalPrice} = props;
  const [hiddenInfo, setHiddenInfo] = useState(true);

  const {variationsById, additionsById, selectedVariations, selectedAdditions} = useSelector((state) => ({
    variationsById: state.common.variationsById,
    additionsById: state.common.additionsById,
    selectedAdditions: state.common.selectedAdditions,
    selectedVariations: state.common.selectedVariations,
  }));

  const thisItem = {
    data: {...props},
    id: props.id,
  };

  thisItem.additions = selectedAdditions.get([...selectedAdditions.keys()].filter((item) => item === thisItem.id)[0]);

  const hasVariation = (!!selectedVariations.get(thisItem.id));
  const hasAdditions = !!thisItem.additions;
  const hasOptions = (hasAdditions || hasVariation);
  let priceForAll = counter * originalPrice;

  delete thisItem.data.onChange;
  delete thisItem.data.photo;

  const getBuildedVariation = () => {
    if (hasVariation) {
      const variationData = (Object.values(variationsById[thisItem.id])
        .filter(({id}) => id === (+selectedVariations.get(thisItem.id)))[0]);
      return {
        summaryPrice: variationData.price,
        data: variationData
      }
    } else {
      return {
        summaryPrice: 0,
        data: {}
      }
    }
  }

  const getBuildedArrayOfAdditions = () => {
    if (hasAdditions) {
      let summaryPrice = 0;
      const additionsData = [...thisItem.additions].map((item) => {
        const count = item[1].count;
        const thisAddition = ([...Object.values(additionsById[thisItem.id])].filter(({id}) => {
          return id === item[1].id;
        })[0]);
        const summaryAdditionPrice = count * thisAddition.price;
        summaryPrice = summaryPrice + summaryAdditionPrice;
        return {
          ...thisAddition,
          summaryAdditionPrice,
          count,
        }
      });
      return {
        summaryPrice,
        data: additionsData
      };
    } else {
      return {
        summaryPrice: 0,
        data: []
      };
    }
  };

  let buildedArrayOfAdditions = {
    summaryPrice: 0,
    data: []
  };

  if (hasAdditions) {
    buildedArrayOfAdditions = getBuildedArrayOfAdditions();
  }

  let buildedVariation = {
    summaryPrice: 0,
    data: {}
  };

  if (hasVariation) {
    buildedVariation = getBuildedVariation();
  }

  priceForAll = priceForAll + ((buildedVariation.summaryPrice + buildedArrayOfAdditions.summaryPrice) * counter);

  return (
    <div className="BasketRowItem_box">
      <div className="w-100 display_flex">
        <div className="BasketRowItem_box_info">
          <div className="BasketRowItem_box_info_productName">
            <div>
              <span>
                {name}
              </span>
            </div>
            <div className="BasketRowItem_box_info_productName_counter">
              Количество: {counter}
            </div>
            {hasOptions && (
              <div
                className="BasketRowItem_box_info_productName_icon"
                onClick={() => setHiddenInfo(!hiddenInfo)}
              >
                <DownSVG/>
              </div>
            )}
          </div>
          <div className="BasketRowItem_box_info_orderPage">
            <div className="BasketRowItem_box_info_orderPage_price">
              {priceForAll} ₽
            </div>
          </div>
        </div>

        <div
          className="BasketRowItem_box_card"
          style={{backgroundImage: `url(${photo})`}}
        />
      </div>

      {!hiddenInfo && hasOptions && (
        <div className="BasketRowItem_hidden">
          <div className="Basket_item_hidden_header">
            <span>
              Ценообразование
            </span>
          </div>
          {hasVariation ? (
            <div className="BasketRowItem_hidden_table">
              <div>
                <div className="BasketRowItem_hidden_table_header">
                 <span>
                    Вариант:
                 </span>
                </div>

                <div className="BasketRowItem_hidden_table_item">
                  <div className="BasketRowItem_hidden_table_item_element">
                    {buildedVariation.data.title}:
                  </div>
                  <div className="BasketRowItem_hidden_table_item_element">
                    + {buildedVariation.data.price * counter}₽
                  </div>
                </div>
                <div className="BasketRowItem_hidden_table_item">
                <span>
                  {/*({counter}ед.т. * {buildedVariation.data.price}₽)*/}
                  (+ {buildedVariation.data.price}₽) к каждой единице товара
                  </span>
                </div>
              </div>
            </div>
          ):(
            <div></div>
          )}
          {hasAdditions && (
            <div>
              <div className="BasketRowItem_hidden_table">
                <div className="BasketRowItem_hidden_table_header">
                 <span>
                   Дополнительно:
                 </span>
                </div>
                <div>
                  {buildedArrayOfAdditions.data.map(({title, price, count}) => {
                    return <div key={"buildedArrayOfAdditions" + title}>
                      <div className="BasketRowItem_hidden_table_item">
                        <div className="BasketRowItem_hidden_table_item_element">
                          {title} ({count} шт):
                        </div>
                        <div className="BasketRowItem_hidden_table_item_element">
                          + {price * count}₽
                        </div>
                      </div>
                      <div className="BasketRowItem_hidden_table_item">
                        <span>
                         (к каждой единице товара)
                        </span>
                      </div>
                    </div>;
                  })}
                </div>
              </div>

              <div className="BasketRowItem_hidden_table_header">
                 <span>
                    Итого:
                 </span>
              </div>
              <div className="BasketRowItem_hidden_table_item">
                <span>
                  За дополнения:
                </span>
                <div className="BasketRowItem_hidden_table_item_element">
                  + {buildedArrayOfAdditions.summaryPrice * counter}₽
                </div>
              </div>
            </div>
          )}
          {hasOptions && (
            <div>
              <div className="BasketRowItem_hidden_table_item">
                <span>
                  Всего за опции:
                </span>
                {hasVariation === false ? (
                  <div className="BasketRowItem_hidden_table_item_element">
                    + 0₽
                  </div>
                ) : (
                  <div className="BasketRowItem_hidden_table_item_element">
                    + {(buildedArrayOfAdditions.summaryPrice * counter) + (buildedVariation.data.price * counter)}₽
                  </div>
                )}
                </div>
              <Spacer size={3}/>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BasketRowItem;
