//
import "./index.scss";
import "../../../static/styles/sliderStyle.scss"
import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {useSelector, useDispatch} from 'react-redux';
import {commonActions} from '../../../store/actions'
import router from '../../../router';
import {PAGE_ROUTES} from "../../../definitions";
import Spacer from "../../../components/ui/universal/Spacer";
import NewPointOnList from "../../../components/common/NewPointOnList";
import Heading from "../../../components/ui/universal/Heading";
import Padding from "../../../components/ui/universal/Padding";
import { Icon24Filter, Icon24DeleteOutline } from '@vkontakte/icons';
import Card from "../../../components/ui/universal/Card";
import Cell from "../../../components/ui/mobile/Cell";
import PrimaryButton from "../../../components/ui/universal/PrimaryButton";
import {isEmptyObject, isMobile} from "../../../utils";
import ItemsCarousel from "react-items-carousel";
import IconUniversal from "../../../components/ui/universal/IconUniversal";
import { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';

const image = ['https://user36270.clients-cdnnow.ru/1624963085642-180x180@2x.png', 'https://user36270.clients-cdnnow.ru/1621954830250-180x180@2x.webp','https://user36270.clients-cdnnow.ru/1620825216256-180x180@2x.webp','https://user36270.clients-cdnnow.ru/1625223203451-180x180@2x.webp','https://user36270.clients-cdnnow.ru/1620825947123-180x180@2x.webp','https://user36270.clients-cdnnow.ru/1626705289737-180x180@2x.webp'];

const MainPage = () => {
  const dispatch = useDispatch();
  const [selectedTags, setSelectedTags] = useState([]);
  const [isVisibleSelect, setIsVisibleSelect] = useState(false);
  const [isEnableFilterAverageCheck, setIsEnableFilterAverageCheck] = useState(false);
  const [rangeSliderMin, setRangeSliderMin] = useState(500);
  const [rangeSliderMax, setRangeSliderMax] = useState(2000);
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  const {city, activeSection, tags, points} = useSelector((store) => ({
    city: store.common.city,
    activeSection: store.common.activeSection,
    tags: store.common.tags,
    points: store.common.points,
  }));

  function randomInteger(min, max) {
    let rand = min - 0.5 + Math.random() * (max - min + 1);
    return Math.round(rand);
  }


  const tagToggle = ({id}) => {
    if (selectedTags.includes(id)) {
      const index = selectedTags.indexOf(id);
      if (index > -1) {
        const copy = [...selectedTags];
        copy.splice(index, 1);
        setSelectedTags([...copy]);
      }
    } else {
      setSelectedTags([
        ...selectedTags,
        id
      ]);
    }
  };

  const filters = {
    getCityId: () => {
      return city.id;
    },
    getSectionId: () => {
      return activeSection.id;
    },
    getTags: () => {
      return selectedTags.join(',');
    }
  };

  const tagsAsString = filters.getTags();

  useEffect(() => {
    const params = {
      city_id: filters.getCityId(),
      section_id: filters.getSectionId(),
      tags_ids: filters.getTags()
    };

    const {city_id, section_id, tags_ids} = params;
    const sendParams = {
      city_id,
      section_id,
    };

    if (tags_ids.length > 0) {
      sendParams.tags_ids = tags_ids;
    }

    dispatch(commonActions.getPoints(sendParams));
  }, [city.id, activeSection.id, tagsAsString]);

  const clearFilters = () => {
    setRangeSliderMin(500);
    setRangeSliderMax(2000);
    setIsEnableFilterAverageCheck(false);
  };

  const renderTabs = () => {
    const chevronWidth = 40;
    return (
      <div className="MainPage_carousel no_select">
        {Object.keys(tags).map((tagKey, i) => {
          const tag = tags[tagKey];
          const active = selectedTags.includes(tag.id);

          if (tag.id === 101) {
            return (
              <div
                className={classNames('MainPage_carousel_open', {
                  'active': active
                })}
                onClick={() => tagToggle({id: tag.id})}
              >
                <span>
                  Открыто
                </span>
              </div>
            );
          }
        })}

        <div className="MainPage_carousel_box" style={{width: `${0}px`}}>
          <ItemsCarousel
            key={'224353532fewf'}
            requestToChangeActive={setActiveItemIndex}
            activeItemIndex={activeItemIndex}
            numberOfCards={isMobile() ? 3 : 5}
            gutter={0}
            leftChevron={(
              <button className="MainPage_carousel_box_cursorLeft">
                <IconUniversal typeIcon="newLeft"/>
              </button>
            )}
            rightChevron={(
              <button className="MainPage_carousel_box_cursorRight">
                <IconUniversal typeIcon="newRight"/>
              </button>
            )}
            outsideChevron={false}
            chevronWidth={chevronWidth}
          >
            {Object.keys(tags).map((tagKey, i) => {
              const tag = tags[tagKey];
              const active = selectedTags.includes(tag.id);
              const others = {};

              if (tag.id === 101) {
                return false
              }

              return (
                <div
                  className={classNames('MainPage_carousel_box_item', {
                    'active': active
                  })}
                  onClick={() => tagToggle({id: tag.id})}
                  {...others}
                >
                  <div
                    className="MainPage_carousel_box_item_icon"
                    style={{backgroundImage: `url(${image[i > 5 ? randomInteger(0,5) : i]})`}}
                  />
                  <div className="MainPage_carousel_box_item_text">
                    <span>
							    		{tag.title}
							    	</span>
                  </div>
                </div>
              );
            })}
          </ItemsCarousel>
        </div>
      </div>
    );
  };

  const pointsTreatment = (itemKey) => {
    const {average_check, disabled} = points[itemKey];
    if (!!disabled) return false;
    if (!isEnableFilterAverageCheck) return itemKey;
    if (average_check >= rangeSliderMin && average_check <= rangeSliderMax) {
      return itemKey;
    }
  }

  const renderContentPoints = () => {
    return Object.keys(points).filter(pointsTreatment).map((pointKey) => {
      const point = points[pointKey];
      return (
        <NewPointOnList
          id={point.id}
          opened={point.opened}
          onClick={() => {
            router.navigate(PAGE_ROUTES.POINT, {
              id: point.id
            });
          }}
          cover={point.hasOwnProperty('cover') ? point.cover['450'] : ''}
          logotype={point.hasOwnProperty('logotype') ? point.logotype['150'] : ''}
          name={point.title}
          shortDescription={point.short_description}
          averageLeadTimeOnMinutes={point.average_lead_time_on_minutes}
          averageLeadTimeOnHours={point.average_lead_time_on_hours}
          averageLeadTimeOnDay={point.average_lead_time_on_day}
          averageCheck={point.average_check}
          freeFrom={point.delivery_free_from}
          deliveryPrice={point.delivery_price}
          tags={point.tags_ids.map(({code_title}) => code_title)}
        />
      );
    });
  }

  const resultRenderContentPoints = renderContentPoints();

  const renderPoints = () => {
    return (
      <div style={{width: '100%'}}>
        {isEnableFilterAverageCheck && (
          <div
            onClick={clearFilters}
          >
            <div className="MainPage_delete">
              <Icon24DeleteOutline/>
              <span>
               Сбросить фильтры
              </span>
            </div>
          </div>
        )}
        {resultRenderContentPoints}
        {!resultRenderContentPoints.length && (
          <div className="MainPage_infoBox">
            <div className="MainPage_infoBox_content">
              <span>
                К сожалению по данным фильтрам ничего не найдено
              </span>
            </div>
          </div>
        )}
      </div>
    );
  };

  const selectFilters = () => {
    return (
      <div className="PointFilters">
        <div
          className="PointFilters_close"
          onClick={() => {
            setIsVisibleSelect(false)
          }}
        >
          <div className="PointFilters_close_icon"/>
        </div>
        <div className="PointFilters_content">
          <div>
            <Card verticalPadding={2} horizontalPadding={3}>
              <div className="PointFilters_content_heading">
                <span>Фильтры</span>
              </div>
              <div className="PointFilters_content_promotions">
                <Cell
                  selectable={true}
                  checked={isEnableFilterAverageCheck}
                  onChange={() => {
                    setIsEnableFilterAverageCheck(!isEnableFilterAverageCheck);
                  }}
                >
                   <span style={{marginLeft: 15}}>
                     Средний чек
                   </span>
                </Cell>
              </div>

              <div
                className="PointFilters_content_box"
                style={{
                  pointerEvents: isEnableFilterAverageCheck ? 'auto' : 'none',
                  opacity: isEnableFilterAverageCheck ? '1' : '0.5',
                }}
              >
                <Spacer size={6}/>
                  <div>
                      <Range
                        className="slider"
                        min={100}
                        max={3000}
                        step={100}
                        defaultValue={[rangeSliderMin, rangeSliderMax]}
                        onChange={(val) => {
                          const [minVal, maxVal] = val;
                          setRangeSliderMin(minVal);
                          setRangeSliderMax(maxVal);
                        }}
                      />
                  </div>

                <Spacer size={5}/>

                <div className="PointFilters_content_box_inputBox">
                  <div className="display_flex">
                    <input
                      placeholder="от"
                      value={rangeSliderMin}
                      onChange={(event) => {
                        setRangeSliderMin(+event.target.value)
                      }}
                    />
                  </div>
                  <div className="display_flex">
                    <input
                      placeholder="до"
                      value={rangeSliderMax}
                      onChange={(event) => {
                        setRangeSliderMax(+event.target.value)
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="PointFilters_content_box_buttonBox">
                <PrimaryButton
                  text={'Применить фильтры'}
                  mode={"primary"}
                  onClick={() => {
                    setIsVisibleSelect(false);
                  }}
                />
                <PrimaryButton
                  text={'Сбросить параметры'}
                  onClick={clearFilters}
                />
              </div>
            </Card>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div>
        <Spacer size={6}/>
          {!isEmptyObject(tags) && (
            <div>
              <Padding horizontal={3} vertical={2}>
                <Heading size={3} underline={false}>
                  Фильтры
                </Heading>
              </Padding>
              <Spacer size={3}/>
              {renderTabs()}
              <Spacer size={3}/>
            </div>
          )}
      </div>
      <div>
        <Padding horizontal={3} vertical={2}>
          <div className="display_flex w-100">
            <div>
              <Heading size={1} underline={1}>
                {activeSection.title}
              </Heading>
            </div>
            <div style={{marginRight: 0, marginLeft: 'auto'}}>
              <div
                className={classNames('MainPage__filterIcon', {
                  'MainPage__filterIcon_active': isEnableFilterAverageCheck
                })}
                onClick={() => {
                  setIsVisibleSelect(true);
                }}
              >
                <div className="MainPage__filterIcon_cursor">
                  {isEnableFilterAverageCheck && (
                    <div className="MainPage__filterIcon_cursor_dot"/>
                  )}
                </div>
                <Icon24Filter/>
              </div>
            </div>
          </div>
        </Padding>
        {renderPoints()}
      </div>

      {isVisibleSelect && (
        <div>
          {selectFilters()}
        </div>
      )}
    </div>
  )
};

export default MainPage;
