// external
import React, { useState } from 'react';
// internal
import Slider from '../../../components/ui/mobile/Slider';
import Cell from '../../../components/ui/mobile/Cell';
import Radio from '../../../components/ui/mobile/Radio';
import RangeSlider from '../../../components/ui/mobile/RangeSlider';
import Button from "../../../components/ui/universal/Button";
import Input from "../../../components/ui/universal/Input";
import Switch from "../../../components/ui/universal/Switch";
import Spacer from "../../../components/ui/universal/Spacer";
import Tumbler from "../../../components/ui/universal/Tumbler";
import Padding from "../../../components/ui/universal/Padding";
//

const UiPage = () => {
  const [state, setState] = useState({
    removeList: ['Михаил Андриевский', 'Вадим Дорохов', 'Саша Колобов'],
    draggingList: ['Say', 'Hello', 'To', 'My', 'Little', 'Friend'],
    value1: 24.4234,
    value2: 0.2,
    value3: 20,
    value4: [10, 20]
  });

  const [value, setValue] = useState(' ');
  const [switchOn, setSwitchOn] = useState(' ');
  const [tumblerOn, setTumblerOn] = useState(' ');

  return (
    <>
      <div>
        SWITCH

        <div>
          <Switch
            on={switchOn}
            onChange={() => setSwitchOn(!switchOn)}
          />
        </div>
      </div>
      <Spacer size={6}/>
      <div>
        Tumbler

        <div>
          <Padding horizontal={2} vertical={2}>
            <Tumbler
              on={tumblerOn}
              onChange={() => setTumblerOn(!tumblerOn)}
              firstTitle="Первый"
              secondTitle="Второй"
            />
          </Padding>
        </div>
      </div>
      <div>
        <Button>
          QQQQQ
        </Button>
        <Button mode="outline">
          QQQQQ
        </Button>
        <Button mode="negative_outline" size="hyper">
          QQQQQ
        </Button>
        <div>
          <div>
            {state.removeList.length > 0 && (
              <div>
                {state.removeList.map((item, index) => (
                  <Cell
                    key={item}
                    removable
                    onRemove={() => {
                      setState(
                        {
                          ...state,
                          removeList: [
                            ...state.removeList.slice(0, index),
                            ...state.removeList.slice(index + 1)
                          ]
                        }
                      );
                    }}
                  >
                    {item}
                  </Cell>
                ))}
              </div>
            )}
          </div>
          <div>
            <div>
              <Radio name="radio" value="1" description="Lorem ipsum dolor sit amet, consectetur." defaultChecked>First</Radio>
              <Radio name="radio" value="2">Second</Radio>
              <Radio name="radio" value="3" disabled>Third (disabled)</Radio>
            </div>
          </div>
          <div>
            <RangeSlider
              top="Uncontrolled"
              min={10}
              max={20}
              step={1}
              defaultValue={[12, 16]}
            />
            <Slider
              min={10}
              max={30}
              value={Number(state.value1)}
              onChange={(value1) => setState({
                ...state,
                value1
              })}
              top="Simple [10, 30]"
            />
            <Slider
              step={0.2}
              min={0}
              max={1}
              value={Number(state.value2)}
              onChange={(value2) => setState({
                ...state,
                value2
              })}
              top="Step [0, 1]"
            />
            <Slider
              onChange={(value3) => setState({
                ...state,
                value3
              })}
              defaultValue={state.value3}
              top="Uncontrolled"
              bottom={`${state.value3}`}
            />
          </div>
          <div>
            {state.draggingList.map((item) => (
              <Cell key={item} draggable onDragFinish={({ from, to }) => {
                const draggingList = [...state.draggingList];
                draggingList.splice(from, 1);
                draggingList.splice(to, 0, state.draggingList[from]);
                setState({
                  ...state,
                  draggingList
                });
              }}>
                {item}
              </Cell>
            ))}
          </div>
          <Cell>Суббота</Cell>
          <Cell>Воскресение</Cell>
          <Cell selectable>Воскресение</Cell>
          <Cell selectable>Воскресение</Cell>


          <Input
            title={'rew'}
            mode="primary"
            placeholder="Поиск"
            value={value}
            onChangeHandler={setValue}
          />
        </div>
        <div style={{
          backgroundColor: '#eee',
          minHeight: '150vh',
          margin: '16px',
          borderRadius: 8,
          backgroundImage: 'radial-gradient(rgb(199, 199, 199) 1px, rgba(0, 0, 0, 0) 1px), radial-gradient(rgb(239, 239, 239) 1px, rgba(0, 0, 0, 0) 1px)',
          backgroundPosition: '0 0, 15px 15px',
          backgroundSize: '30px 30px'
        }}>
          123
        </div>
      </div>
    </>
  );
};

export default UiPage;
