import './index.scss';

import React from 'react';
import {
  Formik,
  Form,
  ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import { isEmptyObject } from '../../../utils';
import { login } from '../../../services/auth';
import * as Modals from "../../../components/ui/universal/Modals";
import PasswordInput from '../../../components/ui/universal/PasswordInput';
import Card from '../../../components/ui/universal/Card';
import Spacer from '../../../components/ui/universal/Spacer';
import { ISignInContent } from './interface';
import PrimaryButton from "../../../components/ui/universal/PrimaryButton";
import ReactPhoneInput from 'react-phone-input-2';
const REQUIRED_FIELD_ERROR = 'Обязательное поле';

const SigninContent: React.FC<ISignInContent> = () => {
  const InitialFormikState = {
    login: '79190186963',
    password: '307525ab',
  };
  const handleOnChangePhone = (value: any, data: any) => {
    // console.log({
    //   phone: value,
    //   dialCode: data.dialCode,
    //   phoneWithoutCode: value.replace('+' + data.dialCode, '').replace(/[^\d;]/g, '')
    // });
  };
  const formikValidationSchema = (
    Yup.object().shape({
      login: Yup.string()
        .required(REQUIRED_FIELD_ERROR),

      password: Yup.string()
        .min(6, 'Пароль должен быть не менее 6 символов')
        .required(REQUIRED_FIELD_ERROR),
    })
  );

  const renderForm = () => {
    return (
      <div>
        <Formik
          initialValues={InitialFormikState}
          validationSchema={formikValidationSchema}
          onSubmit={(values: any, { setSubmitting, setErrors }) => {
            login(values).then((data: any) => {
              if (data.status) {
                window.location.href = '/';
              }
            }, (errors: any) => {
              errors.forEach(({ code }: any) => {
                switch (code) {
                  case 'PERMISSION_DENIED':
                    setErrors({
                      password: 'Неверный логин или пароль',
                    });

                    setSubmitting(false);
                    break;
                }
              });
            });
          }}
          render={({
            errors,
            touched,
            values,
            setFieldValue,
          }) => (
            <Form>
              <ReactPhoneInput
                autoFormat
                disableDropdown={true}
                disableSearchIcon
                countryCodeEditable={false}
                enableSearch={false}
                onlyCountries={['ru']}
                country="ru"
                placeholder="+7 915 123 45 67"
                preferredCountries={['ru']}
                disableCountryCode={false}
                value={values.login}
                isValid={() => !(errors.login && touched.login)}
                masks={{ ru: '(...) ...-..-..' }}
                onChange={(value, data) => {
                  setFieldValue('login', value);
                  handleOnChangePhone(value, data);
                }}
              />
              {errors.login && touched.login && (
                <div>
                  <ErrorMessage name="login" component="div" className="phone--invalid-feedback" />
                </div>
              )}

              <Spacer size={10} />

              <PasswordInput
                title="Ваш пароль"
                name="password"
                mode="secondary"
                placeholder="Ваш пароль"
                value={values.password}
                onChangeHandler={(e) => setFieldValue('password', e)}
                error={!!(errors.password && touched.password)}
                description={errors.password && touched.password && (
                  <ErrorMessage name="password" component="div" className="invalid-feedback" />
                )}
              />

              <Spacer size={5} />

              <div>
                <PrimaryButton
                  text="Войти"
                  mode="primary"
                  type="submit"
                />

                <Spacer size={4} direction="vertical" />

                {!isEmptyObject(touched) && [
                  <PrimaryButton
                    text="Сбросить"
                    mode="red"
                    type="reset"
                  />,
                  <Spacer size={4} direction="vertical" />
                ]}
              </div>
            </Form>
          )}
        />
      </div>
    );
  };

  const renderMainBlock = () => {
    return (
      <div className="SingIn">
        <Card verticalPadding={4} horizontalPadding={3}>
          <div className="relative">
            <Card transparent={true} horizontalPadding={2} shadow={false}>
              <div className="SingIn_header">
                <span>
                  Вход
                </span>
              </div>

              <Spacer size={5}/>
              <Spacer direction="vertical" size={4} />

              {renderForm()}

              <PrimaryButton
                text="Нет аккаунта?"
                type="submit"
                onClick={() => {
                  Modals.open('registration', {}, {}, {});
                }}
              />

              <Spacer direction="vertical" size={4} />

            </Card>
          </div>
        </Card>
      </div>
    );
  };

  return renderMainBlock();
};

export default SigninContent;
