import { useSelector } from 'react-redux';
import {isEmptyObject} from '../utils';

export const useGetPointInfo = () => {
  const {pointsById} = useSelector((store: any) =>({
    pointsById: store.common.pointsById
  }));

  const getPointInfoById = (id: number) => {
    let response = null;
    if (isEmptyObject(pointsById)) return response;
    if (!(id in pointsById)) return response;
    return pointsById[id];
  };

  return {
    getPointInfoById
  }
};

export default useGetPointInfo;
