//
import React from 'react';
//
import Button from '../../../components/ui/universal/Button';



class RegModal extends React.Component {
  state = {
    login: '',
    password: '',
    email: '',
    phone: '',
    dialCode: '',
    phoneWithoutCode: '',
  };

  __handleOnChangePhone = (value, data) => {
    this.setState({
      phone: value,
      dialCode: data.dialCode,
      phoneWithoutCode: value.replace('+' + data.dialCode, '').replace(/[^\d;]/g, '')
    });
  };

  __renderMainBlock = () => {
    return <>
      <div className="relative" style={{width: 375}}>
        <div className="Main__padding16"> </div>
        <div className="Main__padding_left_right_16">
          <div className="Box__header">
              <span>
                Join to Suhba social network
              </span>
          </div>
          <div className="Main__padding24"> </div>

          <div className="Main__padding16"> </div>
          <div className="Main__padding16"> </div>
          <div>
           
          </div>
          <div className="Main__padding16"> </div>

          <div className="Main__padding16"> </div>
          <Button
            style={{
              width: '100%'
            }}
            size="large"
            disabled={
              this.state.login.length < 1 ||
              this.state.password.length < 1
            }
            /**/>
            Register
          </Button>
        </div>
        <div className="Main__padding16"> </div>

      </div>
    </>
  };

  __mainRender = () => {
    return <>
      <div className="Main__padding24"> </div>
      <div className="Main__padding24"> </div>
      <div className="Main__padding_left_right_48">
        {this.__renderMainBlock()}
      </div>
      <div className="Main__padding24"> </div>
      <div className="Main__padding24"> </div>
    </>
  };

  render() {
    return this.__mainRender();
  }
}

export default RegModal;