// styles
// external
import React, { Component } from 'react';

class NotWrapper extends Component {
  render() {
    const {children} = this.props;

    return <div>
      {children}
    </div>
  }
}

export default NotWrapper;
