// @ts-nocheck in
import './index.scss';
// external
import React, { createRef } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
// internal
import { IPropsComponent } from './assets/interfaces';

const Textarea: React.FC<IPropsComponent> = (props) => {
  const ref: any = createRef<HTMLTextAreaElement>();

  const {
    title,
    maxRows,
    minRows,
    value,
    placeholder,
    onChangeHandler,
    description,
    inputRef,
    ...inputProps
  } = props;

  return (
    <div className="textarea--main">
      <label>
        <div className="textarea--wrapper">
          {title && (
            <div className="textarea--title no_select">
              {title}
            </div>
          )}
          <div>
            <TextareaAutosize
              {...inputProps}
              onChange={(text) => {
                if (onChangeHandler) {
                  onChangeHandler(text.target.value);
                }
              }}
              type="text"
              placeholder={placeholder}
              className="textarea--decor"
              maxRows={maxRows}
              minRows={minRows}
              defaultValue={value}
              onChangeHandler={onChangeHandler}
              inputRef={(tag: any) => {
                ref.current = tag;
              }}
            />
          </div>
          {description && (
            <div className="textarea--description no_select">
              <div className="hr" />
              {description}
            </div>
          )}
        </div>
      </label>
    </div>
  );
};

Textarea.defaultProps = {
  title: undefined,
  placeholder: 'placeholder',
  maxRows: 10,
  minRows: undefined,
  value: '',
  onChangeHandler: () => {}
};

export default Textarea;
