import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'delete_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="delete_outline_24"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.556 8.102a.9.9 0 01.842.954l-.5 8a.9.9 0 11-1.796-.112l.5-8a.9.9 0 01.954-.842zm-5.112 0a.9.9 0 00-.842.954l.5 8a.9.9 0 101.796-.112l-.5-8a.9.9 0 00-.954-.842z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M3 4.1a.9.9 0 100 1.8h1.18l1.155 12.316c.045.484.084.898.143 1.237.062.359.157.704.35 1.03a2.9 2.9 0 001.25 1.137c.342.162.695.225 1.057.253.344.027.76.027 1.246.027h5.238c.486 0 .902 0 1.246-.027.362-.028.715-.09 1.057-.253a2.9 2.9 0 001.249-1.137c.194-.326.289-.671.35-1.03.06-.34.099-.753.144-1.237L19.82 5.9H21a.9.9 0 000-1.8h-5.204a3.902 3.902 0 00-7.592 0H3zm4.124 13.915c.05.526.082.869.128 1.13.043.252.087.356.124.417a1.1 1.1 0 00.474.432c.064.03.172.065.426.085.265.02.61.021 1.138.021h5.172c.528 0 .873 0 1.138-.021.254-.02.362-.055.426-.085a1.1 1.1 0 00.474-.432c.037-.06.08-.166.124-.416.046-.262.079-.605.128-1.131L18.012 5.9H5.988l1.136 12.115zM12 2.9a2.1 2.1 0 00-1.898 1.2h3.796A2.1 2.1 0 0012 2.9z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24DeleteOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24DeleteOutline: FC<Icon24DeleteOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24DeleteOutline as any).mountIcon = mountIcon;

export default Icon24DeleteOutline;
