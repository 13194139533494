import * as storage from './storage';

const savedParams = ['ref', 'sc'];

const initGetParams = (location => {
  const params = {};
  const storageParams = {};
  const search = location.search.slice(1).split('&');

  if (search[0] !== "") {
    search.forEach((param) => {
      const [key, value] = param.split("=");
        params[key] = value;

      if (savedParams.includes(key)) {
        storage.lsSet(key, value);
      }
    });
  }

  savedParams.forEach(key => {
    const value = storage.lsGet(key);
    if (value) {
      storageParams[key] = value;
    }
  });

  return {
    params: {
      ...storageParams,
      ...params,
    },
    hash: location.hash.slice(1)
  };

})(window.location);

export default initGetParams;
