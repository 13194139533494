import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'filter_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="filter_24"><path fill-rule="evenodd" clip-rule="evenodd" d="M6 8.9c.08 0 .159-.01.233-.03a2.901 2.901 0 005.534 0c.075.02.153.03.233.03h8a.9.9 0 100-1.8h-8c-.08 0-.158.01-.233.03a2.901 2.901 0 00-5.534 0A.9.9 0 006 7.1H4a.9.9 0 100 1.8h2zm3-2a1.1 1.1 0 100 2.2 1.1 1.1 0 000-2.2zM3.1 16a.9.9 0 01.9-.9h8a.9.9 0 01.233.03 2.901 2.901 0 015.534 0A.902.902 0 0118 15.1h2a.9.9 0 110 1.8h-2a.902.902 0 01-.233-.03 2.901 2.901 0 01-5.534 0 .9.9 0 01-.233.03H4a.9.9 0 01-.9-.9zm10.8 0a1.1 1.1 0 112.2 0 1.1 1.1 0 01-2.2 0z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24FilterProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24Filter: FC<Icon24FilterProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24Filter as any).mountIcon = mountIcon;

export default Icon24Filter;
