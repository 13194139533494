import "./index.scss";
import React from "react";
import SimpleCheckbox from '../../ui/universal/SimpleCheckbox';

const SelectVariation = (props) => {
  const { description, name, price, checked, onChangeHandler } = props;
  return (
      <div
        className="SelectVariation_option"
        onClick={() => onChangeHandler(!checked)}
      >
        <div className="SelectVariation_option_item">
          <div className="SelectVariation_option_item_left">
            <span>
              {name}
            </span>
            <div className="SelectVariation_option_item_left_small">
              {description}
            </div>
          </div>
          <div className="SelectVariation_option_item_right">
            {price === 0 ? (
              <span/>
            ) : (
              <span>
              + {price} ₽
              </span>
            )}
          </div>
          <div className="SelectVariation_option_item_rightCounter">
            <SimpleCheckbox
              checked={checked}
              onClick={(e) => onChangeHandler(e)}
            />
          </div>
        </div>
      </div>
  );
};

export default SelectVariation;
