import "./index.scss";
import React from "react";
import * as auth from "../../../services/auth";
import * as api from "../../../services/api";
import cogoToast from "cogo-toast";
import Card from "../../../components/ui/universal/Card";
import Spacer from "../../../components/ui/universal/Spacer";
import Loading from "../../../components/ui/universal/Loading";
import PrimaryButton from "../../../components/ui/universal/PrimaryButton";

class PointAvatarUpload extends React.Component {
  constructor(props) {
    super(props);
    this.uploadPhotoForm = React.createRef();
    this.filedata = React.createRef();
  }

  state = {
    filedata: "",
    imagePreviewUrl: "",
    percentsOfComplete: 0,
  };

  _handleSubmit = (e) => {
    e.preventDefault();
    if (!this.state.filedata) return;

    let uploader = new XMLHttpRequest();
    let url = `${api.API_DOMAIN}/uploader`;

    uploader.open("POST", url, true);
    let token = auth.getToken();
    token = JSON.parse(token).access_token;
    uploader.setRequestHeader("Authorization", token);

    uploader.upload.onprogress = (event) => {
      const percent = Math.ceil((event.loaded / event.total) * 100);
      switch (percent) {
        case 100:
        case 1:
        case 10:
        case 30:
        case 70:
          this.setState({
            percentsOfComplete: percent,
          });
          break;
      }
    };

    uploader.onload = (e) => {
      try {
        let response = JSON.parse(e.currentTarget.response);
        this.props.onChangeHandler({
          imagePreviewUrl: this.state.imagePreviewUrl,
          hash: response.files[0].hash,
        });
        this.props.onClickClose();
      } catch (e) {
        cogoToast.error("Ошибка");
        this.props.onClickClose();
      }
    };

    uploader.onerror = () => {
      let response = JSON.parse(this.response);
      if (response.status === 200) {
        //
      } else {
        console.error("status" + response.status, "error upload");
      }
      this.props.onClickClose();
      cogoToast.error("Ошибка обновления");
    };

    uploader.send(new FormData(this.uploadPhotoForm.current));
  };

  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let filedata = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        filedata,
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(filedata);
  }

  render() {
    const { onClickClose } = this.props;

    let imagePreview = this.state.imagePreviewUrl ? (
      <div className="img" style={{ backgroundImage: `url(${this.state.imagePreviewUrl})` }}></div>
    ) : (
      ""
    );

    return (
      <div className="UploadPhoto">
        <div className="UploadPhoto_close" onClick={onClickClose}>
          <div className="UploadPhoto_close_icon" />
        </div>
        <div className="UploadPhoto_content">
          <Card horizontalPadding={3} verticalPadding={3}>
            <div className="UploadPhoto__padding_preview">
              <form ref={this.uploadPhotoForm} onSubmit={(e) => this._handleSubmit(e)}>
                <input
                  style={{ display: "none" }}
                  ref={this.filedata}
                  className="file"
                  type="file"
                  name="filedata"
                  onChange={(e) => this._handleImageChange(e)}
                />
                {this.state.percentsOfComplete < 1 ? (
                  <div className="UploadPhoto__imgPreview">{imagePreview}</div>
                ) : (
                  <div className="UploadPhoto__percents">
                    <Loading />
                  </div>
                )}
                <div className="UploadPhoto__button">
                  {!this.state.filedata ? (
                    // <div>
                    //   <div style={{width: 300}}>
                    //     Загружаемые изображения должны быть формата: <b>jpeg, png</b> и не должны быть размером более <b>20 МБ</b>.
                    //   </div>
                    //   <Spacer size={6} />
                    //   <Button
                    //     style={this.state.percentsOfComplete > 0 ? {display:'none'} : {}}
                    //     size="large"
                    //     fullWidth
                    //     onClick={() => this.filedata.current.click()}
                    //   >
                    //     Выбрать фото
                    //   </Button>
                    // </div>

                    <div>
                      <div className="header">
                        <span>
                          Загружаемые изображения должны быть формата: <b>jpeg, png</b> и не должны быть размером более{" "}
                          <b>20 МБ</b>.
                        </span>
                      </div>
                      <Spacer size={6} />
                      <PrimaryButton
                        text="Выбрать фото"
                        style={this.state.percentsOfComplete > 0 ? { display: "none" } : {}}
                        onClick={() => this.filedata.current.click()}
                      />
                    </div>
                  ) : (
                    this.state.percentsOfComplete > 0 || [
                      <div style={{ width: "100%" }}>
                        <Spacer size={6} />
                        {/*<Button*/}
                        {/*  mode="negative_outline"*/}
                        {/*  size="large"*/}
                        {/*  fullWidth={true}*/}
                        {/*  onClick={() => {*/}
                        {/*    this.setState({*/}
                        {/*      filedata: '',*/}
                        {/*      imagePreviewUrl: '',*/}
                        {/*      percentsOfComplete: 0*/}
                        {/*    })*/}
                        {/*  }}*/}
                        {/*>*/}
                        {/*  Отмена*/}
                        {/*</Button>*/}

                        <PrimaryButton
                          mode="red"
                          text="Отмена"
                          onClick={() => {
                            this.setState({
                              filedata: "",
                              imagePreviewUrl: "",
                              percentsOfComplete: 0,
                            });
                          }}
                        />
                      </div>,
                    ]
                  )}
                </div>
                {/*<div className="UploadAvatar__button">*/}
                {/*  {this.state.filedata ? [*/}
                {/*    <Spacer size={6}/>,*/}
                {/*    <Button*/}
                {/*      size="large"*/}
                {/*      fullWidth={true}*/}
                {/*      disabled={this.state.percentsOfComplete > 0}*/}
                {/*      state={this.state.percentsOfComplete > 0 && this.state.percentsOfComplete < 100 ? 'loading' : null}*/}
                {/*      onClick={(e) => this._handleSubmit(e)}*/}
                {/*    >*/}
                {/*      Загрузить*/}
                {/*    </Button>*/}
                {/*  ] : ''}*/}
                {/*</div>*/}

                <div>
                  {this.state.filedata
                    ? [
                        <Spacer size={6} />,
                        <PrimaryButton
                          text="Загрузить"
                          mode="primary"
                          state={
                            this.state.percentsOfComplete > 0 && this.state.percentsOfComplete < 100 ? "loading" : null
                          }
                          onClick={(e) => this._handleSubmit(e)}
                        />,
                        <div>{this.state.percentsOfComplete}</div>,
                      ]
                    : ""}
                </div>
              </form>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

export default PointAvatarUpload;
