import "./index.scss";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmptyObject } from "../../../utils";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Input from "../../../components/ui/universal/Input";
import Card from "../../../components/ui/universal/Card";
import Spacer from "../../../components/ui/universal/Spacer";
import "../../../static/styles/input-phone.scss";
import { ISignInContent } from "./interface";
import PrimaryButton from "../../../components/ui/universal/PrimaryButton";
import * as Modals from "../../../components/ui/universal/Modals";
import { ReactComponent as CloseSVG } from "../../../static/images/svg/new/close.svg";
import { ReactComponent as DeleteSVG } from "../../../static/images/svg/new/delete.svg";
import { ReactComponent as PlusSVG } from "../../../static/images/svg/new/newPlus.svg";
// @ts-ignore
import Textarea from "../../../components/ui/universal/Textarea";
import Switch from "../../../components/ui/universal/Switch";
import { commonActions } from "../../../store/actions/common-actions";
import cogoToast from "cogo-toast";
import ProductCategorySelection from "../../../components/common/ProductCategorySelection";
import Loading from "../../../components/ui/universal/Loading";
import { useGetMyPointInfo } from "../../../hooks";
import ImageUpload from "../../../components/common/UploadPhoto";
import { v4 as uuidv4 } from "uuid";

const REQUIRED_FIELD_ERROR: any = "Обязательное поле";

const RegistrationContent: React.FC<ISignInContent> = (props: any) => {
  const [editDisabled, setEditDisabled] = useState(false);
  const [isVisibleCategories, setIsVisibleCategories] = useState(false);
  const [openModalPhoto, setOpenModalPhoto] = useState(false);
  const [openModalCover, setOpenModalCover] = useState(false);
  const [activeGalleryKey, setActiveGalleryKey]: any = useState();
  const [photosFromSubjectById, setPhotosFromSubjectById] = useState({
    1: {
      hash: "",
      link: "",
    },
    2: {
      hash: "",
      link: "",
    },
    3: {
      hash: "",
      link: "",
    },
    4: {
      hash: "",
      link: "",
    },
    5: {
      hash: "",
      link: "",
    },
    6: {
      hash: "",
      link: "",
    },
    7: {
      hash: "",
      link: "",
    },
    8: {
      hash: "",
      link: "",
    },
    9: {
      hash: "",
      link: "",
    },
    10: {
      hash: "",
      link: "",
    },
  });
  const { pointId } = useGetMyPointInfo();
  const dispatch = useDispatch();

  const { activeCategory }: any = useSelector((state: any) => ({
    activeCategory: state.common.activePointCategoryId,
  }));

  const { categories, subjectById } = useSelector((store: any) => ({
    categories: store.common.categories,
    subjectsByCategory: store.common.subjectsByCategory,
    subjectById: store.common.subjectById,
  }));

  console.log(12, subjectById);

  const {
    id: subject_id,
    disabled,
    description,
    weight,
    price,
    average_lead_time_on_minutes,
    name,
    discount,
    photo1,
  }: any = subjectById;

  const isLoaded = isEmptyObject(subjectById) || subjectById.id !== props.sub_id;

  useEffect(() => {
    dispatch(
      commonActions.getSubjectsId({
        id: props.sub_id,
        extended: 1,
      }),
    );
  }, []);

  useEffect(() => {
    if (!isEmptyObject(subjectById)) {
      // @ts-ignore
      Object.keys(subjectById).forEach((itemKey) => {
        // @ts-ignore
        if (itemKey.indexOf("photo") > -1) {
          // @ts-ignore
          const num = parseInt(itemKey.match(/\d+/));
          // @ts-ignore
          setPhotosFromSubjectById((photosFromSubjectById) => ({
            ...photosFromSubjectById,
            [num]: {
              // @ts-ignore
              link: subjectById[itemKey][150],
              hash: subjectById[itemKey].hash === undefined ? "" : subjectById[itemKey].hash,
            },
          }));
        }
      });
    }
  }, []);

  if (isLoaded) {
    return <Loading />;
  }

  const category = categories[pointId][activeCategory];
  const initialFormikState = {
    description,
    weight,
    price,
    average_lead_time_on_minutes,
    name,
    discount,
  };

  const formikValidationSchema = Yup.object().shape({
    name: Yup.string().required(REQUIRED_FIELD_ERROR),

    price: Yup.number().required(REQUIRED_FIELD_ERROR),

    average_lead_time_on_minutes: Yup.number().required(REQUIRED_FIELD_ERROR),

    weight: Yup.number().required(REQUIRED_FIELD_ERROR),

    discount: Yup.number().required(REQUIRED_FIELD_ERROR),

    description: Yup.string().min(6, "Не менее 6 символов").required(REQUIRED_FIELD_ERROR),
  });

  const renderForm = () => {
    return (
      <div>
        <Formik
          initialValues={initialFormikState}
          validationSchema={formikValidationSchema}
          onSubmit={(values: any, { setSubmitting, setErrors }) => {
            const _values = values;
            _values.category_id = activeCategory;
            _values.disabled = +editDisabled;
            _values.subject_id = subject_id;

            Object.keys(photosFromSubjectById).map((item) => {
              // @ts-ignore
              const valuesGallery = photosFromSubjectById[item].hash;
              if (!valuesGallery.length) {
                delete _values[`photo${item}`];
              } else {
                _values[`photo${item}`] = valuesGallery;
              }
            });
            const editSubject: any = dispatch(commonActions.editSubject(_values));

            editSubject.then(
              (data: any) => {
                if (!openModalPhoto && !openModalCover) {
                  setSubmitting(true);
                  cogoToast.success("Сохранено!");
                  Modals.close();
                }
              },
              ({ errors }: any) => {
                errors.forEach(({ code }: any) => {});

                setSubmitting(false);
                cogoToast.error("Ошибка!");
              },
            );
          }}
          render={({ errors, touched, values, setFieldValue }) => (
            <Form>
              <Spacer size={5} />

              <Input
                name="name"
                mode="secondary"
                title="Название товара"
                placeholder="Введите название товара"
                value={values.name}
                onChangeHandler={(e) => setFieldValue("name", e)}
                error={!!(errors.name && touched.name)}
                description={
                  errors.name &&
                  touched.name && <ErrorMessage name="name" component="div" className="invalid-feedback" />
                }
              />

              <Spacer size={10} />

              <div className="EditSubject_button" onClick={() => setIsVisibleCategories(!isVisibleCategories)}>
                <span>Выбор категории:</span>
                <div className="EditSubject_button_category">{category.title}</div>
              </div>

              {isVisibleCategories && <ProductCategorySelection onClickClose={() => setIsVisibleCategories(false)} />}

              <Spacer size={5} />

              <div className="EditSubject_coverBox">
                <div className="EditSubject_coverBox_name">
                  <span>Обложка</span>
                </div>

                {openModalCover && (
                  <div>
                    <ImageUpload
                      onChangeHandler={({ imagePreviewUrl, hash }: any) => {
                        setPhotosFromSubjectById((photosFromSubjectById) => ({
                          ...photosFromSubjectById,
                          [1]: {
                            link: imagePreviewUrl,
                            hash: hash,
                          },
                        }));
                      }}
                      onClickClose={() => {
                        setOpenModalCover(!openModalCover);
                      }}
                    />
                  </div>
                )}

                <div
                  className="EditSubject_coverBox_box"
                  style={{
                    backgroundImage: !photosFromSubjectById[1].link.length
                      ? `url(${photo1})` && ""
                      : `url(${photosFromSubjectById[1].link})`,
                  }}
                  onClick={() => {
                    setOpenModalCover(!openModalCover);
                  }}>
                  {photo1.isDefault && !photosFromSubjectById[1].link.length && <PlusSVG />}
                </div>
                <div className="EditSubject_coverBox_download">
                  <span>Загрузить обложку</span>
                </div>
              </div>

              <Spacer size={15} />

              <div className="EditSubject_coverBox_name">
                <span>Галерея</span>
              </div>
              <div className="EditSubject_gallery">
                {Object.keys(photosFromSubjectById).map((itemKey) => {
                  // @ts-ignore
                  const itemValue = photosFromSubjectById[itemKey].link;
                  // @ts-ignore
                  const itemValueHash = photosFromSubjectById[itemKey].hash;
                  return (
                    <div className="EditSubject_gallery_box">
                      <div
                        className="EditSubject_gallery_box_item"
                        key={uuidv4()}
                        style={{ backgroundImage: `url(${itemValue})` }}
                        onClick={() => {
                          setOpenModalPhoto(!openModalPhoto);
                          setActiveGalleryKey(itemKey);
                        }}>
                        {!itemValueHash.length && (
                          <div className="EditSubject_gallery_box_item_box">
                            <PlusSVG />
                          </div>
                        )}
                      </div>
                      {!!itemValueHash.length && (
                        <div
                          className="EditSubject_gallery_box_delete"
                          key={uuidv4()}
                          onClick={() => {
                            setPhotosFromSubjectById((photosFromSubjectById) => ({
                              ...photosFromSubjectById,
                              [itemKey]: {
                                link: "",
                                hash: "",
                              },
                            }));
                          }}>
                          <span>Очистить</span>
                          <DeleteSVG />
                        </div>
                      )}

                      {openModalPhoto && (
                        <div>
                          <ImageUpload
                            onChangeHandler={({ imagePreviewUrl, hash }: any) => {
                              setPhotosFromSubjectById((photosFromSubjectById) => ({
                                ...photosFromSubjectById,
                                [activeGalleryKey]: {
                                  link: imagePreviewUrl,
                                  hash: hash,
                                },
                              }));
                              setActiveGalleryKey();
                            }}
                            onClickClose={() => {
                              setOpenModalPhoto(!openModalPhoto);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>

              <Spacer size={15} />

              <div>
                <Textarea
                  title="Описание товара"
                  maxRows={5}
                  minRows={5}
                  placeholder="Введите название"
                  name="description"
                  value={values.description}
                  onChangeHandler={(e) => {
                    setFieldValue("description", e);
                  }}
                  // @ts-ignore
                  error={!!(errors.description && touched.description)}
                  description={
                    errors.description &&
                    touched.description && (
                      <ErrorMessage name="description" component="div" className="invalid-feedback" />
                    )
                  }
                />
              </div>

              <Spacer size={15} />

              <Input
                name="price"
                mode="secondary"
                title="Цена"
                placeholder="Цена в рублях"
                value={values.price}
                onChangeHandler={(e) => setFieldValue("price", e)}
                error={!!(errors.price && touched.price)}
                description={
                  errors.price &&
                  touched.price && <ErrorMessage name="price" component="div" className="invalid-feedback" />
                }
              />

              <Spacer size={15} />

              <Input
                name="weight"
                mode="secondary"
                title="Вес"
                placeholder="Введите вес в граммах"
                value={values.weight}
                onChangeHandler={(e) => setFieldValue("weight", e)}
                error={!!(errors.weight && touched.weight)}
                description={
                  errors.weight &&
                  touched.weight && <ErrorMessage name="weight" component="div" className="invalid-feedback" />
                }
              />

              <Spacer size={15} />

              <Input
                name="discount"
                mode="secondary"
                title="Скидки"
                placeholder="Введите процент скидки"
                value={values.discount}
                onChangeHandler={(e) => setFieldValue("discount", e)}
                error={!!(errors.discount && touched.discount)}
                description={
                  errors.discount &&
                  touched.discount && <ErrorMessage name="discount" component="div" className="invalid-feedback" />
                }
              />

              <Spacer size={15} />

              <Input
                name="average_lead_time_on_minutes"
                mode="secondary"
                title="Время изготовления"
                placeholder="Введите время изготовления"
                value={values.average_lead_time_on_minutes}
                onChangeHandler={(e) => setFieldValue("average_lead_time_on_minutes", e)}
                error={!!(errors.average_lead_time_on_minutes && touched.average_lead_time_on_minutes)}
                description={
                  errors.average_lead_time_on_minutes &&
                  touched.average_lead_time_on_minutes && (
                    <ErrorMessage name="average_lead_time_on_minutes" component="div" className="invalid-feedback" />
                  )
                }
              />

              <Spacer size={10} />

              <div className="EditSubject_switch">
                <span>Отображать товар</span>
                <div className="EditSubject_switch_box">
                  <Switch on={editDisabled} onChange={() => setEditDisabled(!editDisabled)} />
                </div>
              </div>
              <div className="MyAddressPage_buttonBox">
                <PrimaryButton text="Сохранить" mode={"primary"} btnType={"submit"} />
              </div>
            </Form>
          )}
        />
      </div>
    );
  };

  const renderMainBlock = () => {
    return (
      <div className="EditSubjectHeader">
        <Card verticalPadding={1} horizontalPadding={1}>
          <div className="relative">
            <Card transparent={true} horizontalPadding={2} shadow={false}>
              <div className="EditSubjectHeader_header">
                <div className="EditSubjectHeader_header_left" />
                <div className="EditSubjectHeader_header_center">
                  <span>Редактирование</span>
                </div>
                <div className="EditSubjectHeader_header_right" />
              </div>

              <Spacer size={10} />
              <Spacer direction="vertical" size={4} />

              {renderForm()}

              <Spacer direction="vertical" size={4} />
            </Card>
          </div>
        </Card>
      </div>
    );
  };

  return renderMainBlock();
};

export default RegistrationContent;
