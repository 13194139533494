import React from 'react';
import Button from '../../../components/ui/universal/Button';
//

function ViewTextModal(props) {
  function nextModal() {
    props.open('document', {docid: 56}, {docid: 5, b: 3}, {
      onCloseHandler: (node) => {
        console.profile('# document modal onclose handler ok!!!')
      }
    });
  }

  return <>
    <div>
      модалка просмотра текста
    </div>
    <div>
      props: {JSON.stringify(props)}
    </div>
    <div>
      <Button
        size={'small'}
        className="options"
        type="secondary"
        onClick={() => nextModal()}
        /**/>
        Next modal document
      </Button>
    </div>
  </>
}

export default ViewTextModal;
