import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { reduxPlugin } from 'redux-router5';
import { router5Middleware } from 'redux-router5';
import router from '../router';
import reducers from './reducers';

let store;

export function getStore() {
  store = createStore(
    reducers,
    applyMiddleware(
      thunk,
      router5Middleware(router)
    )
  );

  router.usePlugin(
    reduxPlugin(store.dispatch)
  );
}

getStore();

export default store;
