import './index.scss';
//
import React from 'react';
import classNames from 'classnames';
//
import { IPropsComponent } from './assets/interfaces';

const NewHeaderTitle: React.FC<IPropsComponent> = (props) => {
  const {
    text, after, before, onClick, align
  } = props;

  return (
    <div
      onClick={onClick}
      className={classNames({
        'Newheader--title': true,
        [`Newheader--title_align_${align}`]: true
      })}
    >
      <div className={`Newheader--title_align_content_${align}`}>
        {!!before && (
          <div className="Newheader--title_before">
            {before}
          </div>
        )}
        <div
          className={classNames({
            'Newheader--title_text': true,
            [`Newheader--title_text_${align}`]: true
          })}
        >
        <span>
          {text}
        </span>
        </div>
        {!!after && (
          <div className="Newheader--title_after">
            {after}
          </div>
        )}
      </div>
    </div>
  );
};

NewHeaderTitle.defaultProps = {
  before: undefined,
  after: undefined,
  align: 'center',
  onClick: () => {}
};

export default React.memo(NewHeaderTitle);
