import './index.scss';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { isEmptyObject } from '../../../utils';
import {
  Formik,
  Form,
  ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import Input from '../../../components/ui/universal/Input';
import Card from '../../../components/ui/universal/Card';
import Spacer from '../../../components/ui/universal/Spacer';
import '../../../static/styles/input-phone.scss';
import {ISignInContent} from './interface';
import PrimaryButton from "../../../components/ui/universal/PrimaryButton";
import * as Modals from "../../../components/ui/universal/Modals";
// @ts-ignore
import Switch from '../../../components/ui/universal/Switch';
import {commonActions} from "../../../store/actions/common-actions";
import cogoToast from "cogo-toast";
import Loading from "../../../components/ui/universal/Loading";
import {useGetMyPointInfo} from "../../../hooks";

const REQUIRED_FIELD_ERROR: any = 'Обязательное поле';

const RegistrationContent: React.FC<ISignInContent> = (props: any) => {
  const [editDisabled, setEditDisabled] = useState(false);
  const [isLoadingQueryUpdate, setIsLoadingQueryUpdate] = useState(false);
  const {pointId} = useGetMyPointInfo();
  const dispatch = useDispatch();

  const { activeCategory }: any = useSelector((state: any) => ({
      activeCategory: state.common.activePointCategoryId
  }));

  const {categories} = useSelector((store:any) => ({
    categories: store.common.categories,
    pointsById: store.common.pointsById,
    subjectById: store.common.subjectById as any
  }));

  const isLoadedCategories = isEmptyObject(props.categoryId)

  const setActiveCategory = (val:any) => {
        dispatch(commonActions.setActivePointCategoryId(val));
    };

  useEffect(() => {
    dispatch(commonActions.getSubjectsId({
      id: props.categoryId,
    }));

  }, []);


  if (isLoadedCategories) {
    return (
      <Loading/>
    );
  }

  const category = categories[pointId][props.categoryId]
  const initialFormikState = {
    title: category.title,
    priority: category.priority
  };
  const formikValidationSchema = (
    Yup.object().shape({
      title: Yup.string().required(REQUIRED_FIELD_ERROR),

      priority: Yup.number().required(REQUIRED_FIELD_ERROR)
    })
  );

  const renderForm = () => {
    return (
      <div>
        <Formik
          initialValues={initialFormikState}
          validationSchema={formikValidationSchema}
          onSubmit={(values: any, {setSubmitting, setErrors}) => {
            values.id = props.categoryId;
            values.disabled = +editDisabled;
            values.point_id = pointId
            const editCategory: any = dispatch(commonActions.editCategory(values));
            editCategory.then((data: any) => {
              setSubmitting(true);
              cogoToast.success('Сохранено!');
              setIsLoadingQueryUpdate(false);
              Modals.close()
            }, ({errors}: any) => {
              errors.forEach(({code}: any) => {
              });

              setSubmitting(false);
              cogoToast.error('Ошибка!');
            });
          }}
          render={({
                     errors,
                     touched,
                     values,
                     setFieldValue
                   }) => (
            <Form>
              <Spacer size={5}/>

              <Input
                name="title"
                mode="secondary"
                title="Название категории"
                placeholder="Введите название категории"
                value={values.title}
                onChangeHandler={(e) => setFieldValue('title', e)}
                error={!!(errors.title && touched.title)}
                description={errors.title && touched.title && (
                  <ErrorMessage name="title" component="div" className="invalid-feedback"/>
                )}
              />

              <Spacer size={10}/>

              <Input
                name="priority"
                mode="secondary"
                title="Позиция в списке"
                placeholder="Введите название товара"
                value={values.priority}
                onChangeHandler={(e) => setFieldValue('priority', e)}
                error={!!(errors.priority && touched.priority)}
                description={errors.priority && touched.priority && (
                  <ErrorMessage name="priority" component="div" className="invalid-feedback"/>
                )}
              />

              <Spacer size={10}/>

              <div className="EditCategory_switch">
                <span>
                  Отображать товар
                </span>
                <div className="EditCategory_switch_box">
                  <Switch
                    on={editDisabled}
                    onChange={() => setEditDisabled(!editDisabled)}
                  />
                </div>
              </div>

              <div className="MyAddressPage_buttonBox">
                <PrimaryButton
                  text="Добавить"
                  mode={"primary"}
                  btnType={"submit"}
                />
              </div>
            </Form>
          )}
        />
      </div>
    );
  };

  const renderMainBlock = () => {
    return (
      <div className="EditCategoryHeader">
        <Card verticalPadding={3} horizontalPadding={3}>
          <div className="relative">
            <Card transparent={true} horizontalPadding={2} shadow={false}>
              <div className="EditCategoryHeader_header">
                <div className="EditCategoryHeader_header_left"/>
                <div className="EditCategoryHeader_header_center">
                  <span>
                    Редактирование
                  </span>
                </div>
                <div className="EditCategoryHeader_header_right"/>
              </div>

              <Spacer size={10}/>
              <Spacer direction="vertical" size={4}/>

              {renderForm()}

              <Spacer direction="vertical" size={4}/>

            </Card>
          </div>
        </Card>
      </div>
    );
  };

  return renderMainBlock();
};

export default RegistrationContent;
