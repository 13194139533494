import React, { Component } from 'react';
import router5 from '../../router';

class BaseContainer extends Component {

  __getSection = () => {
    const section = this.__getRoute().params.section;
    return section !== undefined ? section.toLowerCase() : undefined;
  };

  __getId = () => {
    const id = this.__getRoute().params.id;
    return id !== undefined ? parseInt(id) : undefined;
  };

  __getRoute = () => {
    return router5.getState();
  };

  __sectionNavigate = (section = undefined) => {
    let params = {...this.__getRoute().params};
    params.section = section;
    router5.navigate(this.__getRoute().name, params);
  };

  render() {
    return <>
      BaseContainer: render();
    </>
  }

}

export default BaseContainer;
