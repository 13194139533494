import './index.scss'
import React, {useEffect, useState} from 'react';
import { Icon28HomeOutline, Icon28Profile, Icon28BillheadOutline, Icon28Menu } from '@vkontakte/icons';
import router5 from "../../router";
import {PAGE_ROUTES} from "../../definitions";
import router from "../../router";
import classNames from "../../utils/classNames";
import * as Modals from "../ui/universal/Modals";
import {isLogged as _isLogged} from "../../services";

const Footer = (props) => {
  const { name: routeName } = router.getState();
  const [headerScrollPoint, setHeaderScrollPoint] = useState(false);
  const handleScroll = () => {
    setHeaderScrollPoint(window.pageYOffset > 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  const isLogged = _isLogged();

  return (
    <div className="Footer_fake">
      <div className="Footer_main">
        <div  className={classNames('Footer_main_variant', {
          'border': headerScrollPoint
        })}>

          <div className="Footer_main_variant_left">
            <div
              className={classNames('Footer_main_variant_left_centred', {
                'active': routeName === PAGE_ROUTES.MAIN
              })}
              onClick={() => {
                router5.navigate(PAGE_ROUTES.MAIN);
              }}
            >
              <Icon28HomeOutline />
                <span>
                  Главная
                </span>
            </div>
          </div>
          {isLogged ? (
            <div className="Footer_main_variant_center">
              <div
                className={classNames('Footer_main_variant_center_centred', {
                  'active': routeName === PAGE_ROUTES.MYORDERS
                })}
                onClick={() => {
                  router.navigate(PAGE_ROUTES.MYORDERS)
                }}
              >
                <Icon28BillheadOutline />
                <span>
                  Заказы
                </span>
              </div>
            </div>
          ) : (
            <div/>
          )}
          <div className="Footer_main_variant_right">
            <div
              className="Footer_main_variant_right_centred"
              onClick={() => {
                isLogged ? (
                    router5.navigate(PAGE_ROUTES.MENU)
                  ) : Modals.open('auth', {}, {}, {});
              }}
            >
              {isLogged ? (
                <div
                  className={classNames('Footer_main_variant_right_centred', {
                    'active': routeName === PAGE_ROUTES.MENU
                  })}
                >
                  <Icon28Menu />
                  <span>
                    Меню
                  </span>
                </div>
              ) : (
                <div className="Footer_main_variant_right_centred">
                  <Icon28Profile />
                  <span>
                    Вход
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
