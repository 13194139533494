import './index.scss';
import React from "react";
import classNames from "classnames";

const PrimaryButton = (props) => {
  const {className, style , disabled, text, state, mode, onClick, btnType, price } = props;
  const cn = classNames({
    [className]: !!className,
    disabled: disabled || state === 'disabled',
    [mode]: !!mode,
    [state]: !!state,

  });

  return (
    <div className="PrimaryButton">
      <button
        style={style}
        className={cn}
        onClick={onClick}
        type={btnType}
      >
        {text && (
         <span>
          {text}
         </span>
        )}

          {price && (
           <span>
             { price + '₽'}
           </span>
          )}

      </button>
    </div>
  );
};

export default PrimaryButton;
