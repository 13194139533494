import "./index.scss";
import React, {useState} from "react";
import Padding from "../../../components/ui/universal/Padding";
import Input from "../../../components/ui/universal/Input";
import Spacer from "../../../components/ui/universal/Spacer";
import PrimaryButton from "../../../components/ui/universal/PrimaryButton";

const CodePage = () => {
  const [value, setValue] = useState('');

  return (
    <div>
      <Spacer size={10}/>
      <Padding vertical={3} horizontal={2}>
        <Input
          title={"Введите код"}
          mode="secondary"
          value={value}
          onChangeHandler={setValue}
        />
        <Spacer size={15}/>
        <div>
          <PrimaryButton
            mode="primary"
            text="Активировать"
          />
        </div>
      </Padding>
    </div>
  );
};

export default CodePage;