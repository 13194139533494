//
import React from 'react';
import BaseContainer from '../BaseContainer';

class Index extends BaseContainer {
  render() {
    return (
      <div>
        404
      </div>
    );
  };
}

export default Index;
