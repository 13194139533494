import './index.scss';
import React from 'react';
import Padding from "../../ui/universal/Padding";
import Heading from "../../ui/universal/Heading";
import BriefItem from "../BriefItem";
import Spacer from "../../ui/universal/Spacer";
import PointOnList from "../PointOnList";
import {declOfNum} from "../../../utils/declOfNum";


const NewPointOnList = (props) => {
  const getAverageTime = () => {
    if (props.averageLeadTimeOnDay > 0) {
      return declOfNum(props.averageLeadTimeOnDay, [ 'день', 'дня', 'дней' ]);
    } else if (props.averageLeadTimeOnHours > 0) {
      return declOfNum(props.averageLeadTimeOnHours, [ 'час', 'часа', 'часов' ]);
    } else {
      return declOfNum(props.averageLeadTimeOnMinutes, [ 'минута', 'минуты', 'минут' ]);
    }
  };

  return(
  <Padding horizontal={3} vertical={3}>
    <Heading size={2} underline={false}>
      <div
        className="NewPointOnList_box"
        key={`PointOnList_${props.id}`}
        onClick={props.onClick}
      >
        <div
          className="NewPointOnList_box_card"
          style={{backgroundImage:`url(${props.cover})`}}
        >
        </div>
        <div className="NewPointOnList_box_info">
          <div className="NewPointOnList_box_info_productName">
            <span>
              {props.name}
            </span>
          </div>
          <div className="NewPointOnList_shortDescription">
            <span>
              {props.shortDescription}
            </span>
          </div>
          <div className="NewPointOnList_box_info_brief">
            <BriefItem
              text={'Доставка ~ ' + getAverageTime()}
            />
            <BriefItem
              text={`~${props.averageCheck} ₽`}
            />

            {props.opened && (
              <BriefItem
                mode="green"
                text="Открыто"
              />
            )}

            {!props.opened && (
              <BriefItem
                mode="red"
                text="Закрыто"
              />
            )}

          </div>
        </div>
        <Spacer size={3} />
      </div>
    </Heading>
  </Padding>
  );
};

PointOnList.defaultProps = {
  id: 0,
  onClick: () => {},
  cover: '',
  logotype: '',
  name: '',
  shortDescription: '',
  averageLeadTimeOnMinutes: 0,
  averageLeadTimeOnHours: 0,
  averageLeadTimeOnDay: 0,
  averageCheck: 0,
  freeFrom: 0,
  deliveryPrice: 0,
  tags: []
};

export default NewPointOnList