import './index.scss';
import classNames from "../../../utils/classNames";
import React from 'react';

const BriefItem = (props) => {
  const { bold, mode, text, status_id, onClick, style} = props;
  const status = {
    0: "Новый",
    1: "Согласуется",
    2: "Собирается",
    3: "Доставляется",
    4: "Выполнен",
    5: "Отменен",
    6: "Возвращен",
  }
  const statusMode = {
    0: "active",
    1: "primary",
    2: "primary",
    3: "primary",
    4: "green",
    5: "red",
    6: "red",
  }

  return(
    <div
      style={style}
      onClick={onClick}
      className={classNames('BriefItem', {
        'BriefItem_bold': !!bold,
        [`BriefItem_${mode}`]: !!mode,
        [`BriefItem_${statusMode[mode]}`]: mode

      })}
    >
      <span>
        {text || status[status_id]}
      </span>
    </div>
  )
}


export default BriefItem