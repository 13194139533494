import './index.scss';
// external
import React from 'react';
import classNames from 'classnames';
// internal
import { IPropsComponent } from './assets/interfaces';

const Card: React.FC<IPropsComponent> = (props) => {
  const {
    rounded, shadow, transparent, horizontalPadding, verticalPadding, children, overflow
  } = props;

  return (
    <>
      <div className={classNames('box', {
        'box--rounded': rounded,
        'box--transparent': transparent,
        'box--overflow': overflow,
        [`box--padding_horizontal_${horizontalPadding}`]: horizontalPadding,
        [`box--padding_vertical_${verticalPadding}`]: verticalPadding,
      })}
      >
        {children}
      </div>
    </>
  );
};

Card.defaultProps = {
  horizontalPadding: undefined,
  verticalPadding: undefined,
  rounded: true,
  shadow: true,
  transparent: false,
  overflow: false
};

export default React.memo(Card);
