const callbacks = {
  scope: new Map(),
  getKey: (prefix = '') => {
    const chars = [..."ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"];
    return prefix + [...Array(16)].map(i => chars[Math.random() * chars.length|0]).join``;
  },
  add: (prefix = '', func) => {
    const key = callbacks.getKey(prefix);
    callbacks.scope.set(key, func);
    return key;
  },
  get: (key) => {
    return callbacks.scope.get(key);
  },
  remove: (key) => {
    return callbacks.scope.delete(key);
  },
  clear: () => {
    callbacks.scope.clear();
  }
};

export default callbacks;