import Cookies from 'js-cookie';
import * as api from './api';
import {API_ENTRY} from './api';

export const TOKEN_KEY = 'access_token';
export const TOKEN_VALID_TIME = 8888888;

export function logout() {
  Cookies.remove(TOKEN_KEY);
  window.location.href = '/';
}

export const client_secret = 'main_client_secret',
  type = 'remote_login',
  client_id = 1;

export const login = ({ login, password }) => {
  return new Promise((resolve, reject) => {
    api.post('auth.get', {
      login: login,
      password,
      client_secret,
      client_id,
      type,
    }).then((response) => {
      api.apiResponseTreatment(response).then((data) => {
        if ('response' in data) {
          Cookies.set(
            TOKEN_KEY,
            JSON.stringify(data.response), { path: '/' }
          );

          resolve({
            status: true,
            data: data.response
          });
        }
      }, reject);
    }).catch((data) => {
      reject(data);
    });
  });
};

export function getToken() {
  return Cookies.get(TOKEN_KEY);
}

export const isLogged = () => {
  let token = getToken();
  if (token !== undefined) {
    token = JSON.parse(token);
    checkToken(token).then((res) => {}, (res) => {
      refreshToken(token).then((freshData) => {
        //
      }, () => {
        logout();
      }).catch(data => {
        console.error(data);
      });
    }).catch(data => {
      console.error(data);
    });
    return true;
  } else {
    return false;
  }
};

export const tokenLocalRelevance = ({ valid_until_time }) => {
  //const x = +((Math.round((+new Date()) / 1000)) + ((TOKEN_VALID_TIME / 100) * 90)).toFixed(0);
  return +new Date() < ((valid_until_time - 6000) * 1000); // я сделал -6000 было -86000 заебало вылетать
};

export const checkToken = (token) => {
  const { access_token } = token;
  return new Promise((resolve, reject) => {
    if (!tokenLocalRelevance(token)) {
      reject({
        status: false,
      });

      const asyncCheckRelevance = async () => {
        if (window.relevanceIsLoading) return;

        window.relevanceIsLoading = true;

        try {
          const fetchResponse = await fetch(API_ENTRY + `/auth.relevance?access_token=` + access_token, {
            method: 'GET',
          });

          const response = await fetchResponse.json();
          window.relevanceIsLoading = false;

          api.apiResponseTreatment(response).then((data) => {
            if (data.hasOwnProperty('status')) {
              if (!data.status) {
                return reject({
                  status: false
                });
              } else {
                if ('response' in data) {
                  return resolve({
                    status: true,
                    data: data.response
                  });
                }
              }
            }

            resolve({
              status: true
            });
          }, () => {
            reject({
              status: false
            })
          });
        } catch (e) {
          return e;
        }
      }
      asyncCheckRelevance();
    } else {
      resolve({
        status: true
      })
    }
  });
};

export const removeAllTokens = async (access_token) => {
  return new Promise((resolve, reject) => {
    api.post('auth.removeAll', {
      access_token,
      exclusion: 1
    }).then((response) => {
      api.apiResponseTreatment(response).then((data) => {
        resolve({
          status: true
        });
      }, () => {
        reject({
          status: false
        })
      });
    }).catch((data) => {
      reject(data);
    });
  });
};

export const removeTokenById = async (id) => {
  return new Promise((resolve, reject) => {
    api.post('account.removeTokenById', {
      id,
    }).then((response) => {
      api.apiResponseTreatment(response).then((data) => {
        resolve({
          status: true
        });
      }, () => {
        reject({
          status: false
        })
      });
    }).catch((data) => {
      reject(data);
    });
  });
};

export const refreshToken = async (token) => {
  const { refresh_token, access_token, owner_id } = token;
  return new Promise((resolve, reject) => {
    api.post('auth.refresh', {
      access_token,
      refresh_token
    }).then((response) => {
      api.apiResponseTreatment(response).then((data) => {
        if ('status' in data) {
          if (!data.status) {
            logout();
          } else {
            const freshData = {
              ...data.response,
              owner_id
            };

            Cookies.set(
              TOKEN_KEY,
              JSON.stringify(freshData), { path: '/' }
            );

            resolve({
              status: true,
              data: freshData
            });
          }
        }
      }, () => {
        logout();
        reject({
          status: false
        })
      });
    }).catch((data) => {
      reject(data);
    });
  });
};
