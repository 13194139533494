export const LOADING_STATUSES = {
  PERSONAL_INFO_LOADING_KEY: 'PERSONAL_INFO_LOADING_KEY',
  LOAD_DEVICES_LOADING_KEY: 'LOAD_DEVICES_LOADING_KEY',
  PERSONAL_UPDATE_LOADING_KEY: 'PERSONAL_UPDATE_LOADING_KEY',
  INTERVIEW_UPDATE_LOADING_KEY: 'INTERVIEW_UPDATE_LOADING_KEY',
  PASSWORD_UPDATE_LOADING_KEY: 'PASSWORD_UPDATE_LOADING_KEY',
  SUBJECTS_LOADING_KEY: 'SUBJECTS_LOADING_KEY'
};

export const LOADING_STATUS_IN_PROCESS = 'LOADING';
export const LOADING_STATUS_OK = '';
export const LOADING_STATUS_FAIL = 'FAIL';
