import "./index.scss"
import React, {useEffect, useState} from 'react';
import router5 from "../../../router";
import { PAGE_ROUTES } from "../../../definitions";
import {v4 as uuidv4} from "uuid";
import RowListItem from '../../../components/common/RowListItem';
import {ReactComponent as FavesSvg} from './assets/menuItem/faves.svg';
import {ReactComponent as OrdersSvg} from './assets/menuItem/orders.svg';
import {ReactComponent as BellSvg} from './assets/menuItem/bell.svg';
import {ReactComponent as CogSvg} from './assets/menuItem/cog.svg';
import {ReactComponent as LocSvg} from './assets/menuItem/loc.svg';
import {ReactComponent as ExitSvg} from './assets/menuItem/exit.svg';
import {ReactComponent as CodeSvg} from './assets/menuItem/code.svg';
import {ReactComponent as MailSvg} from './assets/menuItem/mail.svg';
import {ReactComponent as BookAnswer} from './assets/menuItem/bookAnswer.svg';
import {ReactComponent as OkSvg} from './assets/menuItem/ok.svg';
import { ReactComponent as ChevronSVG } from '../../../static/images/svg/new/chevronRight.svg';
import {logout} from '../../../services';
import {useDispatch, useSelector} from 'react-redux';
import {commonActions} from '../../../store/actions';
import {isEmptyObject} from '../../../utils';
import Loading from '../../../components/ui/universal/Loading';
import {useGetPointInfo} from '../../../hooks';

const Index = (props) => {
  const dispatch = useDispatch();
  const {getPointInfoById} = useGetPointInfo();
  const [isLoadedMyPoints, setIsLoadedMyPoints] = useState(false);


  let {account, myPoints} = useSelector((store) =>({
		account: store.users.account,
		myPoints: store.common.myPoints,
		pointsById: store.common.pointsById
	}));

	const {rules, id} = account;
	const {maxLevel, points: rulesPoints} = rules;

	let myPointsArray = [];

  Object.keys(rulesPoints).forEach((itemKey) => {
    const isAllowed = rulesPoints[itemKey] > 0;
    if (isAllowed) myPointsArray.push(parseInt(itemKey));
  });

	const myPointsStringArray = myPointsArray.join(',');

	useEffect(() => {
	  if (!myPointsArray.length) {
      setIsLoadedMyPoints(true);
    } else {
      const getMyPoints = dispatch(commonActions.getMyPoints({
        ids: myPointsStringArray
      }));
      getMyPoints.then(() => {
        setIsLoadedMyPoints(true);
      });
    }
	}, [myPointsStringArray]);


  if (!(!isEmptyObject(myPoints) || myPointsArray.length === 0) || !isLoadedMyPoints) {
    return (
      <Loading/>
    );
  }

  if (!!myPointsArray.length) {
    myPoints = myPoints.points;
  }

	const renderMyPoints = () => {
    if (isEmptyObject(myPoints)) return <div/>;

    return Object.keys(myPoints).map((itemKey) => {
      const {id, title} = getPointInfoById(+itemKey);
      return (
        <div key={uuidv4()}>
          <RowListItem
            title={title}
            chevron={<ChevronSVG/>}
            onClick={() => router5.navigate(PAGE_ROUTES.SPECIALMENU, { id })}
          />
        </div>
      );
    });
	};


	// useEffect(() => {
  //   dispatch(commonActions.getOrders({
  //     archive: 0,
  //     cursor: 0,
  //     count: 20
  //   }));
	//
  //   // dispatch(commonActions.addOrder({
  //   //   point_id: 1,
  //   //   contact_info: 5,
  //   //   pay_method_id: 1,
  //   //   not_call: 0,
  //   //   take_it_myself: 0,
  //   //   change_needed: 0,
  //   //   comment: 0,
  //   //   items: [{subject_id: 1, variation_id: 1, count: 1, additions: [{id: 1, count: 2}]}]
  //   // }));
	//
  // }, []);

	const renderMenu = () => {
		return <>
			<div className="Main__full_width">
				<RowListItem
					title="Мои заказы"
					icon={<OrdersSvg />}
					chevron={<ChevronSVG />}
					onClick={() => router5.navigate(PAGE_ROUTES.MYORDERS)}
				/>
				<RowListItem
					title="Уведомления"
					icon={<BellSvg />}
					chevron={<ChevronSVG />}
					onClick={() => router5.navigate(PAGE_ROUTES.NOTIFICATIONS)}
				/>
				<RowListItem
					title="Мои адреса"
					icon={<LocSvg />}
					chevron={<ChevronSVG />}
					onClick={() => router5.navigate('myaddress', {
						section: 'first'
					})}
				/>
				<RowListItem
					title="Настройки"
					icon={<CogSvg />}
					chevron={<ChevronSVG />}
					onClick={() => router5.navigate('settings', {
						section: 'general'
					})}
				/>

				<div className="Main__padding24">
				</div>
				<RowListItem
					title="Написать разработчикам"
					icon={<MailSvg />}
					chevron={<ChevronSVG />}
					onClick={() => router5.navigate(PAGE_ROUTES.FORDEV)}
				/>
				<RowListItem
					title="Ввести код"
					icon={<CodeSvg />}
					chevron={<ChevronSVG />}
					onClick={() => router5.navigate(PAGE_ROUTES.CODE)}
				/>
				<RowListItem
					title="Пользовательское соглашение"
					icon={<OkSvg />}
					chevron={<ChevronSVG />}
					onClick={() => router5.navigate(PAGE_ROUTES.AGREEMENT)}
				/>
				<RowListItem
					disabled={true}
					title="Отзывы и предложения"
					icon={<BookAnswer />}
					onClick={() => router5.navigate(PAGE_ROUTES.MAIN)}
				/>
				<RowListItem
					title="В избранном"
					disabled={true}
					icon={<FavesSvg />}
					onClick={() => router5.navigate(PAGE_ROUTES.MAIN)}
				/>
				{maxLevel === 8 ? (
					<RowListItem
						title="Типо админка"
						chevron={<ChevronSVG />}
						onClick={() => router5.navigate(PAGE_ROUTES.ADMIN_MENU)}
					/>
				):(
					<div/>
				)}
				<div>
          {!isEmptyObject(myPoints) && (
            <div className="MenuPage_header">
							<span>
								Управление
							</span>
            </div>

          )}
        </div>
				<div>
					{renderMyPoints()}
				</div>

				<RowListItem
					title="Выход"
					icon={<ExitSvg />}
					onClick={() => {
						logout();
					}}
				/>

				{maxLevel > 0 ? (
					<div className="MenuPage_id">
						<span>
							Ваш ID:	{id}
						</span>
					</div>
				) : (
					<div/>
				)}


				<div className="Main__padding24">
				</div>
			</div>
		</>
	};

	return (
		<div>
			{renderMenu()}
		</div>
	);
};

export default React.memo(Index);
