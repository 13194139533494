import { TYPES } from '../../actions';

const initialState = {
  users: {},
  personal: {},
  account: {},
  devices: {},
  auth: {}
};


export const usersReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPES.USERS.SET_BY_ID: {
      return {
        ...state,
        users: {
          ...state.users,
          ...action.payload
        }
      };
    }

    case TYPES.USERS.SET_DEVICES: {
      return {
        ...state,
        devices: action.payload
      };
    }

    case TYPES.USERS.SET_PERSONAL: {
      return {
        ...state,
        personal: action.payload
      };
    }

    case TYPES.USERS.SET_AUTH: {
      return {
        ...state,
        auth: action.payload
      };
    }

    case TYPES.USERS.SET_ACCOUNT: {
      return {
        ...state,
        account: action.payload
      };
    }

    default: return state;
  }
};
