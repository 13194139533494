import './static/styles/index.scss';
import React from 'react';
import { connect } from 'react-redux';
import Routes from './routes';
import router from './router';
import * as themes from './services/themes';
import { isMobile } from './utils';
import { ParamsContext } from './services';
import initialUrlParams from './services/initialUrlParams';
import * as Modals from './components/ui/universal/Modals';
import ViewTextModal from './modals/account/ViewText';
import AvatarUpload from './modals/account/AvatarUpload';
import PointAvatarUploadModal from './modals/account/PointAvatarUpload';
import SignInModal from './modals/external/SignIn';
import Reg from './modals/external/Reg';
import RegistrationModal from './modals/external/Registration';
import PointCategoriesModal from './modals/external/PointCategories';
import InfoPersonalModal from './modals/external/InfoPersonal';
import OrderDetailsModal from './modals/external/OrderDetails';
import AddAddressModal from './modals/external/AddAddress';
import EditSubjectModal from './modals/external/EditSubject';
import EditCategoryModal from './modals/external/EditCategory';
import addAdditionsModal from './modals/external/AddAdditions';
import addVariationsModal from './modals/external/AddVariations';
import EditAdditionsModal from './modals/external/EditAdditions';
import EditVariationsModal from './modals/external/EditVariations';
import store from './store/store';
import { usersActions } from './store/actions';
import * as auth from './services/auth';

const LIGHT = 'app_light';
// const DARK = 'app_dark';
const IS_MOBILE_CLASSNAME = 'mobile';
const logged = auth.isLogged();

if (logged) {
  const my_id = JSON.parse(auth.getToken()).owner_id;
  store.dispatch(usersActions.get(my_id, { personal: true }));
  store.dispatch(usersActions.getAccount(my_id));
}

console.error = (function() {
  let error = console.error;

  return function(exception) {
    if ((exception + '').indexOf('Warning: A component is `contentEditable`') !== 0) {
      error.apply(console, arguments);
    }
  }
})();

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      isMobile: false,
      route: undefined,
    };
  }

  __routerListenerHandler = (state, prevState) => {
    this.setState({
      route: state
    })
  };

  componentDidMount() {
    this.loadAssets();
    this.handleResize();
    router.addListener(this.__routerListenerHandler);
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    router.removeListener(this.__routerListenerHandler);
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    const { isLoaded, isMobile } = this.state;
    return !isLoaded ? (
      <div>Loading...</div>
    ) : (
      <div>
        <ParamsContext.Provider
          value={{
            ...initialUrlParams,

            isMobile
          }}
        >
          <Modals.Router
            modalsRoutes={{
              text: ViewTextModal,
              auth: SignInModal,
              reg: Reg,
              registration: RegistrationModal,
              pointCategories: PointCategoriesModal,
              infoPersonal: InfoPersonalModal,
              orderDetails: OrderDetailsModal,
              addAddress: AddAddressModal,
              editSubject: EditSubjectModal,
              editCategory: EditCategoryModal,
              addAdditions: addAdditionsModal,
              addVariations: addVariationsModal,
              editVariations: EditVariationsModal,
              editAdditions: EditAdditionsModal,
              pointAvatar: PointAvatarUploadModal,
              AvatarUpload,
            }}
          />
          <Routes router={this.state.route !== undefined ? this.state.route : {}} {...this.props} />
        </ParamsContext.Provider>
      </div>
    );
  }

  handleResize = () => {
    const w = document.body.offsetWidth;
    const mobile = isMobile();

    if (w <= 850) {
      if (!mobile) {
        document.body.classList.add(IS_MOBILE_CLASSNAME);
        this.setState({
          isMobile: true
        });
      }
    } else if (mobile) {
      document.body.classList.remove(IS_MOBILE_CLASSNAME);
      this.setState({
        isMobile: false
      });
    }
  };

  loadAssets = () => {
    if (!themes.themeIsInited()) {
      themes.setTheme(LIGHT);
    } else {
      themes.themeInit();
    }

    if (navigator.platform.match('Mac') !== null) {
      document.body.classList.add('OSX');
    } else if (navigator.platform.match('Linux') !== null) {
      document.body.classList.add('LINUX');
    }

    this.setState({
      isLoaded: true
    });
  };
}

export function mapDispatchToProps(dispatch) {
  return {}
}

export default connect((state) => {
  return {
    state
  };
}, mapDispatchToProps)(App);
