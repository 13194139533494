//
import React from 'react';
import { connect } from 'react-redux';

import BaseContainer from '../BaseContainer';
import { commonActions } from '../../../store/actions'
import * as Modals from '../../../components/ui/universal/Modals';
import Button from "../../../components/ui/universal/Button";

class Index extends BaseContainer {
  render() {
    return (
      <div>
        <Button
          onClick={() => {
            this.props.testAction();
            Modals.open('auth', {}, {}, {});
          }}
        >
          Авторизация
        </Button>
      </div>
    )
  }
}


let mapState2Props = state => ({...state}),
  mapDispatch2Props = commonActions;

export default connect(mapState2Props, mapDispatch2Props)(Index);
