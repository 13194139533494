import { TYPES } from "../../actions";
import {
  LOADING_STATUS_FAIL,
  LOADING_STATUS_IN_PROCESS,
  LOADING_STATUS_OK,
} from "../../../definitions/loading-statuses";

const initialState = {
  test: [],
  cities: [],
  city: null,
  sections: {},
  categories: {},
  activeSection: null,
  tags: null,
  orders: null,
  myOrders: {},
  pointOrders: {},
  pointOrdersId: {},
  addresses: null,
  pointsById: {},
  points: {},
  myPoints: {},
  subjectById: {},
  subjectsByCategory: {},
  loadingStatus: {
    subjectsIsLoading: LOADING_STATUS_OK,
  },
  subjects: {},
  activePointCategoryId: null,
  variationsById: {},
  additionsById: {},
  additionsId: {},
  variationId: {},
  selectedAdditions: new Map(),
  selectedVariations: new Map(),
};

export const commonReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPES.COMMON.SET_CITIES:
      return {
        ...state,
        cities: action.payload,
      };

    case TYPES.COMMON.SET_TAGS:
      return {
        ...state,
        tags: action.payload,
      };

    case TYPES.COMMON.SET_SELECTED_VARIATIONS_BY_ID: {
      const { id, data } = action.payload;

      const currentSelectedVariations = state.selectedVariations;
      currentSelectedVariations.set(id, data);

      return {
        ...state,
        selectedVariations: currentSelectedVariations,
      };
    }

    case TYPES.COMMON.SET_SELECTED_ADDITIONS_BY_ID: {
      const { id, data } = action.payload;

      const currentSelectedAdditions = state.selectedAdditions;
      currentSelectedAdditions.set(id, data);

      return {
        ...state,
        selectedAdditions: currentSelectedAdditions,
      };
    }

    case TYPES.COMMON.REMOVE_SELECTED_ADDITIONS_BY_ID: {
      const { id } = action.payload;

      const currentSelectedAdditions = state.selectedAdditions;
      currentSelectedAdditions.delete(id);

      return {
        ...state,
        selectedAdditions: currentSelectedAdditions,
      };
    }

    case TYPES.COMMON.REMOVE_SELECTED_VARIATIONS_BY_ID: {
      const { id } = action.payload;

      const currentSelectedVariations = state.selectedVariations;
      currentSelectedVariations.delete(id);

      return {
        ...state,
        selectedVariations: currentSelectedVariations,
      };
    }

    case TYPES.COMMON.CLEAR_SELECTED_ADDITIONS:
      return {
        ...state,
        selectedAdditions: new Map(),
      };

    case TYPES.COMMON.CLEAR_SELECTED_VARIATIONS:
      return {
        ...state,
        selectedVariations: new Map(),
      };

    case TYPES.COMMON.SET_ACTIVE_POINT_CATEGORY_ID:
      return {
        ...state,
        activePointCategoryId: action.payload,
      };

    case TYPES.COMMON.SET_ADDRESSES:
      return {
        ...state,
        addresses: action.payload,
      };

    case TYPES.COMMON.SET_MY_ORDERS:
      return {
        ...state,
        myOrders: action.payload,
      };

    case TYPES.COMMON.APPEND_MY_ORDERS:
      return {
        ...state,
        myOrders: {
          countAll: action.payload.countAll,
          hasNext: action.payload.hasNext,
          rows: [...state.myOrders.rows, ...action.payload.rows],
        },
      };

    case TYPES.COMMON.SET_POINT_ORDERS:
      return {
        ...state,
        pointOrders: action.payload,
      };

    case TYPES.COMMON.APPEND_POINT_ORDERS:
      return {
        ...state,
        pointOrders: {
          countAll: action.payload.countAll,
          hasNext: action.payload.hasNext,
          rows: [...state.pointOrders.rows, ...action.payload.rows],
        },
      };

    case TYPES.COMMON.SET_POINT_ORDERS_ID:
      return {
        ...state,
        pointOrdersId: action.payload.rows[0],
      };

    case TYPES.COMMON.SET_SECTIONS:
      return {
        ...state,
        sections: action.payload,
      };

    case TYPES.COMMON.SET_CATEGORIES:
      return {
        ...state,
        categories: {
          ...state.categories,
          ...action.payload,
        },
      };

    case TYPES.COMMON.SET_VARIATIONS_BY_ID: {
      const { id, data } = action.payload;
      return {
        ...state,
        variationsById: {
          ...state.variationsById,
          [id]: data,
        },
      };
    }

    case TYPES.COMMON.SET_ADDITIONS_BY_ID: {
      const { id, data } = action.payload;
      return {
        ...state,
        additionsById: {
          ...state.additionsById,
          [id]: data,
        },
      };
    }

    case TYPES.COMMON.SET_ADDITIONS_ID: {
      const { id, data } = action.payload;
      return {
        ...state,
        additionsId: data[0],
      };
    }

    case TYPES.COMMON.SET_VARIATIONS_ID: {
      const { id, data } = action.payload;
      return {
        ...state,
        variationId: data[0],
      };
    }

    case TYPES.COMMON.SET_SUBJECTS:
      return {
        ...state,
        subjects: {
          ...state.subjects,
          ...action.payload,
        },
      };

    case TYPES.COMMON.SET_SUBJECT_BY_ID:
      return {
        ...state,
        subjectById: action.payload.rows[0],
      };

    case TYPES.COMMON.SET_SUBJECTS_BY_CATEGORY:
      return {
        ...state,
        subjectsByCategory: {
          ...state.subjectsByCategory,
          [action.payload.category_id]: {
            countAll: action.payload.countAll,
            hasNext: action.payload.hasNext,
            rows: [...action.payload.rows],
          },
        },
      };

    case TYPES.COMMON.APPEND_SUBJECTS_BY_CATEGORY:
      return {
        ...state,
        subjectsByCategory: {
          ...state.subjectsByCategory,
          [action.payload.category_id]: {
            countAll: action.payload.countAll,
            hasNext: action.payload.hasNext,
            rows: [...state.subjectsByCategory[action.payload.category_id].rows, ...action.payload.rows],
          },
        },
      };

    case TYPES.COMMON_SET_LOADING_STATUS: {
      return Object.assign({}, state, {
        loadingStatus: Object.assign({}, state.loadingStatus, { [action.section]: action.status }),
      });
    }

    case TYPES.COMMON.SET_POINTS:
      return {
        ...state,
        points: action.payload,
      };

    case TYPES.COMMON.SET_MY_POINTS:
      return {
        ...state,
        myPoints: action.payload,
      };

    case TYPES.COMMON.SET_POINT_BY_ID:
      return {
        ...state,
        pointsById: {
          ...state.pointsById,
          ...action.payload,
        },
      };

    case TYPES.COMMON.SET_ACTIVE_SECTION:
      return {
        ...state,
        activeSection: action.payload,
      };

    case TYPES.COMMON.SET_CITY:
      return {
        ...state,
        city: action.payload,
      };

    case TYPES.COMMON.SET_TEST:
      return {
        ...state,
        test: [...state.test, ...action.payload],
      };
    default:
      return state;
  }
};
