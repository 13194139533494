import "./index.scss";
import React from "react";
import Padding from "../../../components/ui/universal/Padding";
import Spacer from "../../../components/ui/universal/Spacer";
import PrimaryButton from "../../../components/ui/universal/PrimaryButton";

const BalancePage = () => {
  return (
    <div className="BalancePage">
      <Padding vertical={2} horizontal={2}>
        <Spacer size={5}/>
        <div className="BalancePage_header">
          <span>
            Ваш баланс
          </span>
        </div>

        <div className="BalancePage_balance">
          <span>
            5 900 600 p.
          </span>
        </div>

        <Spacer size={10}/>

        <div>
          <PrimaryButton
            text="Пополнить баланс"
          />
        </div>

        <Spacer size={10}/>

        <div>
          <PrimaryButton
            text="Оплатить услуги"
            mode="primary"
          />
        </div>
      </Padding>
    </div>


  )
};

export default BalancePage;