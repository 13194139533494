import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'billhead_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="billhead_outline_28"><path clip-rule="evenodd" d="M10.174 5.008c-.531.009-.982.025-1.377.057-.771.063-1.243.182-1.613.371a4 4 0 00-1.748 1.748c-.189.37-.308.842-.371 1.614C5 9.58 5 10.584 5 12v4c0 1.417 0 2.42.065 3.203.063.772.182 1.243.371 1.613a4 4 0 001.748 1.748c.37.189.842.308 1.613.371C9.581 23 10.583 23 12 23h4c1.417 0 2.419 0 3.203-.065.772-.063 1.243-.182 1.613-.37a4 4 0 001.748-1.749c.189-.37.308-.841.371-1.613C23 18.419 23 17.417 23 16v-4c0-1.416 0-2.419-.065-3.202-.063-.772-.182-1.243-.371-1.614a4 4 0 00-1.748-1.748c-.37-.189-.842-.308-1.613-.371a21.177 21.177 0 00-1.377-.057A3.001 3.001 0 0115 7h-2a3.001 3.001 0 01-2.826-1.992zm7.658-2c.574.009 1.081.027 1.533.064.896.073 1.66.226 2.359.582a6 6 0 012.622 2.622c.356.7.51 1.463.583 2.359.071.874.071 1.958.071 3.321V16.044c0 1.363 0 2.447-.071 3.322-.074.895-.227 1.659-.583 2.358a6 6 0 01-2.622 2.622c-.7.356-1.463.51-2.359.583-.874.071-1.958.071-3.321.071H11.956c-1.363 0-2.447 0-3.321-.071-.896-.073-1.66-.226-2.359-.583a6 6 0 01-2.622-2.622c-.356-.7-.51-1.463-.583-2.358C3 18.49 3 17.407 3 16.044V11.956c0-1.363 0-2.447.071-3.321.074-.896.227-1.66.583-2.359a6 6 0 012.622-2.622c.7-.356 1.463-.51 2.359-.582.452-.037.96-.055 1.533-.064A3.001 3.001 0 0113 1h2c1.31 0 2.423.839 2.832 2.008zM12 4a1 1 0 011-1h2a1 1 0 110 2h-2a1 1 0 01-1-1zm-2 10.5a1 1 0 10-2 0 1 1 0 002 0zM9 9a1 1 0 110 2 1 1 0 010-2zm1 10a1 1 0 10-2 0 1 1 0 002 0zm3-5.5a1 1 0 100 2h6a1 1 0 100-2zM12 10a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1zm1 8a1 1 0 100 2h4a1 1 0 100-2z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28BillheadOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28BillheadOutline: FC<Icon28BillheadOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28BillheadOutline as any).mountIcon = mountIcon;

export default Icon28BillheadOutline;
