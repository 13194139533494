import "./index.scss";
import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useState } from "react";
import Padding from "../../../components/ui/universal/Padding";
import Tumbler from "../../../components/ui/universal/Tumbler";
import { ReactComponent as SearchSVG } from "../../../components/ui/mobile/HeaderIcon/assets/svg/search.svg";
import Input from "../../../components/ui/universal/Input";
import Spacer from "../../../components/ui/universal/Spacer";
import { ReactComponent as EditSVG } from "../../../static/images/svg/new/edit.svg";
import * as Yup from "yup";
import { ErrorMessage, Form, Formik } from "formik";
import { commonActions } from "../../../store/actions/common-actions";
import { LOADING_STATUS_IN_PROCESS, PAGE_ROUTES } from "../../../definitions";
import PrimaryButton from "../../../components/ui/universal/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as FotoSVG } from "../../../static/images/svg/new/foto.svg";
import IconUniversal from "../../../components/ui/universal/IconUniversal";
import ItemsCarousel from "react-items-carousel";
import Textarea from "../../../components/ui/universal/Textarea";
import Switch from "../../../components/ui/universal/Switch";
import Loading from "../../../components/ui/universal/Loading";
import classNames from "classnames";
import * as Modals from "../../../components/ui/universal/Modals";
import { useGetMyPointInfo } from "../../../hooks";
import IsEmptyHandler from "../../../components/common/is-empty-handler";
import Pagination from "../../../components/common/pagination";
import cogoToast from "cogo-toast";
import ProductCategorySelection from "../../../components/common/ProductCategorySelection";
import { ReactComponent as MenuSVG } from "../../../static/images/svg/new/menu.svg";
import { ReactComponent as PointCategoriesSVG } from "../../../static/images/svg/new/point_categories.svg";
import router from "../../../router";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import ImageUpload from "../../../components/common/UploadPhoto";
import { declOfNum } from "../../../utils/declOfNum";

const REQUIRED_FIELD_ERROR = "Обязательное поле";

const ProductsPage = () => {
  const [tumblerOn, setTumblerOn] = useState(" ");
  const [value, setValue] = useState("");
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [switchOn, setSwitchOn] = useState(false);
  //const [isDisabled, setIsDisabled] = useState( false);
  const [isVisibleCategories, setIsVisibleCategories] = useState(false);
  const [force, setForce] = useState(0);
  const [openModalCover, setOpenModalCover] = useState(false);
  const [openModalGallery, setOpenModalGallery] = useState(false);
  const [galleryPreviewUrl, setGalleryPreviewUrl] = useState({
    2: "",
    3: "",
    4: "",
    5: "",
    6: "",
    7: "",
    8: "",
    9: "",
    10: "",
  });
  const [galleryHash, setGalleryHash] = useState({
    2: "",
    3: "",
    4: "",
    5: "",
    6: "",
    7: "",
    8: "",
    9: "",
    10: "",
  });
  const [coverHash, setCoverHash] = useState("");
  const [coverPreviewUrl, setCoverPreviewUrl] = useState("");
  const dispatch = useDispatch();
  const { pointId } = useGetMyPointInfo();
  const appendCursor = 20;
  const sortable = (a, b) => {
    const categoryA = categories[pointId][a];
    const categoryB = categories[pointId][b];
    if (categoryA.priority > categoryB.priority) {
      return -1;
    } else {
      return 1;
    }
  };

  const setActiveCategory = (val) => {
    dispatch(commonActions.setActivePointCategoryId(val));
  };

  const { activeCategory } = useSelector((state) => ({
    activeCategory: state.common.activePointCategoryId,
  }));

  const chevronWidth = 20;

  const { categories, pointsById, subjectsByCategory, loadingStatus } = useSelector((store) => ({
    categories: store.common.categories,
    pointsById: store.common.pointsById,
    subjectsByCategory: store.common.subjectsByCategory,
    loadingStatus: store.common.loadingStatus,
  }));

  useEffect(() => {
    if (!pointsById.hasOwnProperty(pointId)) {
      dispatch(
        commonActions.getPoints({
          id: pointId,
        }),
      );
    }

    const getCategories = dispatch(
      commonActions.getCategories({
        point_id: pointId,
      }),
    );

    getCategories.then((data) => {
      setActiveCategory(
        Object.values(data.data.categories).sort((a, b) => {
          if (a.priority > b.priority) {
            return -1;
          } else {
            return 1;
          }
        })[0].id,
      );
    });
  }, [pointId]);

  useEffect(() => {
    dispatch(
      commonActions.getSubjects({
        category_id: activeCategory,
        extended: 1,
      }),
    );
  }, [force]);

  const updateSubjects = () => {
    dispatch(
      commonActions.getSubjects({
        category_id: activeCategory,
        extended: 1,
      }),
    );
  };

  useEffect(() => {
    if (activeCategory !== null && !subjectsByCategory.hasOwnProperty(activeCategory)) {
      updateSubjects();
    }
  }, [activeCategory]);

  const subjects = {
    append: () => {
      dispatch(
        commonActions.getSubjects(
          {
            category_id: activeCategory,
            cursor: subjectsByCategory[activeCategory].hasNext.cursor + appendCursor,
            extended: 1,
          },
          true,
        ),
      );
    },
  };

  if (!pointsById.hasOwnProperty(pointId) || !categories.hasOwnProperty(pointId) || !activeCategory) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  const addCategory = categories[pointId][activeCategory];
  const sortabledCategories = Object.keys(categories[pointId]).sort(sortable);

  const getAverageTime = (d, h, m) => {
    if (d > 0) {
      return declOfNum(d, ["день", "дня", "дней"]);
    }
    if (h > 0) {
      return declOfNum(h, ["час", "часа", "часов"]);
    }
    if (m > 0) {
      return declOfNum(m, ["минута", "минуты", "минут"]);
    }
  };

  const renderTabs = () => {
    const chevronWidth = 40;
    return (
      <div className="NewPoint_categories no_select">
        <div className="NewPoint_categories_box" style={{ width: `0 ${chevronWidth}px` }}>
          <ItemsCarousel
            requestToChangeActive={setActiveItemIndex}
            activeItemIndex={activeItemIndex}
            numberOfCards={4}
            gutter={0}
            leftChevron={
              <button className="NewPoint_cursorLeft">
                <IconUniversal typeIcon="newLeft" />
              </button>
            }
            rightChevron={
              <button className="NewPoint_cursorRight">
                <IconUniversal typeIcon="newRight" />
              </button>
            }
            outsideChevron={false}
            chevronWidth={chevronWidth}>
            {sortabledCategories.map((_category) => {
              const category = categories[pointId][_category];
              return (
                <div
                  key={uuidv4()}
                  className={classNames("NewPoint_categories_item", {
                    active: category.id === activeCategory,
                  })}
                  onClick={() => {
                    setActiveCategory(category.id);
                  }}>
                  <span>{category.title.toLocaleUpperCase()}</span>
                </div>
              );
            })}
          </ItemsCarousel>
        </div>
        <div
          className="PointInfo_box_brief_categories"
          onClick={() => {
            Modals.open("pointCategories", {}, {}, {});
          }}>
          <PointCategoriesSVG />
        </div>
      </div>
    );
  };

  const renderProduct = () => {
    return (
      <div>
        <Padding vertical={2} horizontal={2}>
          <Input icon={<SearchSVG />} mode="secondary" placeholder={"поиск"} value={value} onChangeHandler={setValue} />
          <Spacer size={10} />
          {renderTabs()}
          <Spacer size={10} />
          <div>
            {subjectsByCategory.hasOwnProperty(activeCategory) &&
              (() => {
                return (
                  <IsEmptyHandler
                    counter={subjectsByCategory[activeCategory].countAll}
                    showCounter={true}
                    counterText={categories[pointId][activeCategory].title}
                    placeholderText="Здесь пусто :)">
                    <Pagination
                      hasNext={subjectsByCategory[activeCategory].hasNext.hasNext}
                      loadingStatus={loadingStatus["subjectsIsLoading"] === LOADING_STATUS_IN_PROCESS}
                      loadingMore={subjects.append}>
                      <Padding vertical={2}>
                        {subjectsByCategory[activeCategory].rows.map((item) => {
                          const category = categories[pointId][item.category_id];
                          const subjectId = item.id;
                          return (
                            <div key={uuidv4()} className="SpecialProductsPage_item">
                              <div
                                className="SpecialProductsPage_item_cover"
                                style={{
                                  backgroundImage: `url(${item.hasOwnProperty("photo1") ? item.photo1["150"] : ""})`,
                                }}
                              />
                              <div className="SpecialProductsPage_item_info">
                                <div className="SpecialProductsPage_item_info_text">
                                  <span>Название:</span>
                                  <div className="SpecialProductsPage_item_info_text_info">{item.name}</div>
                                </div>
                                <div className="SpecialProductsPage_item_info_text">
                                  <span>Категория:</span>
                                  <div className="SpecialProductsPage_item_info_text_info">{category.title}</div>
                                </div>

                                <div className="SpecialProductsPage_item_info_text">
                                  <span>Время приготовления:</span>
                                  <div className="SpecialProductsPage_item_info_text_info">
                                    {/*{item.average_lead_time_on_minutes} мин.*/}
                                    {getAverageTime(
                                      item.average_lead_time_on_day,
                                      item.average_lead_time_on_hours,
                                      item.average_lead_time_on_minutes,
                                    )}
                                  </div>
                                </div>

                                <div className="SpecialProductsPage_item_info_text">
                                  <span>Id товара:</span>
                                  <div className="SpecialProductsPage_item_info_text_info">{item.id}</div>
                                </div>

                                <div className="SpecialProductsPage_item_info_text">
                                  <span>Цена:</span>
                                  <div className="SpecialProductsPage_item_info_text_info">{item.price} ₽</div>
                                </div>

                                <div className="SpecialProductsPage_item_info_text">
                                  <span>Вес:</span>
                                  <div className="SpecialProductsPage_item_info_text_info">{item.weight} гр.</div>
                                </div>

                                <div className="SpecialProductsPage_item_info_name">
                                  <div className="SpecialProductsPage_item_info_text">
                                    <span>Описание:</span>
                                  </div>
                                  <div className="SpecialProductsPage_item_info_name_info">{item.description}</div>
                                </div>
                              </div>
                              <div className="SpecialProductsPage_item_right">
                                <div
                                  className="SpecialProductsPage_item_right_icon"
                                  onClick={() => {
                                    Modals.open(
                                      "editSubject",
                                      {
                                        sub_id: subjectId,
                                      },
                                      {},
                                      {
                                        onCloseHandler: () => {
                                          updateSubjects();
                                        },
                                      },
                                    );
                                  }}>
                                  <div className="SpecialProductsPage_item_right_icon_box">
                                    <EditSVG />
                                  </div>
                                  <span>Изменить</span>
                                </div>

                                <div
                                  className="SpecialProductsPage_item_right_icon"
                                  onClick={() => {
                                    router.navigate(PAGE_ROUTES.OPTIONS, {
                                      subjectId: item.id,
                                      id: pointId,
                                    });
                                  }}>
                                  <div className="SpecialProductsPage_item_right_icon_box">
                                    <MenuSVG />
                                  </div>
                                  <span>Опции</span>
                                </div>
                                <div className="SpecialProductsPage_item_right_icon">
                                  <div className="SpecialProductsPage_item_right_icon_box">
                                    <Switch
                                      on={item.disabled === 0 && true}
                                      // onChange={() => setIsDisabled(!isDisabled)}
                                    />
                                  </div>
                                  <span>Статус: {item.disabled === 0 ? "вкл" : "выкл"}</span>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </Padding>
                    </Pagination>
                  </IsEmptyHandler>
                );
              })()}
          </div>
        </Padding>
      </div>
    );
  };

  const initialFormikState = {
    name: "",
    description: "",
    weight: null,
    price: null,
    average_lead_time_on_minutes: null,
    average_lead_time_on_day: null,
    average_lead_time_on_hours: null,
    priority: null,
    photo1: "",
    photo2: "eee",
    photo3: "eee",
    photo4: "eee",
    photo5: "eee",
    photo6: "eee",
    photo7: "eee",
    photo8: "eee",
    photo9: "eee",
    photo10: "eee",
    discount: null,
  };

  const formikValidationSchema = Yup.object().shape({
    name: Yup.string().min(3, "Не менее 3 символо").max(25, "Не более 25 символов").required(REQUIRED_FIELD_ERROR),

    description: Yup.string()
      .min(3, "Не менее 3 символов")
      .max(55, "Не более 55 символов")
      .required(REQUIRED_FIELD_ERROR),

    weight: Yup.number().nullable().min(1, "Укажите вес").required(REQUIRED_FIELD_ERROR),

    price: Yup.number().nullable().min(1, "Укажите стоимость").required(REQUIRED_FIELD_ERROR),

    average_lead_time_on_minutes: Yup.number()
      .nullable()
      .min(1, "Укажите время изготовления")
      .required(REQUIRED_FIELD_ERROR),

    average_lead_time_on_day: Yup.number()
      .nullable()
      .min(1, "Укажите время изготовления")
      .required(REQUIRED_FIELD_ERROR),

    average_lead_time_on_hours: Yup.number()
      .nullable()
      .min(1, "Укажите время изготовления")
      .required(REQUIRED_FIELD_ERROR),

    priority: Yup.number().nullable().min(1, "Укажите приоритет в списке").required(REQUIRED_FIELD_ERROR),

    photo1: Yup.string().min(1, "Добавьте обложку товару").required(REQUIRED_FIELD_ERROR),

    discount: Yup.number().nullable().min(1, "Укажите скидку в %").required(REQUIRED_FIELD_ERROR),
  });

  const renderForm = () => {
    return (
      <div>
        <Formik
          initialValues={initialFormikState}
          validationSchema={formikValidationSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            const _values = values;
            _values.disabled = 0;
            _values.category_id = activeCategory;
            _values.photo1 = coverHash;

            Object.keys(galleryHash).map((item) => {
              const valuesGallery = galleryHash[item];
              if (!valuesGallery.length) {
                delete _values.photos[item];
              } else {
                _values[`photo${item}`] = valuesGallery;
              }
            });

            const addSubject = dispatch(commonActions.addSubject({ ...values }));
            addSubject.then(
              (data) => {
                if (data.status) {
                  cogoToast.success("Сохранено");
                  setSubmitting(true);
                  setForce(force + 1);
                }
              },
              (data) => {
                if (!data.status) {
                  setErrors();
                  setSubmitting(false);
                  cogoToast.error("Ошибка,попробуйте обновить страницу");
                }
              },
            );
          }}
          render={({ errors, touched, values, setFieldValue }) => (
            <Form>
              <Spacer size={5} />
              <Input
                name="name"
                mode="secondary"
                title="Название товара"
                placeholder="Введите название товара"
                value={values.name}
                onChangeHandler={(e) => setFieldValue("name", e)}
                error={!!(errors.name && touched.name)}
                description={
                  errors.name &&
                  touched.name && <ErrorMessage name="name" component="div" className="invalid-feedback" />
                }
              />
              <Spacer size={10} />
              <div
                className="SpecialProductsPage_button"
                onClick={() => {
                  setIsVisibleCategories(!isVisibleCategories);
                }}>
                <span>Выбор категории:</span>
                <div className="SpecialProductsPage_button_category">{addCategory.title}</div>
              </div>
              {isVisibleCategories && (
                <ProductCategorySelection
                  onClickClose={() => setIsVisibleCategories(false)}
                  onClick={() => {
                    setIsVisibleCategories(false);
                  }}
                />
              )}
              <Spacer size={5} />
              <div className="SpecialProductsPage_coverBox">
                <div className="SpecialProductsPage_coverBox_name">
                  <span>Обложка</span>
                </div>
                <div
                  className="SpecialProductsPage_coverBox_box"
                  style={{ backgroundImage: `url(${coverPreviewUrl})` }}
                  onClick={() => {
                    setOpenModalCover(!openModalCover);
                  }}>
                  {!coverHash && <FotoSVG />}
                </div>
                <ErrorMessage name="photo1" component="div" className="invalid-feedback" />
                {openModalCover && (
                  <div>
                    <ImageUpload
                      onChangeHandler={({ imagePreviewUrl, hash }) => {
                        setCoverPreviewUrl(imagePreviewUrl);
                        setCoverHash(hash);
                      }}
                      onClickClose={() => {
                        setOpenModalCover(!openModalCover);
                      }}
                    />
                  </div>
                )}

                <div className="SpecialProductsPage_coverBox_download">
                  <span>Загрузить обложку</span>
                </div>
              </div>
              <Spacer size={15} />
              <div className="SpecialProductsPage_coverBox_name">
                <span>Загрузите дополнительные изображения</span>
              </div>
              <ItemsCarousel
                requestToChangeActive={setActiveItemIndex}
                activeItemIndex={activeItemIndex}
                numberOfCards={3}
                gutter={0}
                outsideChevron={false}
                chevronWidth={chevronWidth}>
                <div
                  className="SpecialProductsPage_carouselItem"
                  onClick={() => {
                    setOpenModalGallery(!openModalGallery);
                  }}>
                  <FotoSVG />
                </div>

                {Object.keys(galleryPreviewUrl).map((item) => {
                  const _gallery = galleryPreviewUrl[item];
                  if (!_gallery.length) {
                    return false;
                  }
                  return (
                    <div
                      key={uuidv4()}
                      className="SpecialProductsPage_carouselItem"
                      style={{ backgroundImage: `url(${_gallery})` }}></div>
                  );
                })}
              </ItemsCarousel>
              {openModalGallery && (
                <div>
                  <ImageUpload
                    onChangeHandler={({ imagePreviewUrl, hash }) => {
                      Object.keys(galleryPreviewUrl).map((item) => {
                        const galleryUrl = galleryPreviewUrl[item];
                        if (!galleryUrl.length) {
                          setGalleryPreviewUrl({
                            ...galleryPreviewUrl,
                            [item]: imagePreviewUrl,
                          });
                        }
                      });
                      Object.keys(galleryHash).map((item) => {
                        const _galleryHash = galleryHash[item];
                        if (!_galleryHash.length) {
                          setGalleryHash({
                            ...galleryHash,
                            [item]: hash,
                          });
                        }
                      });
                    }}
                    onClick={() => {
                      setOpenModalGallery(!openModalGallery);
                    }}
                  />
                </div>
              )}

              <Spacer size={15} />

              <div>
                <Textarea
                  title="Описание товара"
                  maxRows={5}
                  minRows={5}
                  placeholder="Введите название"
                  name="description"
                  value={values.description}
                  onChangeHandler={(e) => {
                    setFieldValue("description", e);
                  }}
                  error={!!(errors.description && touched.description)}
                  description={
                    errors.description &&
                    touched.description && (
                      <ErrorMessage name="description" component="div" className="invalid-feedback" />
                    )
                  }
                />
              </div>

              <Spacer size={15} />

              <Input
                maxlength={20}
                name="priority"
                mode="secondary"
                title="Числовой приоритет для сотрировки"
                placeholder="Введите номер позиции"
                value={values.priority}
                onChangeHandler={(e) => setFieldValue("priority", e)}
                error={!!(errors.priority && touched.priority)}
                description={
                  errors.priority &&
                  touched.priority && <ErrorMessage name="priority" component="div" className="invalid-feedback" />
                }
              />

              <Spacer size={15} />

              <Input
                maxlength={20}
                name="price"
                mode="secondary"
                title="Цена"
                placeholder="Цена в рублях"
                value={values.price}
                onChangeHandler={(e) => setFieldValue("price", e)}
                error={!!(errors.price && touched.price)}
                description={
                  errors.price &&
                  touched.price && <ErrorMessage name="price" component="div" className="invalid-feedback" />
                }
              />

              <Spacer size={15} />

              <Input
                maxlength={20}
                name="weight"
                mode="secondary"
                title="Вес(грамм)"
                placeholder="Введите вес в граммах"
                value={values.weight}
                onChangeHandler={(e) => setFieldValue("weight", e)}
                error={!!(errors.weight && touched.weight)}
                description={
                  errors.weight &&
                  touched.weight && <ErrorMessage name="weight" component="div" className="invalid-feedback" />
                }
              />

              <Spacer size={15} />

              <div>
                <Slider
                  className="slider"
                  onChange={(e) => {
                    setFieldValue("discount", e);
                  }}
                  defaultValue={values.discount}
                  min={0}
                  max={90}
                  step={1}
                />
              </div>

              <Spacer size={15} />
              <Input
                maxlength={3}
                name="discount"
                mode="secondary"
                title="Скидкa в (%)"
                placeholder="Введите процент скидки"
                value={values.discount}
                onChangeHandler={(e) => setFieldValue("discount", e)}
                error={!!(errors.discount && touched.discount)}
                description={
                  errors.discount &&
                  touched.discount && <ErrorMessage name="discount" component="div" className="invalid-feedback" />
                }
              />

              <Spacer size={15} />

              <Input
                maxlength={2}
                name="average_lead_time_on_day"
                mode="secondary"
                title="Время изготовления (дни)"
                placeholder="Ведите время изготовления (дни)"
                value={values.average_lead_time_on_day}
                onChangeHandler={(e) => setFieldValue("average_lead_time_on_day", e)}
                error={!!(errors.average_lead_time_on_day && touched.average_lead_time_on_day)}
                description={
                  errors.average_lead_time_on_day &&
                  touched.average_lead_time_on_day && (
                    <ErrorMessage name="average_lead_time_on_day" component="div" className="invalid-feedback" />
                  )
                }
              />

              <Spacer size={10} />

              <Input
                maxlength={2}
                name="average_lead_time_on_hours"
                mode="secondary"
                title="Время изготовления (часы)"
                placeholder="Ведите время изготовления (часы)"
                value={values.average_lead_time_on_hours}
                onChangeHandler={(e) => setFieldValue("average_lead_time_on_hours", e)}
                error={!!(errors.average_lead_time_on_hours && touched.average_lead_time_on_hours)}
                description={
                  errors.average_lead_time_on_hours &&
                  touched.average_lead_time_on_hours && (
                    <ErrorMessage name="average_lead_time_on_hours" component="div" className="invalid-feedback" />
                  )
                }
              />

              <Spacer size={10} />

              <Input
                maxlength={2}
                name="average_lead_time_on_minutes"
                mode="secondary"
                title="Время изготовления (мин)"
                placeholder="Ведите время изготовления (мин)"
                value={values.average_lead_time_on_minutes}
                onChangeHandler={(e) => setFieldValue("average_lead_time_on_minutes", e)}
                error={!!(errors.average_lead_time_on_minutes && touched.average_lead_time_on_minutes)}
                description={
                  errors.average_lead_time_on_minutes &&
                  touched.average_lead_time_on_minutes && (
                    <ErrorMessage name="average_lead_time_on_minutes" component="div" className="invalid-feedback" />
                  )
                }
              />

              <Spacer size={10} />

              <div className="SpecialProductsPage_switch">
                <span>Отображать товар</span>
                <div className="SpecialProductsPage_switch_box">
                  <Switch on={switchOn} onChange={() => setSwitchOn(!switchOn)} />
                </div>
              </div>

              <div className="SpecialProductsPage_buttonBox">
                <PrimaryButton text="Добавить" mode="primary" btnType="submit" />
              </div>
            </Form>
          )}
        />
      </div>
    );
  };

  return (
    <div>
      <Padding horizontal={2} vertical={2}>
        <Tumbler on={tumblerOn} onChange={() => setTumblerOn(!tumblerOn)} firstTitle="Список" secondTitle="Добавить" />
      </Padding>

      {!tumblerOn && <div>{renderProduct()}</div>}

      {!!tumblerOn && (
        <div>
          <Padding vertical={2} horizontal={3}>
            {renderForm()}
          </Padding>
        </div>
      )}
    </div>
  );
};

export default ProductsPage;
