import { TYPES } from "../types";
import store from "../../store";
import * as api from "../../../services/api";
import { LOADING_STATUS_FAIL, LOADING_STATUS_IN_PROCESS, LOADING_STATUS_OK } from "../../../definitions";

export const commonActions = {
  getCities: () => (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get("database.cities", {
          important: 1,
        })
        .then((response) => {
          api.apiResponseTreatment(response).then(
            (data) => {
              dispatch({
                type: TYPES.COMMON.SET_CITIES,
                payload: data.cities,
              });

              resolve({ status: true });
            },
            () => {
              reject({ status: false });
            },
          );

          // dispatch(loadingActions.removeLoading(
          //   LOADING_STATUSES.PERSONAL_INFO_LOADING_KEY + user_id
          // ));
        })
        .catch((data) => {
          // dispatch(loadingActions.removeLoading(
          //   LOADING_STATUSES.PERSONAL_INFO_LOADING_KEY + user_id
          // ));
          reject(data);
        });
    });
  },

  getPoints: (params) => (dispatch, state) => {
    return new Promise((resolve, reject) => {
      api
        .get("shop.getPoints", params)
        .then((response) => {
          api.apiResponseTreatment(response).then(
            (data) => {
              dispatch({
                type: TYPES.COMMON.SET_POINTS,
                payload: data.points,
              });

              const stage = store.getState();
              const newDataObject = {};
              Object.keys(data.points).map((objKey) => {
                const item = data.points[objKey];
                if (!stage.common.pointsById.hasOwnProperty(item.id)) {
                  newDataObject[item.id] = item;
                }
              });

              dispatch({
                type: TYPES.COMMON.SET_POINT_BY_ID,
                payload: newDataObject,
              });

              resolve({ status: true, data });
            },
            () => {
              reject({ status: false });
            },
          );
        })
        .catch((data) => {
          reject(data);
        });
    });
  },

  getMyPoints: (params) => (dispatch, state) => {
    return new Promise((resolve, reject) => {
      api
        .get("shop.getPoints", params)
        .then((response) => {
          api.apiResponseTreatment(response).then(
            (data) => {
              dispatch({
                type: TYPES.COMMON.SET_MY_POINTS,
                payload: data,
              });

              const stage = store.getState();
              const newDataObject = {};
              Object.keys(data.points).map((objKey) => {
                const item = data.points[objKey];
                if (!stage.common.pointsById.hasOwnProperty(item.id)) {
                  newDataObject[item.id] = item;
                }
              });

              dispatch({
                type: TYPES.COMMON.SET_POINT_BY_ID,
                payload: newDataObject,
              });

              resolve({ status: true, data });
            },
            () => {
              reject({ status: false });
            },
          );
        })
        .catch((data) => {
          reject(data);
        });
    });
  },

  addOrder: (params) => (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .post("shop.addOrder", { ...params })
        .then((response) => {
          api.apiResponseTreatment(response).then(
            (data) => {
              resolve({ status: true, data });
            },
            (data) => {
              reject({ status: false, data });
            },
          );
        })
        .catch((data) => {
          reject(data);
        });
    });
  },

  editPoint: (params) => (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .post("shop.editPoint", { ...params })
        .then((response) => {
          api.apiResponseTreatment(response).then(
            (data) => {
              resolve({
                status: true,
                response,
              });
            },
            (errors) => {
              reject({
                status: false,
                errors,
              });
            },
          );
        })
        .catch((data) => {
          reject(data);
        });
    });
  },

  getVariations: (params) => (dispatch, state) => {
    const { subject_id } = params;
    return new Promise((resolve, reject) => {
      api
        .get("shop.getVariations", params)
        .then((response) => {
          api.apiResponseTreatment(response).then(
            (data) => {
              dispatch({
                type: TYPES.COMMON.SET_VARIATIONS_BY_ID,
                payload: {
                  id: subject_id,
                  data: data.variations,
                },
              });

              resolve({ status: true, data });
            },
            () => {
              reject({ status: false });
            },
          );
        })
        .catch((data) => {
          reject(data);
        });
    });
  },

  getVariationsId: (params) => (dispatch, state) => {
    const { variation_id } = params;
    return new Promise((resolve, reject) => {
      api
        .get("shop.getVariations", params)
        .then((response) => {
          api.apiResponseTreatment(response).then(
            (data) => {
              dispatch({
                type: TYPES.COMMON.SET_VARIATIONS_ID,
                payload: {
                  id: variation_id,
                  data: data.variations,
                },
              });

              resolve({ status: true, data });
            },
            () => {
              reject({ status: false });
            },
          );
        })
        .catch((data) => {
          reject(data);
        });
    });
  },

  addVariations: (params) => (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .post("shop.addVariation", { ...params })
        .then((response) => {
          api.apiResponseTreatment(response).then(
            (data) => {
              resolve({ status: true, data });
            },
            (data) => {
              reject({ status: false, data });
            },
          );
        })
        .catch((data) => {
          reject(data);
        });
    });
  },

  editVariations: (params) => (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get("shop.editVariation", params)
        .then((response) => {
          api.apiResponseTreatment(response).then(
            (data) => {
              resolve({
                status: true,
                response,
              });
            },
            (errors) => {
              reject({
                status: false,
                errors,
              });
            },
          );
        })
        .catch((data) => {
          reject(data);
        });
    });
  },

  getAdditions: (params) => (dispatch, state) => {
    const { subject_id } = params;
    return new Promise((resolve, reject) => {
      api
        .get("shop.getAdditions", params)
        .then((response) => {
          api.apiResponseTreatment(response).then(
            (data) => {
              dispatch({
                type: TYPES.COMMON.SET_ADDITIONS_BY_ID,
                payload: {
                  id: subject_id,
                  data: data.additions,
                },
              });

              resolve({ status: true, data });
            },
            () => {
              reject({ status: false });
            },
          );
        })
        .catch((data) => {
          reject(data);
        });
    });
  },

  getAdditionsId: (params) => (dispatch, state) => {
    const { addition_id } = params;
    return new Promise((resolve, reject) => {
      api
        .get("shop.getAdditions", params)
        .then((response) => {
          api.apiResponseTreatment(response).then(
            (data) => {
              dispatch({
                type: TYPES.COMMON.SET_ADDITIONS_ID,
                payload: {
                  id: addition_id,
                  data: data.additions,
                },
              });

              resolve({ status: true, data });
            },
            () => {
              reject({ status: false });
            },
          );
        })
        .catch((data) => {
          reject(data);
        });
    });
  },

  addAdditions: (params) => (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .post("shop.addAddition", { ...params })
        .then((response) => {
          api.apiResponseTreatment(response).then(
            (data) => {
              resolve({ status: true, data });
            },
            (data) => {
              reject({ status: false, data });
            },
          );
        })
        .catch((data) => {
          reject(data);
        });
    });
  },

  editAdditions: (params) => (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get("shop.editAddition", params)
        .then((response) => {
          api.apiResponseTreatment(response).then(
            (data) => {
              resolve({
                status: true,
                response,
              });
            },
            (errors) => {
              reject({
                status: false,
                errors,
              });
            },
          );
        })
        .catch((data) => {
          reject(data);
        });
    });
  },

  getSubjects:
    (params, append = false) =>
    (dispatch, state) => {
      const { category_id } = params;
      return new Promise((resolve, reject) => {
        dispatch({
          type: TYPES.COMMON.COMMON_SET_LOADING_STATUS,
          section: "subjectsIsLoading",
          status: LOADING_STATUS_IN_PROCESS,
        });

        console.log(334304, params);

        if (!append) {
          params.cursor = 0;
        }

        api
          .get("shop.getSubjects", params)
          .then((response) => {
            api.apiResponseTreatment(response).then(
              (data) => {
                dispatch({
                  type: append ? TYPES.COMMON.APPEND_SUBJECTS_BY_CATEGORY : TYPES.COMMON.SET_SUBJECTS_BY_CATEGORY,
                  payload: {
                    rows: data.rows,
                    hasNext: data.hasNext,
                    countAll: data.countAll,
                    category_id,
                  },
                });

                // const stage = store.getState();
                // const newDataObject = {};
                // Object.keys(data.points).map((objKey) => {
                // 	const item = data.points[objKey];
                // 	if (!stage.common.pointsById.hasOwnProperty(item.id)) {
                // 		newDataObject[item.id] = item;
                // 	}
                // });
                //
                // dispatch({
                // 	type: TYPES.COMMON.SET_POINT_BY_ID,
                // 	payload: newDataObject
                // });

                dispatch({
                  type: TYPES.COMMON.COMMON_SET_LOADING_STATUS,
                  section: "subjectsIsLoading",
                  status: LOADING_STATUS_OK,
                });

                resolve({ status: true, data });
              },
              () => {
                dispatch({
                  type: TYPES.COMMON.COMMON_SET_LOADING_STATUS,
                  section: "subjectsIsLoading",
                  status: LOADING_STATUS_FAIL,
                });

                reject({ status: false });
              },
            );
          })
          .catch((data) => {
            dispatch({
              type: TYPES.COMMON.COMMON_SET_LOADING_STATUS,
              section: "subjectsIsLoading",
              status: LOADING_STATUS_FAIL,
            });
            reject(data);
          });
      });
    },

  getSubjectsId:
    (params, append = false) =>
    (dispatch, state) => {
      const { id, category_id } = params;
      return new Promise((resolve, reject) => {
        dispatch({
          type: TYPES.COMMON.COMMON_SET_LOADING_STATUS,
          section: "subjectsIsLoading",
          status: LOADING_STATUS_IN_PROCESS,
        });

        if (!append) {
          params.cursor = 0;
        }

        api
          .get("shop.getSubjects", params)
          .then((response) => {
            api.apiResponseTreatment(response).then(
              (data) => {
                dispatch({
                  type: TYPES.COMMON.SET_SUBJECT_BY_ID,
                  payload: {
                    rows: data.rows,
                    hasNext: data.hasNext,
                    countAll: data.countAll,
                    id,
                    category_id,
                  },
                });

                // const stage = store.getState();
                // const newDataObject = {};
                // Object.keys(data.points).map((objKey) => {
                // 	const item = data.points[objKey];
                // 	if (!stage.common.pointsById.hasOwnProperty(item.id)) {
                // 		newDataObject[item.id] = item;
                // 	}
                // });
                //
                // dispatch({
                // 	type: TYPES.COMMON.SET_POINT_BY_ID,
                // 	payload: newDataObject
                // });

                dispatch({
                  type: TYPES.COMMON.COMMON_SET_LOADING_STATUS,
                  section: "subjectsIsLoading",
                  status: LOADING_STATUS_OK,
                });

                resolve({ status: true, data });
              },
              () => {
                dispatch({
                  type: TYPES.COMMON.COMMON_SET_LOADING_STATUS,
                  section: "subjectsIsLoading",
                  status: LOADING_STATUS_FAIL,
                });

                reject({ status: false });
              },
            );
          })
          .catch((data) => {
            dispatch({
              type: TYPES.COMMON.COMMON_SET_LOADING_STATUS,
              section: "subjectsIsLoading",
              status: LOADING_STATUS_FAIL,
            });
            reject(data);
          });
      });
    },

  editSubject: (params) => (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get("shop.editSubject", params)
        .then((response) => {
          api.apiResponseTreatment(response).then(
            (data) => {
              resolve({
                status: true,
                response,
              });
            },
            (errors) => {
              reject({
                status: false,
                errors,
              });
            },
          );
        })
        .catch((data) => {
          reject(data);
        });
    });
  },

  addSubject: (params) => (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .post("shop.addSubject", { ...params })
        .then((response) => {
          api.apiResponseTreatment(response).then(
            (data) => {
              resolve({ status: true, data });
            },
            (data) => {
              reject({ status: false, data });
            },
          );
        })
        .catch((data) => {
          reject(data);
        });
    });
  },

  getSections: () => (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get("shop.getSections", {})
        .then((response) => {
          api.apiResponseTreatment(response).then(
            (data) => {
              dispatch({
                type: TYPES.COMMON.SET_SECTIONS,
                payload: data.sections,
              });

              resolve({ status: true, data });
            },
            () => {
              reject({ status: false });
            },
          );
        })
        .catch((data) => {
          reject(data);
        });
    });
  },

  getAddresses: () => (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get("addresses.get", {})
        .then((response) => {
          api.apiResponseTreatment(response).then(
            (data) => {
              dispatch({
                type: TYPES.COMMON.SET_ADDRESSES,
                payload: data,
              });

              resolve({ status: true, data });
            },
            () => {
              reject({ status: false });
            },
          );
        })
        .catch((data) => {
          reject(data);
        });
    });
  },

  addAddress: (params) => (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .post("addresses.add", { ...params })
        .then((response) => {
          api.apiResponseTreatment(response).then(
            (data) => {
              resolve({ status: true, data });
            },
            (data) => {
              reject({ status: false, data });
            },
          );
        })
        .catch((data) => {
          reject(data);
        });
    });
  },

  removeAddress:
    ({ address_id }) =>
    (dispatch) => {
      return new Promise((resolve, reject) => {
        api
          .post("addresses.remove", { address_id })
          .then((response) => {
            api.apiResponseTreatment(response).then(
              (data) => {
                resolve({ status: true, data });
              },
              (data) => {
                reject({ status: false, data });
              },
            );
          })
          .catch((data) => {
            reject(data);
          });
      });
    },

  getCategories:
    ({ point_id }) =>
    (dispatch) => {
      return new Promise((resolve, reject) => {
        api
          .get("shop.getCategories", { point_id })
          .then((response) => {
            api.apiResponseTreatment(response).then(
              (data) => {
                dispatch({
                  type: TYPES.COMMON.SET_CATEGORIES,
                  payload: { [point_id]: data.categories },
                });

                resolve({ status: true, data });
              },
              () => {
                reject({ status: false });
              },
            );
          })
          .catch((data) => {
            reject(data);
          });
      });
    },

  addCategory: (params) => (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .post("shop.addCategory", { ...params })
        .then((response) => {
          api.apiResponseTreatment(response).then(
            (data) => {
              resolve({ status: true, data });
            },
            (data) => {
              reject({ status: false, data });
            },
          );
        })
        .catch((data) => {
          reject(data);
        });
    });
  },

  editCategory: (params) => (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get("shop.editCategory", params)
        .then((response) => {
          api.apiResponseTreatment(response).then(
            (data) => {
              resolve({
                status: true,
                response,
              });
            },
            (errors) => {
              reject({
                status: false,
                errors,
              });
            },
          );
        })
        .catch((data) => {
          reject(data);
        });
    });
  },

  getTags:
    ({ section_id }) =>
    (dispatch) => {
      return new Promise((resolve, reject) => {
        api
          .get("shop.getTags", { section_id })
          .then((response) => {
            api.apiResponseTreatment(response).then(
              (data) => {
                dispatch({
                  type: TYPES.COMMON.SET_TAGS,
                  payload: data.tags,
                });

                resolve({ status: true, data });
              },
              () => {
                reject({ status: false });
              },
            );
          })
          .catch((data) => {
            reject(data);
          });
      });
    },

  getMyOrders:
    (params, append = false) =>
    (dispatch, state) => {
      return new Promise((resolve, reject) => {
        dispatch({
          type: TYPES.COMMON.COMMON_SET_LOADING_STATUS,
          section: "ordersIsLoading",
          status: LOADING_STATUS_IN_PROCESS,
        });

        console.log(99, params);

        // if (!append) {
        //   params.cursor = 0;
        // }
        api
          .get("shop.getOrders", { ...params })
          .then((response) => {
            api.apiResponseTreatment(response).then(
              (data) => {
                dispatch({
                  type: append ? TYPES.COMMON.APPEND_MY_ORDERS : TYPES.COMMON.SET_MY_ORDERS,
                  payload: data,
                });

                resolve({ status: true, data });
              },
              () => {
                reject({ status: false });
              },
            );
          })
          .catch((data) => {
            reject(data);
          });
      });
    },

  getPointOrders:
    (params, append = false) =>
    (dispatch, state) => {
      return new Promise((resolve, reject) => {
        dispatch({
          type: TYPES.COMMON.COMMON_SET_LOADING_STATUS,
          section: "ordersPointIsLoading",
          status: LOADING_STATUS_IN_PROCESS,
        });

        console.log(99, params);
        api
          .get("shop.getPointOrders", { ...params })
          .then((response) => {
            api.apiResponseTreatment(response).then(
              (data) => {
                dispatch({
                  type: append ? TYPES.COMMON.APPEND_POINT_ORDERS : TYPES.COMMON.SET_POINT_ORDERS,
                  payload: data,
                });

                resolve({ status: true, data });
              },
              () => {
                reject({ status: false });
              },
            );
          })
          .catch((data) => {
            reject(data);
          });
      });
    },

  getPointOrdersId: (params) => (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get("shop.getPointOrders", { ...params })
        .then((response) => {
          api.apiResponseTreatment(response).then(
            (data) => {
              dispatch({
                type: TYPES.COMMON.SET_POINT_ORDERS_ID,
                payload: data,
              });

              resolve({ status: true, data });
            },
            () => {
              reject({ status: false });
            },
          );
        })
        .catch((data) => {
          reject(data);
        });
    });
  },

  setActiveSection: (section) => (dispatch) => {
    dispatch({
      type: TYPES.COMMON.SET_ACTIVE_SECTION,
      payload: section,
    });
  },

  setActivePointCategoryId: (val) => (dispatch, getStore) => {
    dispatch({
      type: TYPES.COMMON.SET_ACTIVE_POINT_CATEGORY_ID,
      payload: val,
    });
  },

  testAction: () => {
    return (dispatch, getStore) => {
      return new Promise((resolve, reject) => {
        dispatch({
          type: TYPES.COMMON.SET_TEST,
          payload: [{ x: 1 }, { b: 2 }],
        });
        resolve(true);
      });
    };
  },
};
