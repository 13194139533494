import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { commonActions, TYPES, usersActions } from '../store/actions'
import { lsGet } from '../services/storage';

export default () => {
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const lsCity = JSON.parse(lsGet('city'));

  const { city, sections, activeSection, tags } = useSelector((store) => ({
		city: store.common.city,
		sections: store.common.sections,
		activeSection: store.common.activeSection,
		tags: store.common.tags,
  }));

  useEffect(() => {
		if (city === null) {
			dispatch({
				type: TYPES.COMMON.SET_CITY,
				payload: lsCity
			});
		}
	}, [lsCity]);

	useEffect(() => {
		if (Object.keys(sections).length === 0) {
			const getSections = dispatch(commonActions.getSections());
			getSections.then((data) => {
				if (data.status) {
					const activeSection = data.data.sections[Object.keys(data.data.sections)[0]];
					dispatch(commonActions.setActiveSection(activeSection));
				}
			})
		}
	}, []);

	useEffect(() => {
		if (activeSection !== null) {
			const tags = dispatch(commonActions.getTags({ section_id: activeSection.id }));
			tags.then(() => {});
		}
	}, [activeSection]);

	if (!loaded) {
		if (Object.keys(sections).length > 0 && city !== null && activeSection !== null && tags !== null) {
			setLoaded(true);
		}
	}

  return {
  	loaded
	}
};
