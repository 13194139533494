import './input.scss';
// external
import React from 'react';
// internal
import classNames from 'classnames';

import { IInputProps } from './interface';

const Input: React.FC<IInputProps> = (props) => {
  const {
    title,
    description,
    onChangeHandler,
    mode,
    icon,
    error,
    ...inputProps
  } = props;

  return (
    <div className={`input--main no_select input--${mode}`}>
      <label className="input--label">
        <div
          className={classNames('input--wrapper no_select', {
            'input--wrapper_error': !!error
          })}
        >
          {!!icon && (
            <div className="input--left_icon">
              {icon}
            </div>
          )}
          {!!title && (
            <div className="input--wrapper_title no_select">
              {title}
            </div>
          )}
          <input
            {...inputProps}
            onChange={(text) => {
              if (onChangeHandler) {
                onChangeHandler(text.target.value);
              }
            }}
            type="text"
          />
          {description && (
            <div className="input--wrapper_description no_select">
              <div className="hr" />
              {description}
            </div>
          )}
        </div>
      </label>
    </div
    >
  );
};

Input.defaultProps = {
  mode: 'primary',
  value: '',
  onChangeHandler: () => {}
};

export default Input;
