import { combineReducers } from 'redux';
import { commonReducer } from './common';
import { usersReducer } from './users';
import { LoadingReducer } from './loading';

export default combineReducers({
  common: commonReducer,
  users: usersReducer,
  loading: LoadingReducer
});
