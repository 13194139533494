import React from 'react';

import { PAGE_ROUTES } from './definitions/routes';

//wrappers
import NotWrapper from './pages/wrappers/NotWrapper';
import MainWrapper from './pages/wrappers/MainWrapper';
//pages
import MainPage from './pages/containers/MainPage';
import NewPointPage from './pages/containers/NewPointPage';
import CitiesPage from './pages/containers/CitiesPage';
import StartPage from './pages/containers/StartPage';
import MenuPage from './pages/containers/MenuPage';
import SettingsPage from './pages/containers/SettingsPage';
import ProfilePage from './pages/containers/ProfilePage';
import UiPage from './pages/containers/UiPage';
import NotFoundPage from './pages/containers/NotFoundPage';
import Notifications from "./pages/containers/Notifications";
import MainAdminPage from "./pages/containers/MainAdminPage";
import MyOrdersPage from "./pages/containers/MyOrdersPage";
import MyAddressPage from "./pages/containers/MyAdressPage";
import GeneralPointSettings from "./pages/containers/GeneralPointSettings";
import SpecialMenuPage from "./pages/containers/SpecialMenuPage";
import PersonalPage from "./pages/containers/PersonalPage";
import SpecialCategories from "./pages/containers/SpecialCategories";
import SpecialOrdersPage from "./pages/containers/SpecialOrdersPage";
import SpecialProductsPage from "./pages/containers/SpecialProductsPage";
import MainSearchPage from "./pages/containers/MainSearchPage";
import ForDevPage from "./pages/containers/ForDevPage";
import CodePage from "./pages/containers/CodePage";
import BalancePage from "./pages/containers/BalancePage";
import AgreementPage from "./pages/containers/AgreementPage";
import CreatePointPage from "./pages/containers/CreatePointPage";
import AdminMenuPage from "./pages/containers/AdminMenuPage";
import EditPointPage from "./pages/containers/EditPointPage";
import OptionsPage from "./pages/containers/OptionsPage";

export default function Routes(props) {
  const routeState = props.router.getState();
  const route = routeState.name;
  let Wrapper = NotWrapper;
  let Component = false;

  switch (route) {
    case PAGE_ROUTES.MAIN:
      Wrapper = MainWrapper;
      Component = MainPage;
      break;
    case PAGE_ROUTES.CITIES:
      Wrapper = NotWrapper;
      Component = CitiesPage;
      break;
    case PAGE_ROUTES.SEARCH:
      Wrapper = NotWrapper;
      Component = MainSearchPage;
      break;
    case PAGE_ROUTES.START:
      Wrapper = NotWrapper;
      Component = StartPage;
      break;
    case PAGE_ROUTES.MENU:
      Wrapper = MainWrapper;
      Component = MenuPage;
      break;
    case PAGE_ROUTES.SPECIALMENU:
      Wrapper = MainWrapper;
      Component = SpecialMenuPage;
      break;
    case PAGE_ROUTES.SPECIALCATEGORIES:
      Wrapper = MainWrapper;
      Component = SpecialCategories;
      break;
    case PAGE_ROUTES.SPECIALORDERS:
      Wrapper = MainWrapper;
      Component = SpecialOrdersPage;
      break;
    case PAGE_ROUTES.PRODUCTS:
      Wrapper = MainWrapper;
      Component = SpecialProductsPage;
      break;
    case PAGE_ROUTES.OPTIONS:
      Wrapper = MainWrapper;
      Component = OptionsPage;
      break;
    case PAGE_ROUTES.PERSONAL:
      Wrapper = MainWrapper;
      Component = PersonalPage;
      break;
    case PAGE_ROUTES.MYORDERS:
      Wrapper = MainWrapper;
      Component = MyOrdersPage;
      break;
    case PAGE_ROUTES.NOTIFICATIONS:
      Wrapper = MainWrapper;
      Component = Notifications;
      break;
    case PAGE_ROUTES.MYADDRESS:
      Wrapper = MainWrapper;
      Component = MyAddressPage;
      break;
    case PAGE_ROUTES.MYCONTACTS:
      Wrapper = MainWrapper;
      Component = MenuPage;
      break;
    case PAGE_ROUTES.CODE:
      Wrapper = MainWrapper;
      Component = CodePage;
      break;
    case PAGE_ROUTES.BALANCE:
      Wrapper = MainWrapper;
      Component = BalancePage;
      break;
    case PAGE_ROUTES.AGREEMENT:
      Wrapper = MainWrapper;
      Component = AgreementPage;
      break;
    case PAGE_ROUTES.FORDEV:
      Wrapper = MainWrapper;
      Component = ForDevPage;
      break;
    case PAGE_ROUTES.SETTINGS:
      Wrapper = MainWrapper;
      Component = SettingsPage;
      break;
		case PAGE_ROUTES.POINT:
			Wrapper = MainWrapper;
			Component = NewPointPage;
			break;
    case PAGE_ROUTES.SECURITY:
      Wrapper = MainWrapper;
      Component = SettingsPage;
      break;
    case PAGE_ROUTES.PROFILE:
      Wrapper = MainWrapper;
      Component = ProfilePage;
      break;
    case PAGE_ROUTES.UI:
      Wrapper = NotWrapper;
      Component = UiPage;
      break;
    case PAGE_ROUTES.NOT_FOUND:
      Wrapper = NotWrapper;
      Component = NotFoundPage;
      break;
    case PAGE_ROUTES.ADMIN_MAIN:
      Wrapper = MainWrapper;
      Component = MainAdminPage;
      break;
    case PAGE_ROUTES.ADMIN_MENU:
      Wrapper = MainWrapper;
      Component = AdminMenuPage;
      break;
    case PAGE_ROUTES.GENERAL_POINT_SETTINGS:
      Wrapper = MainWrapper;
      Component = GeneralPointSettings;
      break;
    case PAGE_ROUTES.CREATE_POINT:
      Wrapper = MainWrapper;
      Component = CreatePointPage;
      break;
    case PAGE_ROUTES.EDIT_POINT:
      Wrapper = MainWrapper;
      Component = EditPointPage;
      break;
    default:
      Component = NotFoundPage;
      break;
  }

  let defaultProps = {
    state: props.state.main,
    router: props.router
  };

  return !Component ? (
    <div>
      <h1>404 Not Found</h1>
    </div>
  ) : (
    <Wrapper routeState={routeState}>
      <Component routeState={routeState} />
    </Wrapper>
  );
}
