import "./index.scss";
import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../components/ui/universal/Input";
import Card from "../../../components/ui/universal/Card";
import { ISignInContent } from "./interface";
import Spacer from "../../../components/ui/universal/Spacer";
import { isEmptyObject } from "../../../utils";
import { commonActions } from "../../../store/actions/common-actions";
import Loading from "../../../components/ui/universal/Loading";
import { useGetMyPointInfo } from "../../../hooks";
import { intTo4digitsString } from "../../../utils/intTo4digitsString";

const SigninContent: React.FC<ISignInContent> = (props: any) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const { pointId } = useGetMyPointInfo();
  const { pointOrdersId } = useSelector((state: any) => ({
    pointOrdersId: state.common.pointOrdersId,
  }));

  const isLoading = isEmptyObject(pointOrdersId) || props.orderId !== pointOrdersId.id;

  useEffect(() => {
    if (isLoading) {
      dispatch(
        commonActions.getPointOrdersId({
          point_id: pointId,
          id: props.orderId,
        }),
      );
    }
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  const {
    id,
    create_time,
    take_it_myself,
    comment,
    not_call,
    delivery_price,
    price,
    discount_percent,
    pay_method_id,
    merchant_comment,
    promo_code,
    change_needed,
    order,
    contact_info_id,
  } = pointOrdersId;

  const renderMainBlock = () => {
    const time = new Date(create_time * 1000);
    const createTime = time.toLocaleDateString("ru-RU") + " " + time.toLocaleTimeString("ru-RU");
    return (
      <div>
        <Card verticalPadding={3} horizontalPadding={3}>
          <div className="OrderDetails">
            <div className="OrderDetails_heading">
              <span>Подробнее</span>
            </div>
            <div className="OrderDetails_item">
              <div className="OrderDetails_item_title">
                <span>ID заказа</span>
              </div>
              <div className="OrderDetails_item_description">
                <span>{"#" + intTo4digitsString(id)}</span>
              </div>
            </div>

            <div className="OrderDetails_item">
              <div className="OrderDetails_item_title">
                <span>Дата и время</span>
              </div>
              <div className="OrderDetails_item_description">
                <span>{createTime}</span>
              </div>
            </div>

            <div className="OrderDetails_ordersBox">
              <div className="OrderDetails_ordersBox_header">
                <span>Детали заказа</span>
              </div>
              <Spacer size={2} />
              {order.map((item: any) => {
                const { additions, subject, variation_id, count_position } = item;
                return (
                  <div key={uuidv4() as any}>
                    <div className="OrderDetails_ordersBox_item_header">
                      <span>Товар</span>
                    </div>
                    <div className="OrderDetails_ordersBox_item">
                      <div className="OrderDetails_ordersBox_item_info">
                        <span>{subject.name}</span>
                      </div>
                      <div className="OrderDetails_ordersBox_item_price">
                        <span>
                          {count_position === 1
                            ? `${count_position} шт = ${subject.price}₽`
                            : `${count_position} шт = ${count_position * subject.price}₽`}
                        </span>
                      </div>
                    </div>

                    <div className="OrderDetails_ordersBox_item_header">
                      <span>Дополнение</span>
                    </div>
                    <Spacer size={3} />
                    {variation_id !== undefined && (
                      <div className="OrderDetails_ordersBox_topping">
                        <div className="OrderDetails_ordersBox_topping_item">
                          <div className="OrderDetails_ordersBox_topping_item_left">
                            <span>{variation_id.info.title}</span>
                            <span className="description">{variation_id.info.description}</span>
                          </div>
                          <div className="OrderDetails_ordersBox_topping_item_right">
                            <span>{variation_id.info.price} ₽</span>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="OrderDetails_ordersBox_topping">
                      {additions.map((item: any, key: any) => {
                        const { info, count } = item;
                        return (
                          <div key={uuidv4() as any} className="OrderDetails_ordersBox_topping_item">
                            <div className="OrderDetails_ordersBox_topping_item_left">
                              <span>{info.title}</span>
                              <span className="description">{info.description}</span>
                            </div>
                            <div className="OrderDetails_ordersBox_topping_item_right">
                              <span>
                                {count === 1 ? `${count} шт = ${info.price}` : `${count} шт = ${count * info.price}`} ₽
                              </span>
                            </div>
                          </div>
                        );
                      })}
                      <Spacer size={3} />
                    </div>
                  </div>
                );
              })}
            </div>

            <Spacer size={15} />

            <Input
              title={"Статус"}
              mode="secondary"
              placeholder="Введите статус заказа"
              value={value}
              onChangeHandler={setValue}
            />
            <Spacer size={10} />

            <div className="OrderDetails_item">
              <div className="OrderDetails_item_title">
                <span>Способ получения</span>
              </div>
              <div className="OrderDetails_item_description">
                <span>{take_it_myself === 1 ? "Самовывоз" : "Доставка"}</span>
              </div>
            </div>

            <div className="OrderDetails_item">
              <div className="OrderDetails_item_title">
                <span>Цена доставки</span>
              </div>
              <div className="OrderDetails_item_description">
                {take_it_myself === 0 && <span>{delivery_price}</span>}
              </div>
            </div>

            <div className="OrderDetails_item">
              <div className="OrderDetails_item_title">
                <span>Звонок</span>
              </div>
              <div className="OrderDetails_item_description">
                <span>{not_call === 1 ? "Позвонить" : "Не звонить без прямой необходимости"}</span>
              </div>
            </div>

            <div className="OrderDetails_item">
              <div className="OrderDetails_item_title">
                <span>Комментарий продавца</span>
              </div>
              <div className="OrderDetails_item_description">
                <span>{merchant_comment}</span>
              </div>
            </div>

            <div className="OrderDetails_item">
              <div className="OrderDetails_item_title">
                <span>Комментарий к заказу</span>
              </div>
              <div className="OrderDetails_item_description">
                <span>{comment}</span>
              </div>
            </div>

            <div className="OrderDetails_item">
              <div className="OrderDetails_item_title">
                <span>Метод оплаты</span>
              </div>
              <div className="OrderDetails_item_description">
                <span>{pay_method_id === 0 ? "Картой представителю" : "Наличными представителю"}</span>
              </div>
            </div>

            {pay_method_id === 1 && (
              <div className="OrderDetails_item">
                <div className="OrderDetails_item_title">
                  <span>Нужна сдача c</span>
                </div>
                <div className="OrderDetails_item_description">
                  <span>{change_needed}</span>
                </div>
              </div>
            )}

            <div className="OrderDetails_item">
              <div className="OrderDetails_item_title">
                <span>Процент скидки</span>
              </div>
              <div className="OrderDetails_item_description">
                <span>{discount_percent}%</span>
              </div>
            </div>

            <div className="OrderDetails_item">
              <div className="OrderDetails_item_title">
                <span>Промокод</span>
              </div>
              <div className="OrderDetails_item_description">
                <span>{promo_code}</span>
              </div>
            </div>

            <div className="OrderDetails_item">
              <div className="OrderDetails_item_title">
                <span>Цена заказа</span>
              </div>
              <div className="OrderDetails_item_description">
                <span>{price}</span>
              </div>
            </div>

            <div className="OrderDetails_item">
              <div className="OrderDetails_item_title">
                <span>Информация об адресе покупателя</span>
              </div>
              <div className="OrderDetails_item_description">
                <span>{contact_info_id.address}</span>
              </div>
            </div>
            <div className="OrderDetails_item">
              <div className="OrderDetails_item_title">
                <span>Id покупателя</span>
              </div>
              <div className="OrderDetails_item_description">
                <span>{contact_info_id.id}</span>
              </div>
            </div>
            <div className="OrderDetails_item">
              <div className="OrderDetails_item_title">
                <span>Имя покупателя</span>
              </div>
              <div className="OrderDetails_item_description">
                <span>{contact_info_id.name}</span>
              </div>
            </div>
            <div className="OrderDetails_item">
              <div className="OrderDetails_item_title">
                <span>Номер телефона</span>
              </div>
              <div className="OrderDetails_item_description">
                <span>{contact_info_id.phone}</span>
              </div>
            </div>
          </div>
        </Card>
      </div>
    );
  };

  return renderMainBlock();
};

export default SigninContent;
