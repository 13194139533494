import './index.scss';
import React from 'react';



const calc = {
	add: () => {
		// я шлю реф ссылку кому -то
		// у меня первый уровень
		// чел регается
		// и у него уровень инкрементится от моего
		// когда он зовет кого-то у того инкременится уровень от того кто позвал
		// то ессть может и 5 и 10ый быть
	},
	addContract: () => {
		// при заключеннии контракта сумма может быть к примеру от 5000 до 10000
		// 65% уходит владельцу, а 35% распределяются
		// если это 1 уровень то он получает 35%
		// если это 2 уровень то он получает 25% и 10% отдает первому
		// если это 3 уровень то он получает 25% и 5% отдает второму и 5% первому
		// если это 4 уровень то он получает 25% и 5% отдает третьему, 2.5% второму, 2.5% первому
		// если это 5 уровень то он получает 25% и 5% отдает четвертому, 2.5% третьему, 1.25% второму и первому,
		// большего уровня приглашать смысла нет
	}
};

const PointOnList = (props) => {
	return (
		<div key={`PointOnList_${props.id}`} className="PointOnList" onClick={props.onClick}>
			<div className="PointOnList_wrapper">
				<div className="PointOnList_wrapper_cover" style={{backgroundImage:`url(${props.cover})`}}>
					<div className="PointOnList_wrapper_cover_logotype" style={{backgroundImage:`url(${props.logotype})`}}>
					</div>
				</div>
				<div className="PointOnList_wrapper_context">
					<div className="PointOnList_wrapper_context_context_left">
						<div className="PointOnList_wrapper_context_context_left_name">
							<span>
								{props.name}
							</span>
						</div>
						<div className="PointOnList_wrapper_context_context_left_description">
							<span>
								{props.shortDescription}
							</span>
						</div>
						<div className="PointOnList_wrapper_context_context_left_delivery">
							{props.freeFrom < 1 && (
								<div className="PointOnList_wrapper_context_context_left_delivery_wr">
									<span>
										Цена доставки: {props.deliveryPrice} р.
									</span>
								</div>
							)}
							{props.freeFrom > 0 && (
								<div className="PointOnList_wrapper_context_context_left_delivery_wr">
									<span>
										Беспл. доставка от <strong>{props.freeFrom} р.</strong>
									</span>
								</div>
							)}
						</div>
						<div className="PointOnList_wrapper_context_context_left_tags">
							{props.opened && (
								<div className="PointOnList_opened">
									<span>
										Открыто
									</span>
								</div>
							)}

							{!props.opened && (
								<div className="PointOnList_closed">
									<span>
										Сейчас закрыто
									</span>
								</div>
							)}
							<span>
								{'#' + props.tags.join(' #')}
							</span>
						</div>
					</div>
					<div className="PointOnList_wrapper_context_right">
						{props.averageCheck > 0 && (
							<div className="PointOnList_wrapper_context_right_price">
                <span>
                  {props.averageCheck} р.
                </span>
							</div>
						)}
						{props.averageLeadTimeOnMinutes > 0 && (
							<div className="PointOnList_wrapper_context_right_time">
                <span>
                  {props.averageLeadTimeOnMinutes} м.
                </span>
							</div>
						)}
						{props.averageLeadTimeOnHours > 0 && (
							<div className="PointOnList_wrapper_context_right_time">
                <span>
                  {props.averageLeadTimeOnHours} ч.
                </span>
							</div>
						)}
						{props.averageLeadTimeOnDay > 0 && (
							<div className="PointOnList_wrapper_context_right_time">
                <span>
                  {props.averageLeadTimeOnDay} д.
                </span>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

PointOnList.defaultProps = {
	id: 0,
	onClick: () => {},
	cover: '',
	logotype: '',
	name: '',
	shortDescription: '',
	averageLeadTimeOnMinutes: 0,
	averageLeadTimeOnHours: 0,
	averageLeadTimeOnDay: 0,
	averageCheck: 0,
	freeFrom: 0,
	deliveryPrice: 0,
	tags: []
};

export default PointOnList;